import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'

export default function SelectField({
	label,
	options,
	value,
	groupKeyByOtherMenu,
	onChange,
	customClass,
	isCollapse,
}) {
	const handleChange = (event) => {
		const {
			target: { value },
		} = event
		onChange(value)
	}

	return (
		<div>
			<FormControl fullWidth size="small">
				<InputLabel id={`label-${label}`}>{label}</InputLabel>
				<Select
					sx={{
						...(isCollapse
							? {
									'& .MuiTypography-root': {
										fontSize: '0.875rem',
									},
							  }
							: {}),
					}}
					labelId={`label-${label}`}
					id={label}
					value={value}
					onChange={handleChange}
					input={<OutlinedInput label={label} />}
				>
					{options.map((option) => (
						<MenuItem
							sx={{
								...(isCollapse
									? {
											'& .MuiTypography-root': {
												fontSize: '0.875rem',
											},
									  }
									: {}),
							}}
							key={option.value}
							value={option.value}
							disabled={groupKeyByOtherMenu.includes(option.value)}
						>
							<ListItemText primary={option.label} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	)
}
