import { useContext, useState, useEffect, useMemo, memo, useLayoutEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, cloneDeep, isEqual } from 'lodash'
import { makeStyles } from '@mui/styles'
import { Delete } from '@mui/icons-material'
import {
	InputLabel,
	FormControl,
	NativeSelect,
	Button,
	Autocomplete,
	Chip,
	TextField,
	Input,
	Stack,
	Typography,
	Box,
} from '@mui/material'

import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { operatorOptions } from 'utils/functions/conditionsOptions'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'
import { generateRandomID } from 'components/pages/dis/helpers'
import CollapseComponent from './CollapseComponent'
import {
	SETTING_TYPES,
	DATA_OBJ_ACTION_TYPES,
	shouldPreventRender,
	dataObjectReducer,
} from '../ViewDialogUtils'

const operatorMap = {
	EQ: '=',
	NE: '!=',
}

const stringFilter = {
	contains: 'contains',
	equals: 'equals',
	startsWith: 'starts with',
	endsWith: 'ends width',
	isEmpty: 'is empty',
	isNotEmpty: 'is not empty',
	isAnyOf: 'is any of',
}
const numberFilter = {
	'=': '=',
	'!=': '!=',
	'>': '>',
	'>=': '>=',
	'<': '<',
	'<=': '<=',
	isEmpty: 'isEmpty',
	isNotEmpty: 'is not empty',
	isAnyOf: 'is any of',
}
const dateFilter = {
	is: 'is',
	not: 'not',
	after: 'after',
	onOrAfter: 'on or after',
	before: 'before',
	onOrBefore: 'on or before',
	isEmpty: 'is empty',
	isNotEmpty: 'is not empty',
}

const useStyles = makeStyles(() => ({
	closeIconSort: {
		marginRight: '20px',
		marginTop: '12px',
		width: '5%',
	},
	filterRowConType: {
		width: '10%',
	},
	filterRowField: {
		width: '30%',
	},
	filterRowOpe: {
		width: '20%',
	},
	filterRowVal: {
		width: '30%',
	},
}))

const getDefaultOperator = (type) => {
	if (type === 'STRING') {
		return Object.keys(stringFilter)[0]
	} else if (type === 'FLOAT' || type === 'INTEGER') {
		return Object.keys(numberFilter)[0]
	} else if (type === 'DATETIME' || type === 'DATE' || type === 'TIME') {
		return Object.keys(dateFilter)[0]
	}
	return ''
}

const RenderOperatorCom = (props) => {
	const { type } = props

	return (
		<>
			{type === 'STRING' &&
				Object.keys(stringFilter).map((item2) => {
					return <option value={item2}>{stringFilter[item2]}</option>
				})}
			{(type === 'FLOAT' || type === 'INTEGER') &&
				Object.keys(numberFilter).map((item2) => {
					return <option value={item2}>{numberFilter[item2]}</option>
				})}
			{(type === 'DATETIME' || type === 'DATE' || type === 'TIME') &&
				Object.keys(dateFilter).map((item2) => {
					return <option value={item2}>{dateFilter[item2]}</option>
				})}
		</>
	)
}

const RenderValueCom = (props) => {
	/* type STRING ,operator isAnyOf => textbox multi */

	/* type STRING, operator isEmpty, isNotEmpty => not show values box*/

	/* type DATETIME, operator isEmpty, isNotEmpty => not show values box */

	/* type DATETIME, orther operator => show picker */

	/* type FLOAT, INTEGER, operator isEmpty, isNotEmpty => not show values box */

	/* type FLOAT, INTEGER, other operator => show values number box */

	/* else show textbox */

	//<Input id="component-simple" InputLabelProps={{ shrink: false }} />
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const { item, changeAddFilValue } = props
	const isAnyOfOprator = useMemo(() => item.operator === 'isAnyOf', [item])

	const changeValue = (e, valuesParam) => {
		//incase isAnyOf values is an array
		let newValue = e.target.value
		if (isAnyOfOprator && valuesParam) {
			changeAddFilValue([...valuesParam])
		} else {
			if (newValue === item.value) {
				return
			}

			changeAddFilValue(newValue)
		}
	}

	return (
		<div>
			<InputLabel variant="standard" htmlFor="uncontrolled-native" shrink>
				Value
			</InputLabel>
			{item.operator === 'isEmpty' || item.operator === 'isNotEmpty' ? (
				<>{/*not show value box*/}</>
			) : (
				<>
					{item.operator === 'isAnyOf' ? (
						<>
							<Autocomplete
								multiple
								options={[]}
								freeSolo
								defaultValue={
									!isAnyOfOprator
										? item.value
										: Array.isArray(item.value)
											? [...item.value]
											: [item.value]
								}
								renderTags={(values, getTagProps) => (
									<Box sx={{ maxHeight: (theme) => theme.spacing(8), overflowY: 'auto' }}>
										{values.map((value, index) => (
											<Chip
												key={index}
												variant="standard"
												label={value}
												{...getTagProps({ index })}
											/>
										))}
									</Box>
								)}
								onChange={changeValue}
								sx={{
									'& .MuiAutocomplete-inputRoot': {
										height: '3em',
										paddingTop: '1em',
									},
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										type={item.type === 'FLOAT' || item.type === 'INTEGER' ? 'number' : ''}
									/>
								)}
							/>
						</>
					) : (
						<>
							{item.type === 'DATETIME' ? (
								//<LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
								//    <DatePicker />
								//id="map-date"
								//label={"Value"}
								//value={item?.value}
								//onChange={(newValue) => handleDateChange(newValue, false)}

								//</LocalizationProvider>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateTimePicker
										//label="With Time Clock
										value={item.value}
										onChange={(newValue) => {
											const newDate = new Date(newValue).toISOString()
											changeAddFilValue(newDate)
										}}
										viewRenderers={{
											hours: null,
											minutes: null,
											seconds: null,
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												//onBlur={(e) => handleDateChange(value, e.target.value, true)}
												className={classes.dateBox}
												classes={classes.dateBox}
												variant="standard"
												sx={{
													'& .MuiInputBase-input': {
														height: '39px !important',
													},
												}}
											//label={t('common:filters.date')}
											></TextField>
										)}
									/>
								</LocalizationProvider>
							) : item?.type === 'FLOAT' || item?.type === 'INTEGER' ? (
								<Input
									InputLabelProps={{ shrink: false }}
									type="number"
									value={item.value}
									onChange={changeValue}
								/>
							) : (
								<Input id="filter-value" value={item.value} onChange={changeValue} />
							)}
						</>
					)}
				</>
			)}
		</div>
	)
}

const AdditionalFilterCom = (props) => {
	const { item, index, additionalFilterDispatch, listActiveFieldsOptions, allColumns } = props
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const [field, setField] = useState(item.field)
	const [operator, setOperator] = useState(item.operator)
	const [type, setType] = useState(item.type)
	const [value, setValue] = useState(!item?.value?.length ? item?.value : '')
	const [values, setValues] = useState(item?.value?.length > 0 ? [...item?.value] : [])

	// if (field && !type && viewDataObj?.allColumns?.length > 0) {
	//     setType(viewDataObj?.allColumns.find(column => column.name == field)?.type)
	// }
	useEffect(() => {
		if (item.operator == 'isAnyOf' && item?.value?.length) {
			setValues([...item.value])
		} else {
			setValue(item.value)
		}
	}, [item])

	function changeAddFilters(addFil) {
		console.log(addFil)
	}
	function deleteAddFilter(id) {
		additionalFilterDispatch({
			type: DATA_OBJ_ACTION_TYPES.DELETE,
			target: {
				index,
			},
		})
	}

	const filterFieldChange = (e) => {
		const newField = e.target.value
		const newItem = cloneDeep(item)
		const tmpColumn = allColumns?.find((col) => col.name === newField)

		newItem['field'] = newField
		newItem['type'] = tmpColumn.type
		newItem['operator'] = getDefaultOperator(tmpColumn.type)
		newItem['value'] = ''
		additionalFilterDispatch({
			type: DATA_OBJ_ACTION_TYPES.UPDATE,
			target: {
				index,
				value: newItem,
			},
		})
	}

	const operatorChange = (e) => {
		const newOperator = e.target.value
		const newItem = cloneDeep(item)
		newItem['operator'] = newOperator
		additionalFilterDispatch({
			type: DATA_OBJ_ACTION_TYPES.UPDATE,
			target: {
				index,
				value: newItem,
			},
		})
	}

	const changeValue = (newValue) => {
		if (isEqual(item.value, newValue)) return

		const newItem = cloneDeep(item)
		newItem['value'] =
			operator !== 'isAnyOf' ? newValue : Array.isArray(newValue) ? [...newValue] : [newValue]
		additionalFilterDispatch({
			type: DATA_OBJ_ACTION_TYPES.UPDATE,
			target: {
				index,
				value: newItem,
			},
		})
	}

	return (
		<Stack
			direction="row"
			alignItems="center"
			spacing={1}
			style={{ justifyContent: 'center', marginBottom: '10px', alignItems: 'baseline' }}
		>
			{/* <FormControl className={classes.filterRowConType}>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                </InputLabel>
                <NativeSelect
                    defaultValue={'And'}
                >
                    <option value={'And'}>And</option>
                    <option value={'Or'}>Or</option>
                </NativeSelect>
            </FormControl> */}
			<FormControl fullWidth>
				<InputLabel variant="standard" htmlFor="uncontrolled-native">
					Field
				</InputLabel>
				<NativeSelect defaultValue={item.field} onChange={filterFieldChange}>
					{listActiveFieldsOptions.map((item) => (
						<option value={item.name}>{item.title}</option>
					))}
				</NativeSelect>
			</FormControl>
			<FormControl style={{ width: '50%' }}>
				<InputLabel variant="standard" htmlFor="uncontrolled-native">
					Operations
				</InputLabel>
				<NativeSelect defaultValue={item.operator} onChange={operatorChange}>
					<RenderOperatorCom operatorParam={item.operator} type={item.type} />
				</NativeSelect>
			</FormControl>
			<FormControl className={classes.filterRowVal}>
				<RenderValueCom item={item} changeAddFilValue={changeValue} />
			</FormControl>
			<div>
				<Button
					aria-label="delete"
					size="small"
					variant="contained"
					sx={{
						borderRadius: '50%',
						width: '25px !important',
						minWidth: '25px !important',
						height: '25px !important',
					}}
					onClick={(e) => deleteAddFilter(item.ranID)}
				>
					<Delete fontSize="small" />
				</Button>
			</div>
		</Stack>
	)
}

const AdditionalFiltersComponent = ({
	allColumns,
	allColumnsLoading,
	clientFilter,
	listActiveFields,
	listActiveFieldsOptions,
	saveSettingDispatch,
	expandedAccordion,
	setExpandedAccordion,
}) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const operators = useMemo(() => {
		return operatorOptions(t)
	}, [t])

	// additional filter collapse
	const [additionalFilter, additionalFilterDispatch] = useReducer(dataObjectReducer, [])

	function isIterable(obj) {
		// checks for null and undefined
		if (obj == null) {
			return false;
		}
		return typeof obj[Symbol.iterator] === 'function';
	}

	useEffect(() => {
		if (isEmpty(allColumns)) return
		const filters = isEmpty(clientFilter) ? [] :
			isIterable(clientFilter) ? [...clientFilter] : [clientFilter]

		if (isEmpty(filters)) {
			additionalFilterDispatch({
				type: DATA_OBJ_ACTION_TYPES.INIT_DATA,
				initData: [],
			})
			return
		}

		// condition get from grid not have type => first time load set type for it
		if (!filters[0]?.type) {
			const tmpAddFis = filters
				?.filter((item) => item.name)
				.map((item) => ({
					...item,
					ranID: generateRandomID(),
					type: allColumns?.find((column) => column.name == item.field)?.type,
				}))

			additionalFilterDispatch({
				type: DATA_OBJ_ACTION_TYPES.INIT_DATA,
				initData: tmpAddFis,
			})
			return
		}

		additionalFilterDispatch({
			type: DATA_OBJ_ACTION_TYPES.INIT_DATA,
			initData: filters,
		})
	}, [allColumns, clientFilter])

	useLayoutEffect(() => {
		saveSettingDispatch({
			type: SETTING_TYPES.ADDITIONAL_FILTER,
			value: additionalFilter,
		})
	}, [additionalFilter])

	const title = useMemo(() => t('common:view.applyAdditionalFilters'), [t])

	const addFilter = (e) => {
		//const additionalFilter = viewDataObj?.additionalFilter ? [...viewDataObj.additionalFilter] : []
		const field = listActiveFields?.length > 0 ? listActiveFields[0] : ''
		const column = allColumns?.find((col) => col.name === field)
		const type = column?.type ? column.type : ''
		const ope = getDefaultOperator(type)
		const newItem = {
			field: field,
			type: type,
			operator: ope,
			value: '',
			ranID: generateRandomID(),
		}

		additionalFilterDispatch({
			type: DATA_OBJ_ACTION_TYPES.ADD,
			target: {
				defaultItem: newItem,
			},
		})
	}

	const [value, setValue] = useState('')

	const generateAdditionalFilterValue = () => {
		let nameTmp = ''
		additionalFilter?.map((item) => {
			const column = allColumns?.find((item2) => item2.name === item?.field)
			let field = column?.title
			if (!field) {
				field = item?.target
			}
			let ope = operatorMap[item?.operator]
			if (!ope) {
				ope = operators.find((item2) => item2.type === item?.operator)?.name
			}
			ope = ope
				? ope
				: stringFilter[item?.operator]
					? stringFilter[item?.operator]
					: numberFilter[item?.operator]
						? numberFilter[item?.operator]
						: dateFilter[item?.operator]
							? dateFilter[item?.operator]
							: item?.operator

			let val = ''
			if (item.value) {
				if (column?.type == 'DATETIME') {
					val = new Date(item.value)
						.toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' })
						.replace(',', '')
				} else {
					val = item.value
				}
			}
			nameTmp += ', ' + field + ' ' + ope + ' ' + val
		})

		if (nameTmp.length > 1) {
			nameTmp = nameTmp.substring(2)
		}

		if (!nameTmp) {
			nameTmp = ''
		}

		if (nameTmp != value) {
			setValue(nameTmp)
		}
	}

	useEffect(() => {
		if (allColumnsLoading || isEmpty(allColumns) || isEmpty(additionalFilter)) {
			setValue('')
			return
		}
		generateAdditionalFilterValue()
	}, [allColumnsLoading, additionalFilter, allColumns])

	return (
		<CollapseComponent
			title={title}
			value={value}
			expanded={expandedAccordion === title}
			setExpandedAccordion={setExpandedAccordion}
			footer={
				<Typography
					variant="subtitle1"
					style={{
						color: 'gray',
					}}
				>
					Additional filters will be applied against the data retrieved from the server.
				</Typography>
			}
			content={
				<div>
					{!isEmpty(listActiveFieldsOptions) &&
						additionalFilter?.map((item, index) => {
							//return renderFilterRow(item)
							return (
								<AdditionalFilterCom
									key={item.id || item.ranID}
									index={index}
									item={item}
									additionalFilterDispatch={additionalFilterDispatch}
									listActiveFieldsOptions={listActiveFieldsOptions}
									allColumns={allColumns}
								/>
							)
						})}

					<div>
						<Button variant="text" onClick={addFilter}>
							+ Add filter
						</Button>
					</div>
				</div>
			}
		/>
	)
}

export const AdditionalFiltersCollapse = memo(AdditionalFiltersComponent, shouldPreventRender)
