export const INVALID_XFO_URLS = {
	GOOGLE: ['google.com'],
}

const TO_DEFAULT_XFO_URL_FUNCTIONS = {
	GOOGLE: (url) => 'https://google.com/search?igu=1',
}

function getXFOConvertKey(url) {
	for (let [key, value] of Object.entries(INVALID_XFO_URLS)) {
		const isInvalidUrl = value.some((item) => url.toLowerCase().includes(item.toLowerCase()))
		if (isInvalidUrl) return key

		continue
	}

	return ''
}

export function getDefaultXFOUrl(url) {
	if (!url) return ''

	const invalidXFOKey = getXFOConvertKey(url)
	if (!invalidXFOKey) return url

	return TO_DEFAULT_XFO_URL_FUNCTIONS[invalidXFOKey](url)
}

export function validURL(str, allowEmpty) {
	if (!str) return allowEmpty

	var pattern =
		/^(?:(?:https?|ftps?):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9])*\.[a-z\u00a1-\uffff]{2,})(?::\d{2,5})?(?:\/[^\s]*)?$/i

	try {
		if (!pattern.test(str)) {
			return false
		} else {
			return true
		}
	} catch (err) {
		return false
	}
}

export function getURLWithProtocol(url, protocol) {
	if (url.includes(protocol)) return url

	return protocol + url
}
