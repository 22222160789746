import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import menuApi from 'apis/disApi/menuApi'
import { MY_MENU_KEY } from 'components/core/Layouts/DisLayout'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const menuKeys = {
	all: ['menus'],
	details: () => [...menuKeys.all, 'detail'],
	detail: (id) => [...menuKeys.details(), id],
}

export default function useMenuQuery({ ...config } = {}) {
	const { environment } = useSelector((state) => state)

	const queryClient = useQueryClient()
	const queryKey = menuKeys.all

	const { data, isLoading, error, isFetching } = useQuery(
		queryKey,
		() => menuApi.getAll(environment.apiToken),
		{
			...config,
		}
	)

	const menuList = useMemo(() => {
		if (data?.data?.length > 0) {
			return data?.data?.filter((x) => !x.isDefault)
		}
		return []
	}, [data?.data])

	const addMutation = useMutation(menuApi.add, {
		onSuccess: () => queryClient.invalidateQueries(queryKey),
	})

	const updateMutation = useMutation(menuApi.update, {
		onSuccess: () => {
			queryClient.invalidateQueries(queryKey)
			// Update menu bar
			queryClient.invalidateQueries([MY_MENU_KEY])
		},
	})

	const removeMutation = useMutation(menuApi.remove, {
		onSuccess: () => queryClient.invalidateQueries(queryKey),
	})

	const handleRefresh = () => queryClient.invalidateQueries(queryKey)

	return {
		isLoading: isFetching && isLoading,
		data,
		error,
		menuList,
		addMutation,
		updateMutation,
		removeMutation,
		onRefreshMenu: handleRefresh,
	}
}
