import { useEffect, useState, useMemo, useLayoutEffect, useContext, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridPremium } from '@mui/x-data-grid-premium'

import { Typography } from '@mui/material'

import { makeStyles } from '@mui/styles'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'

import CollapseComponent from './CollapseComponent'
import { shouldPreventRender } from '../ViewDialogUtils'
import { isEmpty, differenceBy } from 'lodash'

function arrayMove(arr, fromIndex, toIndex) {
	var element = arr[fromIndex]
	arr.splice(fromIndex, 1)
	arr.splice(toIndex, 0, element)
}

const headerColumns = [
	{
		field: 'name',
		headerName: 'Data Name',
		width: 200,
		valueGetter: (value, row) => {
			if (!value) return row?.name || ''
			return value
		},
	},
	{
		field: 'title',
		headerName: 'Caption',
		width: 400,
	},
]

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiButton-root': {
			textTransform: 'none',
		},
		'& .MuiList': {
			backgroundColor: 'ghostwhite',
		},
		'& .MuiDataGrid-footerContainer': {
			width: '100%',
			display: 'inline-flex',
			justifyContent: 'space-between',
			'& .MuiTablePagination-selectLabel': {
				marginTop: '1em',
			},
			'& .MuiTablePagination-displayedRows': {
				marginTop: '1em',
			},
		},
		'& .MuiDataGrid-columnHeader': {
			'& .MuiDataGrid-columnHeaderTitle': {
				lineHeight: '20px',
				fontSize: '14px',
			},
		},
		'& .MuiDataGrid-virtualScroller': {
			position: 'absolute !important',
		},
		'& .MuiDataGrid-main': {
			minHeight: '200px',
		},
		'&.grid-loaded .MuiDataGrid-main': {
			minHeight: '400px',
			overflowY: 'hidden',
		},
	},
}))

const SelectTheFieldsComponent = ({
	allColumns,
	allColumnsLoading,
	listActiveFields,
	setListActiveFields,
	expandedAccordion,
	viewDataColumns,
	setExpandedAccordion,
}) => {
	const [t] = useTranslation('common')
	const title = useMemo(() => t('common:view.selectTheFields'), [t])
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const viewColumns = useMemo(() => {
		if (allColumnsLoading || allColumns?.length === 0) {
			return []
		}

		const remainColumns = differenceBy(allColumns, viewDataColumns, 'name')
		return [...viewDataColumns, ...remainColumns]
	}, [allColumns, viewDataColumns, allColumnsLoading])

	const [allRows, setAllRows] = useState([])
	const [loading, setLoading] = useState(false)
	const [rowSelectionModel, setRowSelectionModel] = useState([])
	const [forceUpdate, setForceUpdate] = useState(true)
	const [value, setValue] = useState([])

	const generateFieldsValue = (allColumns, listActiveFields) => {
		let nameTmp = ''
		if (allColumns) {
			const tmp = allColumns.filter((item) => {
				return listActiveFields?.includes && listActiveFields?.includes(item.name)
			})
			nameTmp = tmp.map((item) => item.title).join(', ')
		}
		if (nameTmp !== value) {
			setValue(nameTmp)
		}
	}

	useEffect(() => {
		if (viewColumns?.length == 0) {
			setAllRows([])
		}

		setAllRows(
			viewColumns.map((value, index) => {
				return {
					id: index,
					...value,
				}
			})
		)
	}, [viewColumns, listActiveFields, forceUpdate])

	useLayoutEffect(() => {
		if (isEmpty(allRows)) {
			setValue('')
			return
		}

		generateFieldsValue(allRows, listActiveFields)
	}, [allRows, listActiveFields])

	// initdata
	useEffect(() => {
		if (!allRows || allRows.length === 0 || !listActiveFields || listActiveFields.length === 0) {
			setRowSelectionModel([])
			return
		}

		setLoading(true)
		const currertActiveFieldIds = allRows
			.filter((row) => listActiveFields.includes(row.name))
			.map((row) => row.id)
		setRowSelectionModel(currertActiveFieldIds)
		setLoading(false)
	}, [allRows, listActiveFields])

	return (
		<CollapseComponent
			title={title}
			value={value}
			footer={
				<Typography
					variant="subtitle1"
					style={{
						color: 'gray',
					}}
				>
					Drag rows to change column order
				</Typography>
			}
			expanded={expandedAccordion === title}
			setExpandedAccordion={setExpandedAccordion}
			content={
				<DataGridPremium
					sx={{
						maxHeight: '500px !important',
						'& .MuiDataGrid-columnHeaderTitleContainerContent': {
							mb: '10px !important',
						},
					}}
					className={`${classes.root} ${allRows.length > 0 ? 'grid-loaded' : ''}`}
					localeText={{
						// Root
						noRowsLabel: t('common:misc.noRows'),
						noResultsOverlayLabel: t('common:misc.noResultsOverlayLabel'),
						errorOverlayDefaultLabel: t('common:misc.errorOverlayDefaultLabel'),

						// Column header text
						columnHeaderFiltersTooltipActive: (count) =>
							count !== 1
								? `${count} ${t('common:misc.columnHeaderFiltersTooltipActive')}`
								: `${count} ${t('common:misc.columnHeaderFilterTooltipActive')}`,
						columnHeaderFiltersLabel: t('common:misc.columnHeaderFiltersLabel'),
						columnHeaderSortIconLabel: t('common:misc.columnHeaderSortIconLabel'),

						// Total row amount footer text
						footerTotalRows: t('common:misc.footerTotalRows'),

						footerRowSelected: (count) =>
							count !== 1
								? `${count.toLocaleString()} ${t('common:misc.footerRowsSelected')}`
								: `${count.toLocaleString()} ${t('common:misc.footerRowSelected')}`,

						// Checkbox selection text
						checkboxSelectionHeaderName: t('common:misc.checkboxSelectionHeaderName'),
						checkboxSelectionSelectAllRows: t('common:misc.checkboxSelectionSelectAllRows'),
						checkboxSelectionUnselectAllRows: t('common:misc.checkboxSelectionUnselectAllRows'),
						checkboxSelectionSelectRow: t('common:misc.checkboxSelectionSelectRow'),
						checkboxSelectionUnselectRow: t('common:misc.checkboxSelectionUnselectRow'),
					}}
					loading={allColumnsLoading || loading}
					rows={allRows}
					columns={headerColumns}
					density={'compact'}
					rowReordering
					onRowOrderChange={(params, event, details) => {
						const tmpArray = viewColumns
						arrayMove(tmpArray, params.oldIndex, params.targetIndex)
						const arrayWithId = tmpArray.map((value, index) => {
							return {
								id: index,
								...value,
							}
						})
						const currertActiveField = listActiveFields
						const currertActiveFieldIds = arrayWithId
							.filter((row) => currertActiveField.includes(row.name))
							.map((row) => row.id)
						const selectedRowName = arrayWithId
							.filter((row) => currertActiveFieldIds.includes(row.id))
							.sort((a, b) => a.id - b.id)
							.map((row) => row.name)
						setListActiveFields(selectedRowName)
						setAllRows(arrayWithId)
						setForceUpdate(!forceUpdate)
					}}
					checkboxSelection
					rowSelectionModel={rowSelectionModel}
					onRowSelectionModelChange={(ids) => {
						const selectedIDs = new Set(ids)
						const selectedRowName = allRows
							.filter((row) => selectedIDs.has(row.id))
							.sort((a, b) => a.id - b.id)
							.map((row) => row.name)
						setRowSelectionModel(ids)
						setListActiveFields(selectedRowName)
					}}
				/>
			}
		/>
	)
}

export const SelectTheFieldsCollapse = memo(SelectTheFieldsComponent, shouldPreventRender)
