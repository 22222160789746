import { Delete, RefreshOutlined } from '@mui/icons-material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { IconButton, List, ListItemButton, ListItemText, Stack, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'
import produce from 'immer'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { logErrorMessage } from 'utils/functions/helpers'
import { useSearchParams } from 'utils/hooks/useSearchParams'
import EmptyDashboard from '../_components/EmptyDashboard'
import SearchInput from '../_components/SearchInput'
import WrapperWithHeading from '../_components/WrapperWithHeading'
import ButtonWithConfirm from './_components/ButtonWithConfirm'

const useStyles = makeStyles(() => ({
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const TileDashboard = ({ tileList, draftMenuItems, parentMenuItem, setDraftMenuItems }) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const location = useLocation()
	const history = useHistory()

	const {
		key: menuDashboardKeyInUrl = '',
		isSubmenuItems = '',
		tile: tileKeyInUrl = '',
	} = useSearchParams()
	const isSubMenu = isSubmenuItems === 'true'

	const [searchText, setSearchText] = useState('')

	const filteredTileList = useMemo(() => {
		return tileList?.length > 0
			? tileList?.filter((tile) => {
					if (!searchText) {
						return true
					}

					return tile.name.toLowerCase().includes(searchText.toLowerCase())
			  })
			: []
	}, [JSON.stringify(tileList), searchText])

	const handleChangeSearchText = (value) => {
		const term = !value ? '' : value.toLowerCase()
		setSearchText(term)
	}

	const handleMenuClick = (key) => {
		const searchParams = new URLSearchParams(location.search)
		searchParams.set('tile', key)
		searchParams.delete('isCreatingTile')

		history.push({
			pathname: location.pathname,
			search: searchParams.toString(),
		})
	}

	const handleCreateNewMenu = () => {
		const searchParams = new URLSearchParams(location.search)
		searchParams.delete('tile')
		searchParams.set('isCreatingTile', true)

		history.push({
			pathname: location.pathname,
			search: searchParams.toString(),
		})
	}

	const handleDeleteTile = (tileKey) => {
		try {
			// 2. Update the target menu item (using ImmerJS for immutability)
			const updatedMenuItem = produce(parentMenuItem, (draft) => {
				if (isSubMenu) {
					const needEditedMenuIndex = draft?.items?.findIndex(
						(item) => item?.dashboard?.key === menuDashboardKeyInUrl
					)

					// Filter out the tile with the specified key
					if (needEditedMenuIndex !== -1) {
						draft.items[needEditedMenuIndex].tileInfo = parentMenuItem?.items[
							needEditedMenuIndex
						]?.tileInfo?.filter((tile) => tile.key !== tileKey)
					}
				} else {
					// Directly remove the tile with the specified key from the main menu's tileInfo
					const tileIndex = draft.tileInfo.findIndex((tile) => tile.key === tileKey)
					if (tileIndex !== -1) {
						draft.tileInfo.splice(tileIndex, 1)
					}
				}
			})

			// 3. Update the detail menu with the modified menu item
			const newDetailMenuItems = draftMenuItems.map((item) => {
				if (item?.dashboard?.key === updatedMenuItem?.dashboard?.key) {
					return updatedMenuItem
				} else {
					return item
				}
			})

			// 4. Update the menu state and UI
			setDraftMenuItems(newDetailMenuItems)

			// 5. Remove the tile from the URL
			if (tileKeyInUrl === tileKey) {
				const searchParams = new URLSearchParams(location.search)
				searchParams.delete('tile')
				history.push({
					pathname: location.pathname,
					search: searchParams.toString(),
				})
			}

			// 6. Show success message
			toast.success(t('common:misc.deleteSelected'))
		} catch (error) {
			logErrorMessage(error)
		}
	}

	return (
		<WrapperWithHeading title={t('common:dis.tiles')}>
			<Stack direction="row" alignItems="center" gap={1}>
				<SearchInput onChangeValue={handleChangeSearchText} />

				<Tooltip title={t('common:dis.createNewTile')} placement="top" arrow>
					<IconButton
						aria-label="add-new-menu"
						size="small"
						className={classes.button}
						onClick={handleCreateNewMenu}
					>
						<AddCircleOutlineIcon fontSize="inherit" className={classes.icon} />
					</IconButton>
				</Tooltip>
			</Stack>

			<List sx={{ overflowY: 'auto', height: '95%' }}>
				{filteredTileList?.length > 0 &&
					filteredTileList.map((item) => {
						return (
							<ListItemButton
								key={item?.key}
								selected={item.key === tileKeyInUrl}
								onClick={() => {
									handleMenuClick(item?.key)
								}}
								sx={{
									'&:hover': {
										textDecoration: 'underline',
										backgroundColor: 'transparent',
									},
								}}
							>
								<ListItemText primary={item.name} />
								<ButtonWithConfirm
									title={t('common:dis.confirmDeleteItem')}
									onSubmit={() => handleDeleteTile(item.key)}
								>
									<IconButton aria-label="copy" size="small">
										<Delete />
									</IconButton>
								</ButtonWithConfirm>
							</ListItemButton>
						)
					})}

				{filteredTileList?.length === 0 && <EmptyDashboard />}
			</List>
		</WrapperWithHeading>
	)
}

export default TileDashboard
