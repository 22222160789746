import { Box, Typography } from '@mui/material'
import React from 'react'

const WrapperWithHeading = ({ children, title }) => {
	return (
		<Box
			sx={{
				border: '1px solid #ddd',
				borderRadius: 2,
				p: 2,
				m: 2,
				position: 'relative',
			}}
		>
			<Typography
				variant="body2"
				component="div"
				sx={{
					position: 'absolute',
					top: -12,
					left: 20,
					py: 0.5,
					px: 1.5,
					background: 'white',
					fontWeight: 500,
				}}
			>
				{title}
			</Typography>

			{children}
		</Box>
	)
}

export default WrapperWithHeading
