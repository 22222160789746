import { useContext, useEffect, useRef, useState } from 'react'
//3rd libs
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
//utils
import { useFirstRender } from 'utils/hooks/useFirstRender'
import { ACTIVITY_RECEIVE_TIME_KEY } from '../../../constants'
import {
	callRecordsStorage,
	checkLoadColumnStorage,
	checkNeedCallQueryAPI,
	subtractSeconds,
	getLinkPrefix,
} from '../../../utils/functions/helpers'
import { DEVICES_ACTIONS } from '../../../reducers/devicesReducer'
import {
	cancelLoadNextRecordsAction,
	getActivityView,
	loadRecordsQuery,
} from '../../data/dataServices'
//context/redux
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { ACTIVITY_ACTIONS } from '../../../reducers/activityReducer'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
//components
import useGetCommonFunc from 'utils/hooks/useGetCommonFunc'
import axios from 'axios'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import useGetPathName from 'utils/hooks/useGetPathName'
import SkeletonLoaderDataGrid from '../../../custom-components/skeletons/SkeletonLoaderDataGrid'
import DoformsDataGrid from '../../data/datagrid/DoformsDataGrid'

const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		padding: '8px',
		'& .MuiButton-root': {
			textDecoration: 'none',
		},
	},
	dataGridToolbar: {
		minHeight: 20,
		paddingLeft: '0px',
		paddingBottom: '8px',
	},
	dialog: {
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	dialogTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
	},
	loading: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		position: 'absolute',
		top: '50%',
		left: '50%',
	},
	headingContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignContent: 'center',
		alignItems: 'center',
		paddingLeft: '10px',
	},
	headingContent: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'baseline',
	},
	headingLabel: {
		display: 'block',
		minWidth: '200px',
	},
	headingAction: {
		display: 'flex',
		alignItems: 'center',
	},
	headingProjectSelection: {
		display: 'inline-flex',
		width: '24em',
		maxWidth: '24em',
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const ActivityData = ({ environment, module }) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const apiToken = environment.apiToken
	const {
		action,
		dataGrid: { columns, query, queryView, records, viewSession },
		formDeleteSelection,
		activityRefresh,
		formColumns,
		formFilterConditions,
		formSelected,
		viewCreate,
		viewData,
		viewUpdate,
		rowsPerPage,
		clientFilters,
	} = module
	const firstPathName = useGetPathName()
	const isFirstRender = useFirstRender()
	const dispatch = useDispatch()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [info, setInfo] = useState(null)
	const [linkViewInfo, setLinkViewInfo] = useState(null)

	const [allRecordsLoading, setAllRecordsLoading] = useState(false)
	const [startLoadAllRecords, setStartLoadAllRecords] = useState(false)
	const [recordLoading, setRecordLoading] = useState(false)
	const [isCancelLoadingRecords, setIsCancelLoadingRecords] = useState(false)
	const isMounted = useRef(true)

	const { getAllRecords, loadUpdateColumns } = useGetCommonFunc({
		tab: 'activity',
		firstPathName,
		viewData,
		query,
		queryView,
		viewSession,
		apiToken,
		records,
		formColumns,
		setAllRecordsLoading,
		setStartLoadAllRecords,
		setLoading,
		setError,
	})

	useEffect(() => {
		// Checked when change column then switch tab will call back api to get correct data
		;(async () => {
			if (
				!_.isEmpty(formColumns) &&
				viewData.key &&
				isFirstRender &&
				checkLoadColumnStorage.get(firstPathName)
			) {
				await loadUpdateColumns()
				checkLoadColumnStorage.set(firstPathName, false)
				setRecordLoading(true)
			}
		})()
	}, [])

	useEffect(() => {
		isMounted.current = true
		checkLoadColumnStorage.set(firstPathName, false)
		return () => (isMounted.current = false)
	}, [])

	useEffect(() => {
		if (recordLoading) {
			loadRecords()
			setRecordLoading(false)
		}
	}, [recordLoading])

	useEffect(() => {
		if (!action) return
		if (!_.isEmpty(queryView)) return
		dispatch({
			type: ACTIVITY_ACTIONS.ACTION,
			payload: action,
		})
	}, [action])

	useEffect(() => {
		const cancelToken = axios.CancelToken.source()

		const getActivityViewData = async () => {
			try {
				setLoading(true)
				getActivityView(environment.apiToken, cancelToken).then((response) => {
					const responseData =
						response.data.queries[0].filter.conditions.findIndex(
							(item) => item.target === ACTIVITY_RECEIVE_TIME_KEY
						) !== -1
							? response.data
							: {
									...response.data,
									queries: response.data.queries.map((item, index) =>
										index === 0
											? {
													...item,
													filter: {
														...item.filter,
														conditions: [
															{
																target: ACTIVITY_RECEIVE_TIME_KEY,
																type: 'EQ',
																values: [],
																preset: 'TODAY',
																join: 'AND',
															},
															...item.filter.conditions,
														],
													},
											  }
											: item
									),
							  }
					const clientFilter = responseData?.clientFilter
						? JSON.parse(responseData?.clientFilter)
						: undefined
					if (clientFilter) {
						dispatch({
							type: ACTIVITY_ACTIONS.CLIENT_FILTERS,
							payload: {
								...clientFilters,
								activity: clientFilter,
							},
						})
					}
					dispatch({
						type: ACTIVITY_ACTIONS.DATA_GRID,
						payload: {
							columns: responseData.columns,
							query: responseData.queries[0],
							queryView: {
								queries: responseData.queries,
							},
						},
					})
					dispatch({
						type: ACTIVITY_ACTIONS.VIEW_DATA,
						payload: responseData,
					})

					if (isMounted.current) {
						setRecordLoading(true)
					}
				})
			} catch (err) {
				if (!axios.isCancel(err)) {
					setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
				}
			}
		}

		const newFullScreenDataModString = localStorage.getItem('newFullScreenData_mod')
		const newFullScreenDataMod = JSON.parse(newFullScreenDataModString)
		if (newFullScreenDataMod) return

		if (_.isEmpty(queryView)) {
			getActivityViewData()
		}

		return () => {
			cancelToken.cancel()
		}
	}, [queryView])

	useEffect(() => {
		if (_.isEmpty(environment.deviceAuditForm)) return
		if (
			_.isEqual(environment.deviceAuditForm.projectKey, formSelected.projectKey) &&
			_.isEqual(environment.deviceAuditForm.formKey, formSelected.key)
		)
			return
		if (!environment.projects.length) return
		const matchProject = environment.projects.find(
			(project) => project.key === environment.deviceAuditForm.projectKey
		)
		const matchForm = matchProject.forms.find(
			(form) => form.key === environment.deviceAuditForm.formKey
		)

		if (matchProject && matchForm) {
			const newFormSelected = {
				...matchForm,
				projectKey: matchProject.key,
				projectName: matchProject.name,
			}
			dispatch({
				type: DEVICES_ACTIONS.FORM_SELECTED,
				payload: newFormSelected,
			})
			dispatch({
				type: ACTIVITY_ACTIONS.FORM_SELECTED,
				payload: newFormSelected,
			})
		}
	}, [environment.deviceAuditForm])

	useEffect(() => {
		if (_.isEmpty(formFilterConditions)) return
		dispatch({
			type: ACTIVITY_ACTIONS.DATA_GRID,
			payload: {
				query: {
					...query,
					filter: {
						conditions: formFilterConditions,
					},
				},
			},
		})
	}, [formFilterConditions])

	useEffect(() => {
		if (startLoadAllRecords) {
			setStartLoadAllRecords(false)
			try {
				getAllRecords()
			} catch (err) {
				setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
			}
		}
	})

	useEffect(() => {
		if (isFirstRender) return
		checkLoadColumnStorage.set(firstPathName, true)
	}, [formColumns])

	useEffect(() => {
		;(async () => {
			try {
				if (_.isEmpty(formColumns) && isFirstRender) return
				setLoading(true)
				if (checkNeedCallQueryAPI(formColumns.map((item) => item.name)) || isCancelLoadingRecords) {
					if (!_.isEmpty(formColumns)) {
						await loadUpdateColumns()
					}
					setRecordLoading(true)
					setIsCancelLoadingRecords(false)
					checkLoadColumnStorage.set(firstPathName, false)
				} else {
					dispatch({
						type: ACTIVITY_ACTIONS.DATA_GRID,
						payload: {
							columns: formColumns,
							viewSession: true,
						},
					})
					setLoading(false)
				}
			} catch (err) {
				setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
				setLoading(false)
			}
		})()
	}, [formColumns])

	useEffect(() => {
		if (formDeleteSelection.length === 0) return
		setInfo(null)
		const selectedRecords = formDeleteSelection
		const infoMessage =
			selectedRecords.length > 1
				? t('common:misc.successfullyDeleted') +
				  ` ${selectedRecords.length} ` +
				  t('common:misc.records') +
				  `.`
				: t('common:misc.successfullyDeleted1Record')
		setInfo(infoMessage)
		dispatch({
			type: ACTIVITY_ACTIONS.FORM_DELETE_SELECTION,
			payload: [],
		})
	}, [formDeleteSelection])

	useEffect(() => {
		;(async () => {
			if (!activityRefresh) return
			try {
				setIsCancelLoadingRecords(false)
				// except activity view always recall load columns api because when columns change, it will affect other new page
				if (!_.isEmpty(formColumns)) {
					await loadUpdateColumns()
				}
				loadRecords()
				checkLoadColumnStorage.set(firstPathName, false)
			} catch (err) {
				setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
			}
		})()
	}, [activityRefresh])

	useEffect(() => {
		if (_.isEmpty(viewUpdate)) return
		setInfo(t('formsData.successfullySavedChanges'))
		dispatch({
			type: ACTIVITY_ACTIONS.VIEW_UPDATE,
			payload: {},
		})
	}, [viewUpdate])

	useEffect(() => {
		if (_.isEmpty(viewCreate)) return
		setInfo(t('common:misc.successfullyCreatedView') + ' ')
		setLinkViewInfo(viewCreate)
		dispatch({
			type: ACTIVITY_ACTIONS.VIEW_CREATE,
			payload: {},
		})
		dispatch({
			type: ENV_ACTIONS.HAS_NEW_VIEW,
			payload: true,
		})
	}, [viewCreate])

	const loadRecords = () => {
		setLoading(true)
		loadRecordsQuery(viewData.key, viewSession, query.filter, apiToken)
			.then((response) => {
				let queries = [...response.data.view.queries]
				queries[0].filter = { ...query.filter }
				dispatch({
					type: ACTIVITY_ACTIONS.DATA_GRID,
					payload: {
						queryView: { ...response.data.view, queries: queries },
						records: response.data.records,
					},
				})
			})
			.catch((err) => {
				setError(
					err.response
						? 'Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message
						: err.message
				)
			})
			.finally(() => {
				if (isMounted.current) {
					setLoading(false)
				}
				dispatch({
					type: ACTIVITY_ACTIONS.ACTIVITY_REFRESH,
					payload: false,
				})
				callRecordsStorage.remove(firstPathName)
			})
	}

	const handleReLoadFirstPage = async (viewData, lastUpdatedTime) => {
		setLoading(true)

		const endOfDay = new Date()
		endOfDay.setHours(23, 59, 59, 999)

		const newDate = new Date(`${lastUpdatedTime}`)
		const lastUpdatedTimeMinus5Second = subtractSeconds(5, newDate)

		let filter
		const isRightColumnMissingItem = callRecordsStorage.get(firstPathName)
		if (isRightColumnMissingItem) {
			filter = query.filter
		} else {
			filter = {
				conditions: query.filter.conditions.map((item) =>
					item.target === ACTIVITY_RECEIVE_TIME_KEY
						? {
								target: ACTIVITY_RECEIVE_TIME_KEY,
								type: 'BT',
								join: 'AND',
								values: [lastUpdatedTimeMinus5Second, endOfDay],
						  }
						: item
				),
			}
		}

		if (!_.isEmpty(formColumns)) {
			await loadUpdateColumns()
		}
		loadRecordsQuery(viewData.key, viewSession, filter, apiToken)
			.then((response) => {
				const newRecords = _.uniqBy([...response.data.records, ...records], 'activityKey')
				dispatch({
					type: ACTIVITY_ACTIONS.DATA_GRID,
					payload: {
						records: newRecords,
					},
				})
				setLoading(false)
				callRecordsStorage.remove(firstPathName)
				return newRecords
			})
			.catch((err) => {
				setLoading(false)
				setError(
					err.response
						? 'Code ' + err.response.data.code + ': ' + err.response.data.message
						: err.message
				)
				return err
			})
	}

	const handlePresetReloadAll = () => {
		let queries = [].concat(queryView.queries)
		queries[0] = { ...queries[0], cursor: null, more: true }
		dispatch({
			type: ACTIVITY_ACTIONS.DATA_GRID,
			payload: {
				queryView: { ...queryView, more: true, queries: queries },
			},
		})
	}

	const showLoading = () => {
		if (loading)
			return (
				<>
					<LoadingSpinner />
					{_.isEmpty(viewData) && <SkeletonLoaderDataGrid />}
				</>
			)
	}

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const showInfoMessage = () =>
		info && (
			<DoformsMessage
				linkViewInfo={linkViewInfo}
				message={info}
				severity={'success'}
				onMessageClosed={handleMessageClosed}
			/>
		)

	const handleMessageClosed = () => {
		setError(null)
		setInfo(null)
	}

	const handleResizeColumn = (selectedField, newWidth) => {
		const newColumns = columns.map((col) => {
			if (col.name === selectedField) {
				col.width = newWidth
			}
			return col
		})
		dispatch({
			type: ACTIVITY_ACTIONS.DATA_GRID,
			payload: {
				columns: newColumns,
			},
		})
	}

	const handleLoadAllRecords = () => {
		setStartLoadAllRecords(true)
	}

	const handleCancelLoadingRecords = () => {
		setIsCancelLoadingRecords(true)
		setStartLoadAllRecords(false)
		cancelLoadNextRecordsAction()
	}

	const onHandleOpenFullScreenTabDevice = () => {
		onHandleOpenFullScreenTab()
	}

	const onHandleOpenFullScreenTab = () => {
		localStorage.setItem('newFullScreenData_env', JSON.stringify({ environment: environment }))
		localStorage.setItem('newFullScreenData_mod', JSON.stringify({ activityModule: module }))
		//window.open('/activity' + '?action=' + action, '_blank_' + Math.random())
		window.open(getLinkPrefix('/activity'), '_blank_' + Math.random())
	}

	return (
		<div className={classes.root}>
			{showLoading()}
			{showErrorMessage()}
			{showInfoMessage()}
			{viewData && (
				<DoformsDataGrid
					tab={'activity'}
					isLoading={loading}
					isRefresh={activityRefresh}
					title={''}
					action={action}
					environment={environment}
					query={query}
					viewData={viewData}
					formSelected={formSelected}
					columns={columns}
					records={records}
					queryView={queryView}
					clientFilters={clientFilters}
					recordsLoading={false}
					allRecordsLoading={allRecordsLoading}
					rowsPerPage={rowsPerPage}
					setError={setError}
					onHandledResizeColumn={handleResizeColumn}
					onHandledLoadAllRecords={handleLoadAllRecords}
					onHandledCancelLoadingRecords={handleCancelLoadingRecords}
					onHandleOpenFullScreenDevicesTab={onHandleOpenFullScreenTabDevice}
					onHandleReLoadFirstPage={handleReLoadFirstPage}
					onHandlePresetReloadAll={handlePresetReloadAll}
					setLoading={setLoading}
				/>
			)}
		</div>
	)
}

export default ActivityData
