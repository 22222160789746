import {
	List,
	ListItem,
	ListItemButton,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { isEqual } from 'lodash'
import React, { useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { EnvironmentContext } from '../../../custom-components/context/EnvironmentContext'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import SkeletonLoaderSidePanel from '../../../custom-components/skeletons/SkeletonLoaderSidePanel'
import { LOOKUPS_ACTIONS } from '../../../reducers/lookupsReducer'
import DashboardSearchBar from '../../core/Dashboard/DashboardSearchBar'
import DoformsCreateSubmission from '../../data/submissions/DoformsCreateSubmission'
import LookupsButton from './LookupsButton'
import { VIEW } from '../../../constants'

const useStyles = makeStyles(() => ({
	lookupItem: {
		padding: 0,
		display: 'flex',
		overflow: 'hidden',
		justifyContent: 'center',
		alignItems: 'stretch',
	},
	lookupItemBtn: {
		display: 'flex',
		padding: '1px 0px 1px 16px',
		'&:hover': {
			backgroundColor: 'white',
		},
		'& .MuiTypography-root': {
			display: 'block',
			fontSize: '15px',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	lookupItemToolbar: {
		position: 'relative',
		top: 'auto',
		right: 'auto',
		display: 'flex',
		flex: '1 1 auto',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		backgroundColor: '#fff',
		transform: 'none',
		paddingRight: '2px',
	},
	lookupMenuListContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		flex: '1 1 0',
		overflow: 'hidden',
	},
	lookupMenuList: {
		paddingTop: 0,
		overflow: 'hidden',
		height: 'inherit',
		'&:not(.loading):hover': {
			overflowY: 'auto',
		},
		'& .MuiListItem-root': {
			flexDirection: 'column',
		},
		'& .MuiCollapse-root': {
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'stretch',
		},
		'& .MuiListItem-container': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		'& .MuiListItem-container:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	loadingWrapper: {
		position: 'absolute',
		top: '20%',
		left: '45%',
		zIndex: '99999',
	},
}))

const LookupsDashboard = (props) => {
	const [t] = useTranslation('common')
	const classes = useStyles()
	const { lookupsProject, module, loading } = props
	const { environment } = useContext(EnvironmentContext)
	const userRights = environment.userCurrent.rights

	const { lookupsModule } = useSelector((state) => state)
	const { formSelected, action } = lookupsModule

	const [itemName, setItemName] = useState(null)
	const [hovering, setHovering] = useState(false)
	const [openSubmission, setOpenSubmission] = useState(false)
	const [skeletonLoading, setSkeletonLoading] = useState(true)
	const [searchText, setSearchText] = useState('')

	const filteredLookupsProject = useMemo(() => {
		const forms = (lookupsProject || {}).forms
		if (searchText === '' || !forms?.length) {
			return lookupsProject
		} else {
			const filteredResult = forms.filter((form) => form.name.toLowerCase().includes(searchText))
			return { ...lookupsProject, forms: filteredResult }
		}
	}, [searchText, lookupsProject])

	const dispatch = useDispatch()

	useLayoutEffect(() => {
		if (lookupsProject && lookupsProject.length > 0) {
			setSkeletonLoading(false)
		} else {
			setSkeletonLoading(true)
		}
	}, [lookupsProject])

	const showLoading = () => (
		<>
			<div className={classes.loadingWrapper}>
				<LoadingSpinner withStyle={false} />
			</div>
			{skeletonLoading && <SkeletonLoaderSidePanel />}
		</>
	)

	const handlePopoverOpen = useCallback(
		(newItemName) => (event) => {
			event.preventDefault()
			setHovering((prev) => itemName !== newItemName || !prev)
			setItemName(newItemName)
		},
		[]
	)

	const handlePopoverClose = (e) => {
		e.preventDefault()
		if (openSubmission) return
		setHovering(false)
	}

	const handleOpenSubmission = (value) => {
		setOpenSubmission(!!value)
	}

	const showItemActions = (form, projectKey) =>
		itemName === form.name && (
			<ListItemSecondaryAction className={classes.lookupItemToolbar}>
				<LookupsButton action={'audit'} form={form} projectKey={projectKey} module={module} />
				{!environment.isReadOnlySubmission && (
					<DoformsCreateSubmission
						onDialogOpen={handleOpenSubmission}
						environment={environment}
						formSelected={form}
						action={'add'}
						title={form.name}
						recordsLoading={false}
						source={'button'}
						tab={'lookups'}
					/>
				)}
			</ListItemSecondaryAction>
		)

	const onChangeValueHandler = (value) => {
		const term = !value ? '' : value.toLowerCase()
		setSearchText(term)
	}

	const clickHandler = (action, form) => (event) => {
		event.preventDefault()
		const reducerItems = { action: module.action, formSelected: module.formSelected }
		const selectedItems = { action: action, formSelected: form }
		if (action === 'audit' && isEqual(reducerItems, selectedItems)) {
			dispatch({
				type: LOOKUPS_ACTIONS.LOOKUPS_REFRESH,
				payload: true,
			})
		} else {
			dispatch({
				type: LOOKUPS_ACTIONS.FORM_SELECTED,
				payload: form,
			})
			dispatch({
				type: LOOKUPS_ACTIONS.ACTION,
				payload: action,
			})
		}
	}

	return (
		<>
			<DashboardSearchBar onChangedValue={onChangeValueHandler} tab={VIEW.VIEW_TAB_LOOKUPS} />
			<div className={classes.lookupMenuListContainer}>
				{loading && showLoading()}
				<List
					className={`${classes.lookupMenuList} ${
						loading ? t('common:misc.loading').toLowerCase() : ''
					}`}
				>
					{filteredLookupsProject?.forms?.length > 0 &&
						filteredLookupsProject.forms.map((form) => (
							<ListItem
								key={form.key}
								className={classes.lookupItem}
								onMouseEnter={handlePopoverOpen(form.name)}
								ContainerProps={{ onMouseLeave: handlePopoverClose }}
							>
								<ListItemButton
									className={classes.lookupItemBtn}
									disabled={formSelected?.key === form?.key && action === 'audit'}
									onClick={clickHandler('audit', form)}
								>
									<ListItemText
										primary={
											<Tooltip
												title={form.name}
												arrow
												placement="bottom-start"
												disableInteractive
												enterDelay={1000}
												enterNextDelay={1000}
											>
												<span>{form.name}</span>
											</Tooltip>
										}
									/>
								</ListItemButton>
								{hovering && showItemActions(form, filteredLookupsProject.key)}
							</ListItem>
						))}
				</List>
			</div>
		</>
	)
}

export default LookupsDashboard
