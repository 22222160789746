import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { EnvironmentProvider } from '../../../custom-components/context/EnvironmentContext'
import { IconThemeProvider } from '../../../custom-components/context/IconThemesContext'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import { useUrlQueryParams } from 'utils/hooks/useUrlQueryParams'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import { VIEWS_ACTIONS } from '../../../reducers/viewsReducer'
import DashboardSplitPane from '../../core/Dashboard/DashboardSplitPane'
import { computeOwnerNameByType } from '../../data/dataHelpers'
import { getAllOwners, getOwnerViews } from '../../data/dataServices'
import ViewsDashboard from './ViewsDashboard'
import ViewsData from './ViewsData'

const useStyles = makeStyles(() => ({
	formSidePanel: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		height: '100%',
	},
	formBody: {
		display: 'contents',
	},
}))

const ViewsModule = () => {
	const { id: paramId } = useParams()

	const urlQueryParam = useUrlQueryParams()
	const actionQuery = urlQueryParam.get('action')
	const [t] = useTranslation('common')
	const classes = useStyles()

	const { environment, viewsModule } = useSelector((state) => state)
	const iconTheme = environment.theme.icons

	const [owners, setOwners] = useState(environment.owners)
	const [newView, setNewView] = useState({})
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	const dispatch = useDispatch()
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		return () => (isMounted.current = false)
	}, [])

	useEffect(() => {
		var newFullScreenDataModString = localStorage.getItem('newFullScreenData_mod')
		if (!_.isEmpty(paramId) && newFullScreenDataModString != null) {
			var newFullScreenDataMod = JSON.parse(newFullScreenDataModString)
			var viewsModuleTmp = newFullScreenDataMod.viewsModule
			dispatch({
				type: VIEWS_ACTIONS.VIEW_RESTORE_PRODUCER,
				payload: viewsModuleTmp,
			})
			localStorage.removeItem('newFullScreenData_mod')
		} else {
			dispatch({
				type: VIEWS_ACTIONS.TAB_CHANGE,
				payload: VIEWS_ACTIONS.NAME,
			})

			if (!environment.owners.length) {
				initiateLoadAllOwners()
			} else if (!environment.views.length) {
				initiateLoadViewsByOwners(environment.owners)
			} else {
				setOwners(environment.owners)
			}
		}
	}, [JSON.stringify(environment.owners)])

	useEffect(() => {
		if (!environment.refresh) return
		setLoading(true)
		dispatch({
			type: ENV_ACTIONS.CLEAR_VIEWS,
		})
		initiateLoadAllOwners()
		dispatch({
			type: ENV_ACTIONS.REFRESH,
			payload: false,
		})
	}, [environment.refresh])

	useEffect(() => {
		if (!environment.hasNewView) return
		setLoading(true)
		initiateLoadAllOwners()
		dispatch({
			type: ENV_ACTIONS.HAS_NEW_VIEW,
			payload: false,
		})
	}, [environment.hasNewView])

	useEffect(() => {
		if (_.isEmpty(viewsModule.viewCreate) || !viewsModule.hasNewView) return
		setNewView({})
		getOwnerViews(viewsModule.viewCreate.ownerKey, environment.apiToken)
			.then((res) => {
				const difference = _.differenceWith(res.data, environment.views, _.isEqual)
				if (difference) {
					setNewView(difference[0])
				}
			})
			.catch((err) => {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
			})
			.finally(() => {
				dispatch({
					type: VIEWS_ACTIONS.HAS_NEW_VIEW,
					payload: false,
				})
				dispatch({
					type: VIEWS_ACTIONS.VIEW_CREATE,
					payload: {},
				})
			})
	}, [viewsModule.hasNewView])

	useEffect(() => {
		if (_.isEmpty(viewsModule.viewDeleted) || !viewsModule.hasDeletedView) return
		const viewDeleted = viewsModule.viewDeleted
		getOwnerViews(viewsModule.viewDeleted.ownerKey, environment.apiToken)
			.then((res) => {
				const ownerViews = res.data
				const newOwners = owners.map((owner) => {
					if (owner.key === viewDeleted.ownerKey) {
						owner.views = _.sortBy(ownerViews, (view) => view.name.toLowerCase())
					}
					return owner
				})
				setOwners(newOwners)
				dispatch({
					type: ENV_ACTIONS.GET_OWNERS,
					payload: newOwners,
				})
				dispatch({
					type: ENV_ACTIONS.REMOVE_FROM_VIEW,
					payload: viewDeleted,
				})
			})
			.catch((err) => {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
			})
			.finally(() => {
				dispatch({
					type: VIEWS_ACTIONS.HAS_NEW_VIEW,
					payload: false,
				})
				dispatch({
					type: VIEWS_ACTIONS.VIEW_CREATE,
					payload: {},
				})
			})
	}, [viewsModule.hasDeletedView])

	useEffect(() => {
		if (_.isEmpty(newView)) return
		const newOwners = owners.map((owner) => {
			if (owner.key === newView.ownerKey) {
				owner.views.push(newView)
			}
			return owner
		})
		setOwners(newOwners)
		dispatch({
			type: ENV_ACTIONS.GET_OWNERS,
			payload: newOwners,
		})
		dispatch({
			type: ENV_ACTIONS.GET_VIEWS,
			payload: [newView],
		})
	}, [newView])

	const initiateLoadAllOwners = () => {
		setLoading(true)
		loadAllOwners()
			.then((res) => {
				let owners = res.data.map((owner) => ({
					...owner,
					name: owner.name ? owner.name : computeOwnerNameByType(owner.type, t),
				}))
				owners = _.sortBy(owners, (view) => view.name.toLowerCase())
				initiateLoadViewsByOwners(owners)
			})
			.catch((err) => {
				setError('Code ' + err.response?.data?.code + ': ' + err.response?.data?.message)
			})
	}

	const loadAllOwners = async () => {
		let promise = await getAllOwners(environment.apiToken)
		return promise
	}

	const initiateLoadViewsByOwners = (owners) => {
		setLoading(true)
		loadViewsByOwner(owners)
			.then((res) => {
				const newOwners = _.sortBy(res, (view) => view.name.toLowerCase())
				if (isMounted.current) {
					setOwners(newOwners)
				}
				dispatch({
					type: ENV_ACTIONS.GET_OWNERS,
					payload: newOwners,
				})
			})
			.catch((err) => {
				setError('Code ' + err.response?.data?.code + ': ' + err.response?.data?.message)
			})
			.finally(() => {
				if (isMounted.current) {
					setLoading(false)
				}
			})
	}

	const loadViewsByOwner = async (owners) => {
		let promises = []
		for (let i = 0; i < owners.length; i++) {
			const promise = getOwnerViews(owners[i].key, environment.apiToken).then((resp) => {
				let promiseObject = { ...owners[i], views: [] }
				if (resp?.data && _.isArray(resp.data) && resp.data.length) {
					promiseObject.views = _.sortBy(resp.data, (view) => view.name.toLowerCase())
					const newViews = _.differenceWith(promiseObject.views, environment.views, _.isEqual)
					if (newViews.length) {
						dispatch({
							type: ENV_ACTIONS.GET_VIEWS,
							payload: promiseObject.views,
						})
					}
				}
				return promiseObject
			})
			promises.push(promise)
		}

		return await Promise.all(promises)
	}

	const sidePanel = () => (
		<div id="form-side-panel" className={classes.formSidePanel}>
			<EnvironmentProvider values={environment}>
				<ViewsDashboard owners={owners} module={viewsModule} loading={loading} />
			</EnvironmentProvider>
		</div>
	)

	const formBody = () => (
		<div id="form-body" className={classes.formBody}>
			<ViewsData environment={environment} module={viewsModule} />
		</div>
	)

	return (
		<IconThemeProvider values={iconTheme}>
			{error && (
				<DoformsMessage message={error} severity={'error'} onMessageClosed={() => setError(null)} />
			)}
			<DashboardSplitPane sidePanel={sidePanel()} mainContent={formBody()} />
		</IconThemeProvider>
	)
}

export default ViewsModule
