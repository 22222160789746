import React from 'react'

export default function useCacheState(defaultValue, key) {
	const [value, setValue] = React.useState(() => {
		const stickyValue = window.sessionStorage.getItem(key)
		return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue
	})

	React.useEffect(() => {
		window.onunload = () => {
			window.sessionStorage.removeItem(key)
		}
	}, [])

	React.useEffect(() => {
		window.sessionStorage.setItem(key, JSON.stringify(value))

		return () => {
			window.sessionStorage.removeItem(key)
		}
	}, [key, value])

	return [value, setValue]
}
