import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getFileMimeType } from 'utils/functions/helpers'

const AudioScreen = ({ audios }) => {
	const { t } = useTranslation('common')

	return (
		<Box>
			{audios?.length === 0 ? (
				<Typography>{t('misc.noAudioFound')}.</Typography>
			) : (
				<Stack direction="row" flexWrap="wrap" spacing={2}>
					{audios.map((audio, index) => (
						<AudioPlayer
							key={index}
							audioBlob={audio.blob}
							fileName={audio.fileName}
							fileType={audio.fileType}
						/>
					))}
				</Stack>
			)}
		</Box>
	)
}

const AudioPlayer = ({ audioBlob, fileName, fileType }) => {
	const { t } = useTranslation('common')
	const [audioUrl, setAudioUrl] = React.useState('')
	const type = getFileMimeType(fileType)

	React.useEffect(() => {
		if (audioBlob) {
			const url = URL.createObjectURL(audioBlob)
			setAudioUrl(url)
			return () => {
				URL.revokeObjectURL(url)
			}
		}
	}, [audioBlob])

	return (
		<div>
			{audioUrl && (
				<audio controls>
					<source src={audioUrl} type={type} />
					{t('misc.yourBrowserDoesNotSupportTheVideoElement')}
				</audio>
			)}
			<Typography
				sx={{
					textAlign: 'center',
				}}
			>
				{fileName}
			</Typography>
		</div>
	)
}

export default AudioScreen
