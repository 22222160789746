import { Box, FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import menuApi from 'apis/disApi/menuApi'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const DEFAULT_MENU_KEY = 'default-menus'

const PageSelect = ({ value, onChange, isSmallSize }) => {
	const { t } = useTranslation('common')
	const { environment } = useSelector((state) => state)

	const { data, isLoading } = useQuery([DEFAULT_MENU_KEY], () => {
		return menuApi.getAll(environment.apiToken)
	})

	const defaultMemu = useMemo(() => {
		if (data?.data) {
			return data?.data?.find((item) => item.isDefault)?.items
		}
		return []
	}, [data?.data])

	return (
		<Box>
			{!isLoading ? (
				<FormControl fullWidth size={isSmallSize ? 'small' : 'medium'}>
					<InputLabel id="path-select-label">{t('common:input.page')}</InputLabel>
					<Select
						labelId="path-select-label"
						id="path-select"
						value={value || ''}
						label={t('common:input.page')}
						name="path"
						onChange={onChange}
					>
						<MenuItem value="">Empty</MenuItem>
						{defaultMemu.map((menu) => (
							<MenuItem value={menu?.page?.path} key={menu?.page?.path}>
								{menu.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			) : (
				<Skeleton variant="rectangular" width="100%" height={isSmallSize ? 40 : 56} />
			)}
		</Box>
	)
}

export default PageSelect
