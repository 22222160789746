import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'
import { LoadingButton } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import DoformsDialog from '../../../custom-components/DoformsDialog'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import { FORM_ACTIONS } from '../../../reducers/formsReducer'
import { LOOKUPS_ACTIONS } from '../../../reducers/lookupsReducer'
import { VIEWS_ACTIONS } from '../../../reducers/viewsReducer'
import { deleteRecord } from './recordsService'

const useStyles = makeStyles(() => ({
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))
const DoformsMultiSelectDelete = (props) => {
	const [t] = useTranslation('common')
	const { environment, records, selections, tab } = props
	const { iconTheme } = useContext(IconThemeContext)
	const { apiToken } = environment
	const classes = useStyles(iconTheme)

	const initState = {
		open: false,
		dialog: {
			title: t('common:misc.title'),
			message: t('common:misc.message'),
		},
	}
	const [values, setValues] = useState(initState)
	const { open, dialog } = values

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	const dispatch = useDispatch()

	const selectionRecords = useMemo(() => {
		if (!selections) return
		let result = selections.map((selection) => {
			if (typeof selection !== 'number') return null
			let record = records[selection - 1]
			record = {
				...record,
				id: selection,
				key: record.type === 'DISPATCH' ? record.dispatchKey : record.submissionKey,
			}
			return record
		}).filter(item => !!item)

		return result ? result : []
	}, [selections])

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setError(null)
	}

	const confirmMassDelete = () => {
		setValues({
			...values,
			open: true,
			dialog: {
				title: t('common:misc.delete'),
				message:
					selectionRecords.length > 1
						? t('common:misc.areYouSureYouWantToDeleteTheSelectedRecords')
						: t('common:misc.areYouSureYouWantToDeleteThisRecord'),
			},
		})
	}

	const handleClose = () => {
		setLoading(false)
		setValues({ ...values, open: false, dialog: initState.dialog })
	}

	const handleMultiDelete = () => {
		setLoading(true)
		performMultiDelete(selectionRecords)
			.then((res) => {
				switch (tab) {
					case 'lookups':
						dispatch({
							type: LOOKUPS_ACTIONS.FORM_DELETE_SELECTION,
							payload: res,
						})
						break
					case 'views':
						dispatch({
							type: VIEWS_ACTIONS.FORM_DELETE_SELECTION,
							payload: res,
						})
						break
					default:
						dispatch({
							type: FORM_ACTIONS.FORM_DELETE_SELECTION,
							payload: res,
						})
						break
				}
			})
			.catch((err) => {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
			})
			.finally(() => {
				setLoading(false)
				handleClose()
			})
	}

	const performMultiDelete = async (selectionRecords) => {
		let promises = []
		for (let i = 0; i < selectionRecords.length; i++) {
			let record = selectionRecords[i]
			await deleteRecord(record, apiToken).then((res) => {
				promises.push(record.key)
			})
		}
		return await Promise.all(promises)
	}

	return (
		<div>
			{showErrorMessage()}
			<LoadingButton
				id={'multi-select-delete'}
				className={classes.icon}
				loading={loading}
				loadingPosition="start"
				onClick={confirmMassDelete}
				startIcon={<DeleteSweepIcon />}
			>
				{t('common:misc.deleteSelected')}
			</LoadingButton>
			<DoformsDialog
				environment={environment}
				open={open}
				dialog={dialog}
				loading={loading}
				dialogAction={true}
				onClose={handleClose}
				onConfirm={handleMultiDelete}
			></DoformsDialog>
		</div>
	)
}

export default DoformsMultiSelectDelete
