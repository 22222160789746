import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import dashboardApi from 'apis/disApi/dashboardApi'
import { useSelector } from 'react-redux'
import { isJson } from '../../../../utils/functions/helpers'

export const dashboardKeys = {
	all: ['dashboards'],
	details: () => [...dashboardKeys.all, 'detail'],
	detail: (id) => [...dashboardKeys.details(), id],
}

export default function useDashboardQuery({ dashboardKey, enableConfig = true }) {
	const { environment } = useSelector((state) => state)
	const queryKey = dashboardKeys.detail(dashboardKey)
	const queryClient = useQueryClient()

	const { data, isLoading, error } = useQuery({
		queryKey,
		queryFn: () => dashboardApi.get(dashboardKey, environment.apiToken),
		enabled: Boolean(queryKey) && Boolean(dashboardKey) && enableConfig,
	})
	const dashboardKeyList =
		data?.data?.variables && isJson(data?.data?.variables)
			? JSON.parse(data?.data?.variables ?? '{}')
			: []

	const updateMutation = useMutation(dashboardApi.update, {
		onSuccess: () => queryClient.invalidateQueries(queryKey),
	})

	const removeMutation = useMutation(dashboardApi.remove, {
		onSuccess: () => queryClient.invalidateQueries(queryKey),
	})

	return {
		isLoading,
		error,
		dashboardKeyList,
		updateMutation,
		removeMutation,
	}
}
