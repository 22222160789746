import { apiUrl } from '../../../apis/apiUrl'
import axios from 'axios'
import { computeDateRange } from '../../../utils/functions/doformsDateUtil'

const url = apiUrl()

export const getFormMapEvents = async (projectKey, formKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/form/events?@projectKey=${projectKey}&@formKey=${formKey}&@beginDate=${beginDate}&@endDate=${endDate}&@has=position`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getFormMapLocations = async (projectKey, formKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/form/locations?@projectKey=${projectKey}&@formKey=${formKey}&@beginDate=${beginDate}&@endDate=${endDate}`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getDeviceMapLocations = async (deviceKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/device/locations?@deviceKey=${deviceKey}&@beginDate=${beginDate}&@endDate=${endDate}`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getDeviceTracks = async (deviceKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/device/tracks?@deviceKey=${deviceKey}&@beginDate=${beginDate}&@endDate=${endDate}`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getTeamDevices = async (teamKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/team/devices?@teamKey=${teamKey}&@beginDate=${beginDate}&@endDate=${endDate}`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getDeviceEvents = async (deviceKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/device/events?@deviceKey=${deviceKey}&@beginDate=${beginDate}&@endDate=${endDate}`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getDeviceGeofences = async (deviceKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/device/geofences?@deviceKey=${deviceKey}&@beginDate=${beginDate}&@endDate=${endDate}`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getDeviceTimeSheets = async (deviceKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/device/timesheets?@deviceKey=${deviceKey}&@beginDate=${beginDate}&@endDate=${endDate}`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getDeviceTripReport = async (deviceKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/device/reports/trip?@deviceKey=${deviceKey}&@beginDate=${beginDate}&@endDate=${endDate}`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getTeamDeviceTripReport = async (teamKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/team/reports/trip?@teamKey=${teamKey}&@beginDate=${beginDate}&@endDate=${endDate}`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getDeviceStopReport = async (deviceKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/device/reports/stop?@deviceKey=${deviceKey}&@beginDate=${beginDate}&@endDate=${endDate}`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getTeamDeviceStopReport = async (teamKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/team/reports/stop?@teamKey=${teamKey}&@beginDate=${beginDate}&@endDate=${endDate}`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getDeviceGeofencesReport = async (deviceKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/device/reports/geofence?@deviceKey=${deviceKey}&@beginDate=${beginDate}&@endDate=${endDate}`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getDeviceTimeSheetsReport = async (deviceKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/device/reports/timesheet?@deviceKey=${deviceKey}&@beginDate=${beginDate}&@endDate=${endDate}`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getTeamDeviceTimeSheetsReport = async (teamKey, dateValue, offset, token) => {
	let dateRange = computeDateRange(dateValue, offset)
	const beginDate = dateRange[0]
	const endDate = dateRange[1]

	return await axios.get(
		`${url}/maps/team/reports/timesheet?@teamKey=${teamKey}&@beginDate=${beginDate}&@endDate=${endDate}`,
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getRecordName = async (recordKey, token) => {
	return await axios.get(`${url}/records/${recordKey}/record`, {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
}

export const getDispatchEvents = async (dispatchKey, token) => {
	return await axios.get(`${url}/events?@dispatchKey=${dispatchKey}&@has=position`, {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
}
