import axios from 'axios'
import { apiUrl } from '../apiUrl'

const url = apiUrl()
const apiHeader = (token) => {
    return {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
}

const feedApi = {
    getAll(token, cancelToken) {
        return axios.get(`${url}/accounts/current/feed`, {
            headers: apiHeader(token),
            cancelToken: cancelToken?.token,
        })
    },
    deviceFeed(mobileKey, token, cancelToken) {
        return axios.get(`${url}/devices/${mobileKey}/feed`, {
            headers: apiHeader(token),
            cancelToken: cancelToken?.token,
        })
    }
}

export default feedApi
