/**
 * @method convertDistance
 * @param {number} distance in meters
 * @param {string} unit
 * @return {number} distance in specified units
 */
export const convertDistance = (distance, unit) => {
	let result = distance || 0

	switch (unit) {
		case 'feet':
			result *= 1 / 0.3048
			break
		case 'kilometers':
			result *= 1 / 1000
			break
		case 'meters':
			break
		case 'miles':
			result *= 1 / 1609.34
			break
		default:
			throw new Error('invalid unit: ' + unit)
	}

	return result
}

/**
 * @method convertSpeed
 * @param {number} speed in meters/second
 * @param {string} units in format 'distance/time'
 * @return {number} speed in specified units
 */
export const convertSpeed = (speed, units) => {
	if (!units) throw new Error('missing units')

	let parts = units.split('/')
	let distanceUnit = parts[0]
	let timeUnit = parts[1]
	let result = speed || 0

	switch (distanceUnit) {
		case 'feet':
			result *= 1 / 0.3048
			break
		case 'kilometers':
			result *= 1 / 1000
			break
		case 'meters':
			break
		case 'miles':
			result *= 1 / 1609.34
			break
		default:
			throw new Error('invalid units: ' + units)
	}

	switch (timeUnit) {
		case 'second':
			break
		case 'minute':
			result *= 60
			break
		case 'hour':
			result *= 3600
			break
		default:
			throw new Error('invalid units: ' + units)
	}

	return result
}

export const formatAddress = (address) => {
	if (address) {
		if (address.formatted_address) {
			return address.formatted_address
		} else {
			return address
		}
	}
	return ''
}

/**
 * @method formatAccuracy
 * @param {number} distance in meters
 * @return {string} distance + ft
 */
export const formatDistance = (distance, isMetricUser) => {
	if (distance > 160) {
		if (isMetricUser) {
			let kilometers = convertDistance(distance, 'kilometers')
			return Math.round(kilometers * 10) / 10 + ' km'
		}
		let miles = convertDistance(distance, 'miles')
		return Math.round(miles * 10) / 10 + ' mi'
	}

	if (distance != null) {
		let feet = Math.round(convertDistance(distance, 'feet')) + ' ft'
		if (isMetricUser) {
			return convertFeetToMeters(feet)
		}
		return feet
	}

	return ''
}

export const formatDistanceNoUnit = (distance) => {
	if (distance > 160) {
		let miles = convertDistance(distance, 'miles')
		return Math.round(miles * 10) / 10
	}

	if (distance != null) {
		return 0
	}

	return 0
}

/**
 * @method formatElapsed
 * @param elapsed in milliseconds
 * @return {string} elapsed + hr and min
 */
export const formatElapsed = (elapsed) => {
	if (elapsed == null) return ''
	if (elapsed == 0) return '0 min'

	let hours = Math.trunc(elapsed / (1000 * 60 * 60))
	let minutes = Math.round((elapsed - hours * 1000 * 60 * 60) / (1000 * 60))
	let parts = []

	if (hours) parts.push(hours + ' hr')
	if (minutes) parts.push(minutes + ' min')
	return parts.join(' ')
}

/**
 * @method formatElapsed
 * @param elapsed in milliseconds
 * @return {string} elapsed + hr and min
 */
export const formatElapsedMinutes = (elapsed) => {
	if (elapsed == null) return ''
	if (elapsed == 0) return '0'

	// let hours = Math.trunc(elapsed / (1000 * 60 * 60));
	let minutes = Math.round(elapsed / 60000)
	// let parts = [];

	return minutes
	// if (hours) parts.push(hours + ' hr');
	// if (minutes) parts.push(minutes + ' min');
	// return parts.join(' ');
}

/**
 * @method formatElapsed
 * @param elapsed in seconds
 * @return {string} elapsed + hr and min
 */
export const formatTimeToTarget = (elapsed) => {
	if (elapsed == null) return '0 min'
	if (elapsed == undefined) return '0 min'
	if (elapsed == 0) return '0 min'
	if (elapsed >= 1 && elapsed < 60) return '1 min'
	let milliSeconds = elapsed * 1000

	let result = this.formatElapsed(milliSeconds)
	return result
}

/**
 * @method convertSpeed
 * @param {number} speed in meters/second
 * @return {string} speed + mph
 */
export const formatSpeed = (speed) => {
	if (speed == null) return ''
	let converted = convertSpeed(speed, 'miles/hour')
	return Math.round(converted) + ' mph'
}

export const formatSpeedToKilometersPerHour = (speed) => {
	if (speed == null) return ''
	let converted = convertSpeed(speed, 'kilometers/hour')
	return Math.round(converted) + ' km/h'
}

export function convertFeetToMeters(distanceInFeet) {
	const feet = +distanceInFeet.split(' ')[0]
	if (feet === 0) return '0 m'
	const meters = feet * 0.3048
	return `${meters.toFixed(2)} m`
}
