import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { EnvironmentProvider } from '../../../custom-components/context/EnvironmentContext'
import { IconThemeProvider } from '../../../custom-components/context/IconThemesContext'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import { useUrlQueryParams } from 'utils/hooks/useUrlQueryParams'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import { FORM_ACTIONS } from '../../../reducers/formsReducer'
import DashboardSplitPane from '../../core/Dashboard/DashboardSplitPane'
import { getAllProjects, getProjectForms } from '../../data/dataServices'
import FormsDashboard from './FormsDashboard'
import FormsData from './FormsData'

const useStyles = makeStyles(() => ({
	formSidePanel: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		height: '100%',
	},
	formBody: {
		display: 'flex',
		height: 'inherit',
	},
}))

const FormModule = () => {
	const { id: paramId } = useParams()

	const urlQueryParam = useUrlQueryParams()

	const actionQuery = urlQueryParam.get('action')

	const classes = useStyles()

	const { environment, formsModule } = useSelector((state) => state)

	const iconTheme = environment.theme.icons

	const [error, setError] = useState(null)

	const dispatch = useDispatch()
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		return () => (isMounted.current = false)
	}, [])

	useEffect(() => {
		var newFullScreenDataModString = localStorage.getItem('newFullScreenData_mod')
		if (!_.isEmpty(paramId) && newFullScreenDataModString != null) {
			var newFullScreenDataMod = JSON.parse(newFullScreenDataModString)
			var formsModuleTmp = newFullScreenDataMod.formsModule
			dispatch({
				type: FORM_ACTIONS.FORM_RESTORE_PRODUCER,
				payload: formsModuleTmp,
			})
			localStorage.removeItem('newFullScreenData_mod')
		} else {
			dispatch({
				type: FORM_ACTIONS.TAB_CHANGE,
				payload: FORM_ACTIONS.NAME,
			})

			if (!environment.isProjectFormsLoaded) {
				initiateLoadAllProjects()
			}
		}
	}, [])

	useEffect(() => {
		if (!environment.refresh) return
		dispatch({
			type: ENV_ACTIONS.CLEAR_FORMS,
		})
		initiateLoadAllProjects()
		dispatch({
			type: ENV_ACTIONS.REFRESH,
			payload: false,
		})
	}, [environment.refresh])

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setError(null)
	}

	const initiateLoadAllProjects = () => {
		dispatch({
			type: ENV_ACTIONS.IS_LOADING_PROJECTS,
			payload: true,
		})
		loadAllProjects()
			.then((res) => {
				const projects = _.sortBy(res.data, (project) => project.name.toLowerCase())
				initiateLoadFormsByProject(projects)
			})
			.catch((err) => {
				setError('Code ' + err.response?.data?.code + ': ' + err.response?.data?.message)
			})
			.finally(() => {
				dispatch({
					type: ENV_ACTIONS.IS_PROJECT_FORMS_LOADED,
					payload: true,
				})
			})
	}

	const loadAllProjects = async () => {
		let promise = await getAllProjects(environment.apiToken)
		return promise
	}

	const initiateLoadFormsByProject = (projects) => {
		let promises = []
		let newProjects = []
		for (let i = 0; i < projects.length; i++) {
			const promise = loadFormsByProject(projects[i])
				.then((res) => {
					if (res.name.toLowerCase() !== 'lookups') {
						newProjects[i] = { ...res }
					}
					dispatch({
						type: ENV_ACTIONS.GET_PROJECTS,
						payload: newProjects.filter((val) => val),
					})
				})
				.catch((err) => {
					setError('Code ' + err.response?.data?.code + ': ' + err.response?.data?.message)
				})
			promises.push(promise)
		}

		Promise.all(promises).then(() => {
			dispatch({
				type: ENV_ACTIONS.IS_LOADING_PROJECTS,
				payload: false,
			})
		})
	}

	const loadFormsByProject = (project) => {
		return getProjectForms(project.key, environment.apiToken).then((resp) => {
			let promiseObject = { key: project.key, name: project.name, forms: [] }
			if (resp?.data && _.isArray(resp.data) && resp.data.length) {
				promiseObject.forms = _.sortBy(resp.data, (project) => project.name.toLowerCase())
				dispatch({
					type: ENV_ACTIONS.GET_FORMS,
					payload: promiseObject.forms,
				})
			}
			return promiseObject
		})
	}

	const sidePanel = () => (
		<div id="form-side-panel" className={classes.formSidePanel}>
			<EnvironmentProvider values={environment}>
				<FormsDashboard
					projects={environment.projects}
					loading={environment.isLoadingProjects}
					module={formsModule}
					env={environment}
				/>
			</EnvironmentProvider>
		</div>
	)

	const formBody = () => (
		<div id="form-body" className={classes.formBody}>
			{showErrorMessage()}
			<FormsData environment={environment} module={formsModule} />
		</div>
	)

	return (
		<IconThemeProvider values={iconTheme}>
			<DashboardSplitPane sidePanel={sidePanel()} mainContent={formBody()} />
		</IconThemeProvider>
	)
}

export default FormModule
