import { Box, Skeleton } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useQuery } from '@tanstack/react-query'
import tileApi from 'apis/disApi/tileApi'
import _, { isEmpty, uniqBy } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { logErrorMessage } from 'utils/functions/helpers'
import useForceRerender from 'utils/hooks/useForceRerender'
import { parsedDataGridRecords } from '../../../../data/dataHelpers'
import {
	cancelLoadNextRecordsAction,
	getViewTabViewRecords,
	loadNextRecordsQuery,
	loadRecordsQuery,
} from '../../../../data/dataServices'
import DoformsDataChart from '../../../../data/datagrid/DoformsDataChart'
import useFields from '../../hooks/useFields'
import useView from '../../hooks/useView'
import DashboardList from '../DashboardKeyList'

const ChartForm = ({
	dashboardKey,
	tileKey, // Optional
	linkedFields,
	viewKeySetting,
	filterData,
	onFilterDataChange,
	enableLoadView,
	dashboardKeyList,
	outlinedInput,
	setIsChartLoading, // Optional
}) => {
	const { environment } = useSelector((state) => state)

	const { t } = useTranslation('common')
	const { viewLoading, views } = useView({ config: { enabled: enableLoadView } })
	const [forceId, setForceRerender] = useForceRerender()

	const [queryView, setQueryView] = useState(null)
	const [currentRecords, setCurrentRecords] = useState(null)

	const isFetchingNextRecords = useRef(false)

	const hasMoreRecords = queryView?.more ?? false

	const { viewKey } = filterData

	const { fields } = useFields({
		viewKey,
	})

	const { data, isLoading, isFetching } = useQuery(
		['map-tile', viewKey, tileKey],
		async () => {
			const [tileResponse, viewResponse] = await Promise.all([
				tileApi.get(dashboardKey, tileKey, environment.apiToken),
				getViewTabViewRecords({
					viewKey: filterData?.viewKey,
					viewSession: false,
					token: environment.apiToken,
				}),
			])

			let chart

			// If has chart in tile, use tile chart
			if (tileResponse?.data?.chart) {
				chart = tileResponse?.data?.chart
			} else if (viewResponse?.data?.chart) {
				chart = viewResponse?.data?.chart
			}


			return {
				viewData: { ...viewResponse?.data, chart } ?? {},
				columns: viewResponse?.data?.columns ?? [],
			}
		},
		{
			enabled: Boolean(viewKey),
			staleTime: Infinity,
		}
	)

	useEffect(() => {
		if (isLoading || isFetching || isEmpty(data) || isEmpty(data?.viewData)) return
		(async () => {
			setIsChartLoading?.(true)
			const conditions = data?.viewData.queries[0]?.filter?.conditions ?? []
			await getRecords({
				clientFilter: { conditions },
				viewKey: filterData?.viewKey,
			})
			setIsChartLoading?.(false)
		})()

	}, [data, isLoading, isFetching])

	const gridRows = useMemo(() => {
		if (!currentRecords?.length || !data?.columns?.length) return []

		return parsedDataGridRecords(currentRecords, data?.columns, environment, [])
	}, [currentRecords, JSON.stringify(data?.columns)])

	const addChartLoading = isLoading && isFetching

	useEffect(() => {
		if (viewKeySetting) {
			onFilterDataChange('viewKey', viewKeySetting)
		}
	}, [viewKeySetting])

	useEffect(() => {
		if (_.isEmpty(linkedFields)) return
		onFilterDataChange('linked', linkedFields)
	}, [linkedFields])

	useEffect(() => {
		try {
			if (!queryView?.more || !currentRecords?.length || enableLoadView) return

			const viewSession = false

			async function getNextRecords({ queryView }) {
				isFetchingNextRecords.current = true

				const nextRecordsResponse = await loadNextRecordsQuery(
					queryView,
					viewSession,
					environment.apiToken
				)

				isFetchingNextRecords.current = false

				const nextQueryView = nextRecordsResponse.data.view

				setQueryView(nextQueryView)

				setCurrentRecords((prev) => {
					const nextRecords = uniqBy(
						[...prev, ...nextRecordsResponse.data.records],
						'submissionKey'
					)

					return nextRecords.filter((item) => Boolean(item))
				})
			}

			if (!isFetchingNextRecords.current) {
				getNextRecords({ queryView })
			}
		} catch (error) {
			logErrorMessage(error)
		}
	}, [currentRecords, environment.apiToken, queryView, enableLoadView])

	useEffect(() => {
		if (isEmpty(data?.columns)) return

		onFilterDataChange('columns', data?.columns)
	}, [JSON.stringify(data?.columns)])

	useEffect(() => {
		if (isEmpty(gridRows)) return

		onFilterDataChange('gridRows', gridRows)
	}, [JSON.stringify(gridRows)])

	useEffect(() => {
		if (isEmpty(data?.viewData)) return

		onFilterDataChange('viewData', data?.viewData)
	}, [JSON.stringify(data?.viewData)])

	async function getRecords({ clientFilter, viewKey }) {
		try {
			const viewSession = false

			const recordsResponse = await loadRecordsQuery(
				viewKey,
				viewSession,
				clientFilter,
				environment.apiToken
			)
			let queries = [...recordsResponse.data.view.queries]
			queries[0].filter = { ...clientFilter }

			const queryView = { ...recordsResponse.data.view, queries: queries }
			const records = recordsResponse.data.records

			setQueryView(queryView)
			setCurrentRecords(records.filter((item) => Boolean(item)))

			return records
		} catch (error) {
			logErrorMessage(error)
			throw error
		}
	}

	async function onCancelLoadingRecords() {
		try {
			cancelLoadNextRecordsAction()
			setQueryView((prev) => ({ ...prev, more: false }))
			isFetchingNextRecords.current = false
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const handleSelectViewKey = (newViewKey) => {
		const selectedView = views.find((view) => view.key === newViewKey)

		onFilterDataChange('viewKey', newViewKey)

		if (selectedView) {
			onFilterDataChange('formKey', selectedView?.formKey)
			onFilterDataChange('projectKey', selectedView?.projectKey)
		}
	}

	const handleLinkedChange = (id, value) => {
		if (value === '') {
			// Remove the field from linked
			const newLinkedFields = { ...filterData?.linked }
			delete newLinkedFields[id]
			onFilterDataChange('linked', newLinkedFields)
		} else {
			const newLinked = { ...filterData?.linked, [id]: value }
			onFilterDataChange('linked', newLinked)
		}
	}

	return (
		<>
			{!viewLoading ? (
				<FormControl
					variant={outlinedInput ? 'outlined' : 'standard'}
					sx={{ m: outlinedInput ? 0 : 1, minWidth: outlinedInput ? '50%' : 120 }}
					size="small"
				>
					<InputLabel id="view-select-small-label">{t('common:input.view')}</InputLabel>
					<Select
						labelId="view-select-small-label"
						id="view-select-small"
						value={viewKey ?? ''}
						label={t('common:input.view')}
						onChange={(e) => handleSelectViewKey(e.target.value)}
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{views?.length > 0 &&
							views
								.sort((a, b) => a.name.localeCompare(b.name))
								.map((viewItem) => (
									<MenuItem value={viewItem.key} key={viewItem.key}>
										{viewItem.name}
									</MenuItem>
								))}
					</Select>
				</FormControl>
			) : (
				<Skeleton
					variant="rectangular"
					height={45}
					sx={{ mt: outlinedInput ? 0 : 2, width: outlinedInput ? '40%' : 120 }}
				/>
			)}

			{viewKey && !_.isEmpty(data) && (
				<Box sx={{ py: 2 }}>
					<DoformsDataChart
						hiddenDisplayChartBtn
						isAddChart={data?.viewData?.chart?.display ? false : true}
						showOnlyChart={false}
						columns={data?.columns ?? []}
						gridRows={gridRows ?? []}
						viewData={data?.viewData ?? {}}
						recordsLoading={false}
						environment={environment}
						onForceUpdate={setForceRerender}
					/>
				</Box>
			)}

			{addChartLoading && <Skeleton height={40} width={105} sx={{ my: 2 }} />}

			<Box>
				<DashboardList
					title={t('common:dis.dashboardKeys')}
					list={dashboardKeyList}
					fields={fields}
					onFieldsChange={handleLinkedChange}
					linkedFields={filterData?.linked || {}}
					getLabelOption={(item) => item.name}
				/>
			</Box>
		</>
	)
}

export default ChartForm
