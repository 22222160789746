import PeopleIcon from '@mui/icons-material/People'
import PersonIcon from '@mui/icons-material/Person'
import SaveIcon from '@mui/icons-material/Save'
import { Alert, LoadingButton } from '@mui/lab'
import {
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import React, { useContext, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { ACTIVITY_ACTIONS } from '../../../reducers/activityReducer'
import { DEVICES_ACTIONS } from '../../../reducers/devicesReducer'
import { FORM_ACTIONS } from '../../../reducers/formsReducer'
import { LOOKUPS_ACTIONS } from '../../../reducers/lookupsReducer'
import { VIEWS_ACTIONS } from '../../../reducers/viewsReducer'
import { computeOwnerNameByType, revertDataGridColumns } from '../dataHelpers'
import { createView, updateView } from '../dataServices'
import { isEmpty } from 'lodash'
import { VIEW } from '../../../constants'
import { SaveTheViewDialog } from './CreatViewComponents/dialogs/SaveTheViewDialog'
import { toast } from 'react-toastify'
import { sleep } from 'utils/functions/helpers'

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiButton-root': {
			textTransform: 'none',
		},
	},

	buttonGroup: {
		'& .MuiToggleButtonGroup-grouped': {
			border: 0,
			textTransform: 'none',
			'&.Mui-disabled': {
				border: 0,
			},
		},
	},
	listBody: {
		maxHeight: '500px',
		overflowY: 'auto',
	},
	footer: {
		minHeight: '52px',
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const boxStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	backgroundColor: 'background.paper',
	boxShadow: 24,
	borderRadius: '5px',
	padding: '16px 32px',
	textAlign: 'center',
}

const DoformsDataSaveView = (props) => {
	const [t] = useTranslation('common')
	const {
		clientFilter,
		hasManageRights,
		environment,
		owners,
		gridColumns,
		viewData,
		queryView,
		recordsLoading,
		tab,
		aggregationModel,
		rowGroupingModel,
	} = props
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const [open, setOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	const [option, setOption] = useState(null)

	const [saveChoice, setSaveChoice] = useState(null)
	const [saveAsChoice, setSaveAsChoice] = useState(null)
	const [saveAsInput, setSaveAsInput] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	const [listActiveFields, setListActiveFields] = useState([])

	// selected project form
	const [projectFormInfo, setProjectFormInfo] = useState({})
	// const [isOpenDialog, setIsOpenDialog] = useState(false)

	const dispatch = useDispatch()

	const saveOptions = useMemo(() => {
		const options =
			hasManageRights && tab !== 'views'
				? [
						{ key: 'forMyself', name: t('common:formsData.forMyself') },
						{ key: 'forEveryone', name: t('common:formsData.forEveryone') },
				  ]
				: [{ key: 'forMyself', name: t('common:formsData.forMyself') }]
		return options
	}, [hasManageRights, tab])

	useEffect(() => {
		// add new mode
		//const defaultSaveType = getDefaultSaveType(tab)
		//setSaveType(defaultSaveType)
		if (isEmpty(viewData)) return

		// edit mode
		const projectKey = viewData.projectKey
		const formKey = viewData.formKey
		const project = environment.projects.find((item) => item.key === projectKey)
		const projectName = project?.name || ''
		const form = environment.forms.find((item) => item.key === formKey)
		const formName = form?.name || ''

		setProjectFormInfo({
			projectKey,
			projectName,
			formKey,
			formName,
		})
	}, [viewData, environment])

	const handleOptionChange = (e, newOption) => {
		setOption(newOption)
	}

	const handleClick = (event) => {
		event.preventDefault()
		setErrorMessage('')
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	function getViewColumnsWithFilter({ viewColumns, aggregationModel, rowGroupingModel }) {
		if (isEmpty(viewColumns)) return []

		if (isEmpty(aggregationModel) && isEmpty(rowGroupingModel)) {
			return viewColumns
		}

		return viewColumns.map((column) => {
			if (aggregationModel[column.name] || rowGroupingModel.includes(column.name)) {
				return {
					...column,
					aggregate: aggregationModel[column.name],
					group: rowGroupingModel.find((group) => group === column.name),
				}
			}
			return column
		})
	}

	const handleSaveApply = (event, saveViewSettings) => {
		setErrorMessage('')
		const isFormsTab = tab === VIEW.VIEW_TAB_FORMS || tab === VIEW.VIEW_TAB_LOOKUPS
		if (isFormsTab && !saveViewSettings) {
			//validate saveViewSettings
			toast.error(`${t('common:view.saveTheView')}: ${t('common:dis.pleaseFillInformation')}`)
			return
		}
		event.preventDefault()
		let viewColumns = revertDataGridColumns(gridColumns)

		const viewColumnsWithFilter = getViewColumnsWithFilter({
			viewColumns,
			aggregationModel,
			rowGroupingModel,
		})

		const saveType = isFormsTab ? saveViewSettings.saveType : saveChoice
		let tempViewData = {
			...viewData,
			begin: queryView.begin,
			columns: viewColumnsWithFilter,
			queries: [...queryView.queries],
			count: queryView.count,
			millis: queryView.millis,
			more: queryView.more,
			sorts: [],
			clientFilter: JSON.stringify(clientFilter),
		}

		if (saveType === 'everyOne') {
			tempViewData = {
				...tempViewData,
				everyone: true,
			}
		}

		setLoading(true)
		updateView(tempViewData, environment.apiToken)
			.then(() => {
				if (tab === 'lookups') {
					dispatch({
						type: LOOKUPS_ACTIONS.VIEW_UPDATE,
						payload: tempViewData,
					})
				} else if (tab === 'views') {
					dispatch({
						type: VIEWS_ACTIONS.VIEW_UPDATE,
						payload: tempViewData,
					})
				} else if (tab === 'devices') {
					dispatch({
						type: DEVICES_ACTIONS.VIEW_UPDATE,
						payload: tempViewData,
					})
				} else if (tab === 'activity') {
					dispatch({
						type: ACTIVITY_ACTIONS.VIEW_UPDATE,
						payload: tempViewData,
					})
				} else {
					dispatch({
						type: FORM_ACTIONS.VIEW_UPDATE,
						payload: tempViewData,
					})

					// sleep(3000).then(() => {
					// 	dispatch({
					// 		type: FORM_ACTIONS.FORM_REFRESH,
					// 		payload: true,
					// 	})
					// })
				}
			})
			.catch((err) => {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
				setErrorMessage('Code ' + err.response.data.code + ': ' + err.response.data.message)
			})
			.finally(() => {
				setLoading(false)
				if (tab === VIEW.VIEW_TAB_FORMS || tab === VIEW.VIEW_TAB_LOOKUPS) {
					handleClose()
				}
			})
	}

	const handleSaveAsApply = (event, saveViewSettings) => {
		setErrorMessage('')

		const isFormsTab = tab === VIEW.VIEW_TAB_FORMS || tab === VIEW.VIEW_TAB_LOOKUPS
		if (isFormsTab && !saveViewSettings) {
			//validate saveViewSettings
			toast.error(`${t('common:view.saveTheView')}: ${t('common:dis.pleaseFillInformation')}`)
			return
		}
		const saveAsOwnerKey = isFormsTab ? saveViewSettings.ownerKey : saveAsChoice
		const viewName = isFormsTab ? saveViewSettings.viewName : saveAsInput
		event.preventDefault()
		const owner = owners.find((owner) => owner.key === saveAsOwnerKey)
		let viewColumns = revertDataGridColumns(gridColumns)

		const viewColumnsWithFilter = getViewColumnsWithFilter({
			viewColumns,
			aggregationModel,
			rowGroupingModel,
		})

		let queries = queryView.queries.map((item, index) => ({
			fields: [...viewColumnsWithFilter],
			kind: viewData.queries.find((query) => query.type === item.type).kind,
			...item,
		}))

		let tempViewData = {
			...viewData,
			columns: [...viewColumnsWithFilter],
			queries: queries,
			more: queryView.more,
			sorts: [],
			ownerKey: owner.key,
			ownerType: owner.type,
			name: viewName,
			clientFilter: JSON.stringify(clientFilter),
		}
		delete tempViewData.more
		delete tempViewData.count

		setLoading(true)
		createView(tempViewData, environment.apiToken)
			.then((response) => {
				if (tab === 'lookups') {
					dispatch({
						type: LOOKUPS_ACTIONS.VIEW_CREATE,
						payload: { ...tempViewData, key: response?.data?.key },
					})
				} else if (tab === 'views') {
					dispatch({
						type: VIEWS_ACTIONS.VIEW_CREATE,
						payload: { ...tempViewData, key: response?.data?.key },
					})
				} else if (tab === 'devices') {
					dispatch({
						type: DEVICES_ACTIONS.VIEW_CREATE,
						payload: { ...tempViewData, key: response?.data?.key },
					})
				} else if (tab === 'activity') {
					dispatch({
						type: ACTIVITY_ACTIONS.VIEW_CREATE,
						payload: { ...tempViewData, key: response?.data?.key },
					})
				} else {
					dispatch({
						type: FORM_ACTIONS.VIEW_CREATE,
						payload: { ...tempViewData, key: response?.data?.key },
					})
				}
			})
			.catch((err) => {
				setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
				setErrorMessage('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
			})
			.finally(() => {
				setLoading(false)
				if (tab === VIEW.VIEW_TAB_FORMS || tab === VIEW.VIEW_TAB_LOOKUPS) {
					handleClose()
				}
			})
	}

	const renderSaveList = () => (
		<>
			{saveOptions.map((option) => (
				<ListItemButton
					key={option.key}
					sx={{ height: '3.5em' }}
					selected={saveChoice ? saveChoice === option.key : false}
					onClick={() => setSaveChoice(option.key)}
				>
					<ListItemIcon>
						{option.key === 'forMyself' ? <PersonIcon /> : <PeopleIcon />}
					</ListItemIcon>
					<ListItemText primary={option.name} />
				</ListItemButton>
			))}
		</>
	)

	const renderSaveAsList = () => (
		<>
			{owners.map((option) => (
				<ListItemButton
					key={option.key}
					sx={{ height: '3.5em' }}
					selected={saveAsChoice ? saveAsChoice === option.key : false}
					onClick={() => setSaveAsChoice(option.key)}
				>
					<ListItemText
						primary={option.name ? option.name : computeOwnerNameByType(option.type, t)}
					/>
				</ListItemButton>
			))}
		</>
	)

	const renderColumnLists = (tab) => {
		if (tab === VIEW.VIEW_TAB_FORMS || tab === VIEW.VIEW_TAB_LOOKUPS) {
			return (
				open && (
					<SaveTheViewDialog
						tab={tab}
						owners={owners}
						hasManageRights={hasManageRights}
						projectFormInfo={projectFormInfo}
						isOpenDialog={open}
						isAddNew={false}
						setIsOpenDialog={setOpen}
						viewData={viewData}
						environment={environment}
						handleSaveApply={handleSaveApply}
						handleSaveAsApply={handleSaveAsApply}
						listActiveFields={gridColumns}
						errorMessage={errorMessage}
					/>
				)
			)
		}
		return (
			<Modal
				target={'form-save-view'}
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				onClose={handleClose}
			>
				<Fade in={open}>
					<Box sx={boxStyle}>
						<Typography id="transition-modal-title" variant="h6" component="h2">
							{t('common:formsData.pleaseSelectSaveOption')}
						</Typography>
						<ToggleButtonGroup
							className={classes.buttonGroup}
							sx={{ pt: 1 }}
							value={option}
							exclusive
							onChange={handleOptionChange}
							fullWidth
						>
							<ToggleButton
								value="save"
								className={classes.icon}
								onClick={() => setOption('save')}
							>{`${t('formsData.save')}`}</ToggleButton>
							<ToggleButton
								value="saveAs"
								className={classes.icon}
								onClick={() => setOption('saveAs')}
							>{`${t('formsData.saveAsView')}`}</ToggleButton>
						</ToggleButtonGroup>
						<List className={classes.listBody}>
							{option ? (
								<>
									{option === 'save' && renderSaveList()}
									{option === 'saveAs' && renderSaveAsList()}
								</>
							) : null}
						</List>
						{error && (
							<Alert icon={false} severity="error">
								This is an error alert — check it out!
							</Alert>
						)}
						<div className={classes.footer}>
							{option === 'saveAs' && (
								<TextField
									disabled={!saveAsChoice}
									id="save-as-input"
									label={t('common:formsData.pleaseEnterANameForThisView')}
									sx={{ mt: 2 }}
									size="small"
									value={saveAsInput}
									onChange={(e) => setSaveAsInput(e.target.value)}
									fullWidth
								/>
							)}
							<Stack direction="row" alignItems="center" justifyContent="flex-end" gap={2} mt={2}>
								<LoadingButton
									className={classes.icon}
									loading={loading}
									onClick={option === 'save' ? handleSaveApply : handleSaveAsApply}
									disabled={option === 'save' ? !saveChoice : !saveAsInput}
								>
									{t('common:misc.ok')}
								</LoadingButton>
								<Button onClick={handleClose} className={classes.icon}>
									{t('common:misc.cancel')}
								</Button>
							</Stack>
						</div>
					</Box>
				</Fade>
			</Modal>
		)
	}

	return (
		<div>
			<LoadingButton
				className={classes.icon}
				disabled={recordsLoading}
				loadingPosition="start"
				onClick={handleClick}
				startIcon={<SaveIcon />}
			>
				{t('common:misc.save')}
			</LoadingButton>
			{renderColumnLists(tab)}
		</div>
	)
}

export default DoformsDataSaveView
