import { makeStyles } from '@mui/styles'
import axios from 'axios'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import {
	callRecordsStorage,
	checkLoadColumnStorage,
	checkNeedCallQueryAPI,
	getLinkPrefix,
} from '../../../utils/functions/helpers'
import { useFirstRender } from 'utils/hooks/useFirstRender'
import useGetPathName from 'utils/hooks/useGetPathName'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import SkeletonLoaderDataGrid from '../../../custom-components/skeletons/SkeletonLoaderDataGrid'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import { FORM_ACTIONS } from '../../../reducers/formsReducer'
import DoformsWizardDataGrid from '../../data/datagrid/DoformsWizardDataGrid'
import { getSelectedProject } from '../../data/dataHelpers'
import {
	cancelLoadNextRecordsAction,
	getViewRecords,
	loadRecordsQuery,
} from '../../data/dataServices'
import DoformsSendDispatch from '../../data/dispatch/DoformsSendDispatch'
import DoformsMap from '../../data/map/DoformsMap'
import DoformsCreateSubmission from '../../data/submissions/DoformsCreateSubmission'
import useGetCommonFunc from 'utils/hooks/useGetCommonFunc'

const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		padding: '8px',
		'& .MuiButton-root': {
			textDecoration: 'none',
		},
	},
	dataGridToolbar: {
		minHeight: 20,
		paddingLeft: '0px',
		paddingBottom: '8px',
	},
	dialog: {
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	dialogTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
	},
	loading: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		position: 'absolute',
		top: '50%',
		left: '50%',
	},
	datagrid: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: '30px',
		padding: '8px',
	},
}))

const FormsData = ({ environment, module }) => {
	const [t] = useTranslation('common')
	const classes = useStyles()
	const isFirstRender = useFirstRender()

	const apiToken = environment.apiToken
	const {
		action,
		formColumns,
		dataGrid: { columns, query, queryView, records, title, viewSession },
		formDeleteSelection,
		formFilterConditions,
		formRefresh,
		formSelected,
		viewCreate,
		viewData,
		viewUpdate,
		recordsChanged,
		rowsPerPage,
		clientFilters,
	} = module
	const firstPathName = useGetPathName()
	const dispatch = useDispatch()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [info, setInfo] = useState(null)
	const [linkViewInfo, setLinkViewInfo] = useState(null)

	const [allRecordsLoading, setAllRecordsLoading] = useState(false)
	const [startLoadAllRecords, setStartLoadAllRecords] = useState(false)
	const [recordLoading, setRecordLoading] = useState(false)
	const [isCancelLoadingRecords, setIsCancelLoadingRecords] = useState(false)

	const isMounted = useRef(true)

	const { getAllRecords, loadUpdateColumns } = useGetCommonFunc({
		tab: 'forms',
		firstPathName,
		viewData,
		query,
		queryView,
		viewSession,
		apiToken,
		records,
		formColumns,
		setAllRecordsLoading,
		setStartLoadAllRecords,
		setLoading,
		setError,
	})

	useEffect(() => {
		// Checked when change column then switch tab will call back api to get correct data
		;(async () => {
			if (
				!_.isEmpty(formColumns) &&
				viewData.key &&
				isFirstRender &&
				checkLoadColumnStorage.get(firstPathName)
			) {
				await loadUpdateColumns()
				checkLoadColumnStorage.set(firstPathName, false)
				setRecordLoading(true)
			}
		})()
	}, [])

	useEffect(() => {
		isMounted.current = true
		checkLoadColumnStorage.set(firstPathName, false)
		return () => (isMounted.current = false)
	}, [])

	useEffect(() => {
		// run this useEffect when send new dispatch
		if (recordsChanged) {
			setRecordLoading(true)
			dispatch({
				type: FORM_ACTIONS.RECORDS_CHANGED,
				payload: false,
			})
		}
	}, [recordsChanged])

	useEffect(() => {
		if (_.isEmpty(formSelected)) return
		var selectedPrj = getSelectedProject(environment, formSelected, 'forms')
		const formTitle = (selectedPrj ? selectedPrj.name + ` / ` : '') + formSelected.name
		dispatch({
			type: FORM_ACTIONS.DATA_GRID,
			payload: {
				title: formTitle,
			},
		})
	}, [formSelected])

	useEffect(() => {
		if (recordLoading) {
			loadRecords()
			setRecordLoading(false)
		}
	}, [recordLoading])

	useEffect(() => {
		if (!action) return
		if (!_.isEmpty(queryView)) return
		dispatch({
			type: FORM_ACTIONS.VIEW_DATA,
			payload: {},
		})
		dispatch({
			type: FORM_ACTIONS.DATA_GRID,
			payload: {
				columns: [],
			},
		})
		const cancelToken = axios.CancelToken.source()

		const getViewData = () => {
			if (!formSelected.key || !formSelected.projectKey) return
			setLoading(true)
			getViewRecords(formSelected.key, formSelected.projectKey, viewSession, apiToken, cancelToken)
				.then((response) => {
					let responseData = response.data

					const clientFilter = responseData?.clientFilter
						? JSON.parse(responseData?.clientFilter)
						: undefined
					if (clientFilter) {
						const clientFilterKey = formSelected?.key ?? ''
						dispatch({
							type: FORM_ACTIONS.CLIENT_FILTERS,
							payload: {
								...clientFilters,
								[clientFilterKey]: clientFilter,
							},
						})
					}
					// responseData = checkForStartDateAndStatus(responseData);
					if (isMounted.current) {
						dispatch({
							type: FORM_ACTIONS.VIEW_DATA,
							payload: responseData,
						})
						dispatch({
							type: FORM_ACTIONS.DATA_GRID,
							payload: {
								columns: responseData.columns,
								query: responseData.queries[0],
							},
						})
						setRecordLoading(true)
					}
				})
				.catch((err) => {
					if (axios.isCancel(err)) {
						console.error('Request canceled', err.message)
					} else {
						setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
					}
				})
		}

		getViewData()

		return () => {
			cancelToken.cancel()
		}
	}, [action, formSelected])

	useEffect(() => {
		if (_.isEmpty(formFilterConditions)) return
		if (query.filter?.conditions && _.isEqual(formFilterConditions, query.filter.conditions)) return
		dispatch({
			type: FORM_ACTIONS.DATA_GRID,
			payload: {
				columns: viewData != null ? viewData.columns : columns,
				query: {
					...query,
					filter: {
						conditions: formFilterConditions,
					},
				},
			},
		})
		setRecordLoading(true)
	}, [formFilterConditions])

	useEffect(() => {
		;(async () => {
			if (!formRefresh) return
			try {
				setIsCancelLoadingRecords(false)
				if (
					!_.isEmpty(formColumns) &&
					!isFirstRender &&
					checkLoadColumnStorage.get(firstPathName)
				) {
					await loadUpdateColumns()
					checkLoadColumnStorage.set(firstPathName, false)
				}
				loadRecords()
			} catch (err) {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
			}
		})()
	}, [formRefresh])

	useEffect(() => {
		if (startLoadAllRecords) {
			setStartLoadAllRecords(false)
			try {
				getAllRecords()
			} catch (err) {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
			}
		}
	})

	useEffect(() => {
		if (isFirstRender) return
		checkLoadColumnStorage.set(firstPathName, true)
	}, [formColumns])

	useEffect(() => {
		;(async () => {
			try {
				if (_.isEmpty(formColumns) && isFirstRender) return
				//if (environment.formColumnsChanged) return;
				setLoading(true)
				if (checkNeedCallQueryAPI(formColumns.map((item) => item.name)) || isCancelLoadingRecords) {
					if (!_.isEmpty(formColumns)) {
						await loadUpdateColumns()
					}
					setRecordLoading(true)
					setIsCancelLoadingRecords(false)
					checkLoadColumnStorage.set(firstPathName, false)
				} else {
					dispatch({
						type: FORM_ACTIONS.DATA_GRID,
						payload: {
							columns: formColumns,
							viewSession: true,
						},
					})
					setLoading(false)
				}
			} catch (err) {
				setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
				setLoading(false)
			}
		})()
	}, [formColumns])

	useEffect(() => {
		if (_.isEmpty(viewUpdate)) return
		setInfo(t('formsData.successfullySavedChanges'))
		dispatch({
			type: FORM_ACTIONS.DATA_GRID,
			payload: {
				columns: viewUpdate.columns,
				query: viewUpdate.queries[0],
			},
		})
		dispatch({
			type: FORM_ACTIONS.VIEW_UPDATE,
			payload: {},
		})
	}, [viewUpdate])

	useEffect(() => {
		if (_.isEmpty(viewCreate)) return
		setInfo(t('common:misc.successfullyCreatedView') + ' ')
		setLinkViewInfo(viewCreate)
		dispatch({
			type: FORM_ACTIONS.VIEW_CREATE,
			payload: {},
		})
		dispatch({
			type: ENV_ACTIONS.HAS_NEW_VIEW,
			payload: true,
		})
	}, [viewCreate])

	useEffect(() => {
		if (formDeleteSelection.length === 0) return
		setInfo(null)
		const selectedRecords = formDeleteSelection
		dispatch({
			type: FORM_ACTIONS.DATA_GRID,
			payload: {
				records: records.filter((rec) =>
					rec.type === 'DISPATCH'
						? !selectedRecords.includes(rec.dispatchKey)
						: !selectedRecords.includes(rec.submissionKey)
				),
			},
		})
		const infoMessage =
			selectedRecords.length > 1
				? t('common:misc.successfullyDeleted') +
				  ` ${selectedRecords.length} ` +
				  t('common:misc.records') +
				  `.`
				: t('common:misc.successfullyDeleted1Record')
		setInfo(infoMessage)
		dispatch({
			type: FORM_ACTIONS.FORM_DELETE_SELECTION,
			payload: [],
		})
	}, [formDeleteSelection])

	const handleReLoadFirstPage = (viewData) => {
		setLoading(true)
		loadRecordsQuery(viewData.key, viewSession, query.filter, apiToken)
			.then((response) => {
				let newDatas = [].concat(response.data.records)
				records.forEach((item) => {
					let existedRecords = response.data.records.filter(
						(filterItem) => item.submissionKey === filterItem.submissionKey
					)
					if (existedRecords.length === 0) {
						newDatas.push(item)
					}
				})

				dispatch({
					type: FORM_ACTIONS.DATA_GRID,
					payload: {
						queryView: response.data.view,
						records: newDatas,
					},
				})
				setLoading(false)
				callRecordsStorage.remove(firstPathName)
				return newDatas
			})
			.catch((err) => {
				setLoading(false)
				setError(
					err.response
						? 'Code ' + err.response.data.code + ': ' + err.response.data.message
						: err.message
				)
				return err
			})
	}

	const handlePresetReloadAll = () => {
		let queries = [].concat(queryView.queries)
		queries[0] = { ...queries[0], cursor: null, more: true }
		dispatch({
			type: FORM_ACTIONS.DATA_GRID,
			payload: {
				queryView: { ...queryView, more: true, queries: queries },
			},
		})
	}

	const loadRecords = () => {
		let viewDataKey = null
		if (viewData) {
			viewDataKey = viewData.key
		}
		if (!viewDataKey) return

		setLoading(true)

		loadRecordsQuery(viewDataKey, viewSession, query.filter, apiToken)
			.then((response) => {
				let queries = [...response.data.view.queries]
				queries[0].filter = { ...query.filter }
				dispatch({
					type: FORM_ACTIONS.DATA_GRID,
					payload: {
						queryView: { ...response.data.view, queries: queries },
						records: response.data.records,
					},
				})
			})
			.catch((err) => {
				setError(
					err.response
						? 'Code ' + err.response.data.code + ': ' + err.response.data.message
						: err.message
				)
			})
			.finally(() => {
				setLoading(false)
				dispatch({
					type: FORM_ACTIONS.FORM_REFRESH,
					payload: false,
				})
			})
	}

	const showLoading = () =>
		loading && (
			<>
				<LoadingSpinner />
				{_.isEmpty(viewData) && <SkeletonLoaderDataGrid />}
			</>
		)

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const showInfoMessage = () =>
		info && (
			<DoformsMessage
				linkViewInfo={linkViewInfo}
				message={info}
				severity={'success'}
				onMessageClosed={handleMessageClosed}
			/>
		)

	const handleMessageClosed = () => {
		setError(null)
		setInfo(null)
	}

	const handleResizeColumn = (selectedField, newWidth) => {
		const newColumns = columns.map((col) => {
			if (col.name === selectedField) {
				col.width = newWidth
			}
			return col
		})
		dispatch({
			type: FORM_ACTIONS.DATA_GRID,
			payload: {
				columns: newColumns,
			},
		})
	}

	const handleLoadAllRecords = () => {
		setStartLoadAllRecords(true)
	}

	const handleCancelLoadingRecords = () => {
		setIsCancelLoadingRecords(true)
		setStartLoadAllRecords(false)
		cancelLoadNextRecordsAction()
	}

	const showMapAction = () =>
		action === 'map' && (
			<DoformsMap
				action={'map'}
				tab={'forms'}
				title={title}
				environment={environment}
				viewData={viewData}
				formSelected={formSelected}
				module={module}
				onHandleShowLoading={setLoading}
				onHandleOpenFullScreenFormsTab={onHandleOpenFullScreenTab}
				onHandleCloseMap={onHandleCloseMap}
			/>
		)

	const showAuditAction = () =>
		action === 'audit' && (
			<DoformsWizardDataGrid
				action={action}
				isLoading={loading}
				isRefresh={formRefresh}
				title={title}
				environment={environment}
				query={query}
				viewData={viewData}
				formSelected={formSelected}
				columns={columns}
				records={records}
				queryView={queryView}
				clientFilters={clientFilters}
				recordsLoading={false}
				allRecordsLoading={allRecordsLoading}
				tab={'forms'}
				rowsPerPage={rowsPerPage}
				setError={setError}
				onHandledResizeColumn={handleResizeColumn}
				onHandledLoadAllRecords={handleLoadAllRecords}
				onHandledCancelLoadingRecords={handleCancelLoadingRecords}
				onHandleReLoadFirstPage={handleReLoadFirstPage}
				onHandlePresetReloadAll={handlePresetReloadAll}
				onHandleOpenFullScreenFormsTab={onHandleOpenFullScreenTab}
				setLoading={setLoading}
			/>
		)

	const onHandleOpenFullScreenTab = () => {
		localStorage.setItem('newFullScreenData_env', JSON.stringify({ environment: environment }))
		localStorage.setItem('newFullScreenData_mod', JSON.stringify({ formsModule: module }))
		//window.open('/forms/' + formSelected.key + '?action=' + action, '_blank_' + Math.random())
		window.open(getLinkPrefix('/forms'), '_blank_' + Math.random())
	}

	const onHandleCloseMap = () => {
		dispatch({
			type: FORM_ACTIONS.ACTION,
			payload: 'audit',
			showMapCloseIcon: false,
		})
	}

	return (
		<div className={classes.root}>
			{showLoading()}
			{showErrorMessage()}
			{showInfoMessage()}
			{!_.isEmpty(viewData) && showMapAction()}
			<div className={classes.datagrid}>{!_.isEmpty(viewData) && showAuditAction()}</div>
			{!environment.isReadOnlySubmission && !formSelected.abandoned && (
				<DoformsCreateSubmission
					environment={environment}
					formSelected={formSelected}
					action={'add'}
					title={title}
					tab={'forms'}
					source={'dashboard'}
				/>
			)}
			{!environment.isReadOnlyDispatch && !formSelected.abandoned && (
				<DoformsSendDispatch
					environment={environment}
					formSelected={formSelected}
					action={'send'}
					title={title}
					tab={'forms'}
					source={'dashboard'}
				/>
			)}
		</div>
	)
}

export default FormsData
