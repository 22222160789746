import _ from 'lodash'

const defaultQuery = {
	columns: [],
	filter: {
		conditions: [
			{ preset: 'TODAY', target: '@StartTime', type: 'EQ' },
			{ preset: 'ALL', target: '@UserStatus', type: 'EQ' },
		],
	},
}

export const DEVICES_ACTIONS = {
	ACTION: 'DEVICES_ACTION',
	DEVICE_SELECTED: 'DEVICES_DEVICE_SELECTED',
	DEVICE_REFRESH: 'DEVICES_DEVICE_REFRESH',
	DATA_GRID: 'DEVICES_DATA_GRID',
	FORM_SELECTED: 'DEVICES_FORM_SELECTED',
	FORM_COLUMNS: 'DEVICES_FORM_COLUMNS',
	FORM_FILTER_CONDITIONS: 'DEVICES_FORM_FILTER_CONDITIONS',
	FORM_DELETE_SELECTION: 'DEVICES_FORM_DELETE_SELECTION',
	VIEW_DATA: 'DEVICES_VIEW_DATA',
	VIEW_UPDATE: 'DEVICES_VIEW_UPDATE',
	VIEW_CREATE: 'DEVICES_VIEW_CREATE',
	RESET: 'DEVICES_RESET',
	DEVICES_RESTORE_PRODUCER: 'DEVICES_RESTORE_PRODUCER',
	DEVICES_OPEN_NEW_SCREEN_MAP: 'DEVICES_OPEN_NEW_SCREEN_MAP',
	TAB_CHANGE: 'DEVICES_TAB_CHANGE',
	NAME: 'Devices',
	ROWS_PER_PAGE: 'DEVICES_ROWS_PER_PAGE',
	UPDATE_FORM_SELECTED: 'DEVICES_UPDATE_FORM_SELECTED',
	OLD_FORM_SELECTED: 'DEVICES_OLD_FORM_SELECTED',
	CLIENT_FILTERS: 'DEVICES_CLIENT_FILTERS',
}

const initialState = {
	action: null,
	deviceSelected: {},
	formColumns: [],
	formFilterConditions: {},
	formDeleteSelection: [],
	deviceRefresh: false,
	viewUpdate: {},
	viewCreate: {},
	viewData: {},
	openNewScreenMap: false,
	formSelected: {},
	showMapCloseIcon: false,
	dataGrid: {
		title: null,
		query: defaultQuery,
		queryView: {},
		columns: [],
		records: [],
		viewSession: false,
	},
	rowsPerPage: 100,
	oldFormSelected: {},
	clientFilters: {},
}

const devicesReducer = (state = initialState, action) => {
	switch (action.type) {
		case DEVICES_ACTIONS.ACTION: {
			return {
				...state,
				action: action.payload,
				showMapCloseIcon: false,
			}
		}
		case DEVICES_ACTIONS.DEVICE_SELECTED: {
			return {
				...state,
				deviceSelected: action.payload,
				dataGrid: _.cloneDeep(initialState.dataGrid),
			}
		}
		case DEVICES_ACTIONS.FORM_SELECTED: {
			return {
				...state,
				formSelected: action.payload,
			}
		}
		case DEVICES_ACTIONS.UPDATE_FORM_SELECTED: {
			return {
				...state,
				formSelected: { ...state.formSelected, ...action.payload },
			}
		}
		case DEVICES_ACTIONS.OLD_FORM_SELECTED: {
			return {
				...state,
				oldFormSelected: action.payload,
			}
		}
		case DEVICES_ACTIONS.FORM_COLUMNS: {
			return {
				...state,
				formColumns: action.payload,
			}
		}
		case DEVICES_ACTIONS.FORM_FILTER_CONDITIONS: {
			return {
				...state,
				formFilterConditions: action.payload,
			}
		}
		case DEVICES_ACTIONS.VIEW_DATA: {
			return {
				...state,
				viewData: action.payload,
			}
		}
		case DEVICES_ACTIONS.VIEW_UPDATE: {
			return {
				...state,
				viewUpdate: action.payload,
			}
		}
		case DEVICES_ACTIONS.VIEW_CREATE: {
			return {
				...state,
				viewCreate: action.payload,
			}
		}
		case DEVICES_ACTIONS.DEVICE_REFRESH: {
			return {
				...state,
				deviceRefresh: action.payload,
			}
		}
		case DEVICES_ACTIONS.FORM_DELETE_SELECTION: {
			return {
				...state,
				formDeleteSelection: action.payload,
			}
		}
		case DEVICES_ACTIONS.DATA_GRID: {
			return {
				...state,
				dataGrid: { ...state.dataGrid, ...action.payload },
			}
		}
		case DEVICES_ACTIONS.RESET:
			return initialState
		case DEVICES_ACTIONS.DEVICES_RESTORE_PRODUCER: {
			return {
				...state,
				action: action?.payload?.action,
				deviceSelected: action?.payload?.deviceSelected || {},
				formColumns: action?.payload?.formColumns || [],
				formFilterConditions: action?.payload?.formFilterConditions || {},
				formDeleteSelection: action?.payload?.formDeleteSelection || [],
				deviceRefresh: action?.payload?.deviceRefresh,
				viewUpdate: action?.payload?.viewUpdate || {},
				viewCreate: action?.payload?.viewCreate || {},
				viewData: action?.payload?.viewData || {},
				dataGrid: action?.payload?.dataGrid || initialState.dataGrid,
				rowsPerPage: action?.payload?.rowsPerPage || 100,
			}
		}
		case DEVICES_ACTIONS.DEVICES_OPEN_NEW_SCREEN_MAP: {
			return {
				...state,
				openNewScreenMap: action.payload,
			}
		}
		case DEVICES_ACTIONS.TAB_CHANGE: {
			if (DEVICES_ACTIONS.NAME === action.payload) {
				return state
			} else {
				return {
					...state,
					// deviceSelected: {}
				}
			}
		}
		case DEVICES_ACTIONS.ROWS_PER_PAGE: {
			return {
				...state,
				rowsPerPage: action.payload,
			}
		}
		case DEVICES_ACTIONS.CLIENT_FILTERS: {
			return {
				...state,
				clientFilters: action.payload,
			}
		}
		default:
			return state
	}
}

export default devicesReducer
