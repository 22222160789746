import { Add, Delete, Edit, Save } from '@mui/icons-material'
import { Box, Stack, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import AppButton from 'custom-components/AppButton'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import ButtonWithConfirm from '../../menu-items/_components/ButtonWithConfirm'

const WebGroupDialog = ({ groupOptions, open, isSubmittingGroup, onClose, onSave }) => {
	const { t } = useTranslation('common')

	const [editingGroup, setEditingGroup] = useState(null)
	const [editableGroupOptions, setEditableGroupOptions] = useState([])

	useEffect(() => {
		setEditableGroupOptions(groupOptions)
	}, [groupOptions])

	const handleCloseDialog = () => {
		onClose()
		setEditingGroup(null)
		setEditableGroupOptions(groupOptions)
	}

	const handleEnableEditGroup = (id) => {
		setEditingGroup(id)
	}

	const handleAddNewGroup = () => {
		let newId = null

		setEditableGroupOptions((prev) => {
			newId = crypto.randomUUID()

			return [{ label: '', value: newId, isCreating: true }, ...prev]
		})

		setEditingGroup(newId)
	}

	const handleDeleteGroup = (id) => {
		setEditableGroupOptions(
			editableGroupOptions.map((group) => {
				if (group.value === id) {
					return {
						...group,
						isDeleting: true,
					}
				}
				return group
			})
		)
	}

	const handleChangeGroupName = (id, name) => {
		const newGroupOptions = editableGroupOptions.map((group) => {
			if (group.value === id) {
				return {
					...group,
					label: name,
				}
			}
			return group
		})
		setEditableGroupOptions(newGroupOptions)
	}

	const handleSaveGroup = async () => {
		let hasEmptyName = false

		for (const group of editableGroupOptions) {
			if (group.label === '' && !group.isDeleting) {
				hasEmptyName = true
				break
			}
		}

		if (hasEmptyName) {
			toast.error(t('common:toast.pleaseFillWebgroupName'))
			return
		}

		await onSave(editableGroupOptions)
		handleCloseDialog()
	}

	return (
		<Dialog
			open={open}
			keepMounted
			onClose={handleCloseDialog}
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle>{t('common:dis.manageWebGroups')}</DialogTitle>
			<DialogContent dividers>
				<DialogContentText id="alert-dialog-slide-description">
					<Stack spacing={2}>
						{editableGroupOptions?.length > 0 &&
							editableGroupOptions
								.filter((group) => !group.isDeleting)
								.map((group) => {
									const isDisabledDelete = Boolean(group.menuKey) || group.userCount > 0

									return (
										<WebGroupItem
											key={group.value}
											group={group}
											isEditing={editingGroup === group.value}
											disabledDeleteBtn={isDisabledDelete}
											onEnableEdit={handleEnableEditGroup}
											onDelete={handleDeleteGroup}
											onChangeGroupName={handleChangeGroupName}
										/>
									)
								})}
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<AppButton startIcon={<Add />} onClick={handleAddNewGroup}>
					{t('common:misc.add')}
				</AppButton>
				<AppButton startIcon={<Save />} loading={isSubmittingGroup} onClick={handleSaveGroup}>
					{t('common:misc.save')}
				</AppButton>
				<AppButton onClick={handleCloseDialog}>{t('common:misc.cancel')}</AppButton>
			</DialogActions>
		</Dialog>
	)
}

function WebGroupItem({
	group,
	disabledDeleteBtn,
	isEditing,
	onEnableEdit,
	onDelete,
	onChangeGroupName,
}) {
	const { t } = useTranslation('common')

	const { label: groupValue, value: groupKey } = group

	const nameInputRef = useRef(null)

	useEffect(() => {
		if (isEditing) {
			nameInputRef.current.focus()
		}
	}, [isEditing])

	function handleNameChange(event) {
		onChangeGroupName(groupKey, event.target.value)
	}

	return (
		<Stack direction="row" alignItems="center" spacing={4}>
			<Box sx={{ flex: 1 }}>
				<TextField
					inputRef={nameInputRef}
					size="small"
					id="web-group-name"
					label="Name"
					variant="outlined"
					value={groupValue}
					disabled={!isEditing}
					onChange={handleNameChange}
					sx={{ width: '300px' }}
				/>
			</Box>
			<Stack direction="row" alignItems="center" spacing={1}>
				<Button
					aria-label="delete"
					size="large"
					variant="contained"
					sx={{
						borderRadius: '50%',
						width: '40px !important',
						minWidth: '40px !important',
						height: '40px !important',
					}}
					onClick={() => onEnableEdit(groupKey)}
				>
					<Edit fontSize="small" />
				</Button>
				<ButtonWithConfirm
					title={t('common:dis.confirmDeleteItem')}
					onSubmit={() => onDelete(groupKey)}
				>
					<Button
						aria-label="delete"
						size="large"
						variant="contained"
						sx={{
							borderRadius: '50%',
							width: '40px !important',
							minWidth: '40px !important',
							height: '40px !important',
						}}
						disabled={disabledDeleteBtn}
					>
						<Delete fontSize="small" />
					</Button>
				</ButtonWithConfirm>
			</Stack>
		</Stack>
	)
}

export default WebGroupDialog
