import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { AppBar, Collapse, Grid, Toolbar, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { API } from '../../../config'
import { CoreContext } from '../../../custom-components/context/CoreContext'
import { isAuthenticated } from '../../auth/AuthServices'

const useStyles = makeStyles(() => ({
	appbar: (props) => ({
		background: props.backgroundColor,
		color: props.color,
		alignItems: 'center',
		display: 'grid',
		margin: 0,
		padding: 0,
		boxShadow: 'none',
	}),
	menuButton: {
		marginRight: '16px',
	},

	title: (props) => ({
		textAlign: props.title.align,
		fontSize: props.title.fontSize,
		fontStyle: props.title.fontStyle,
		fontWeight: props.title.fontWeight,
		paddingTop: '20px',
	}),
	logo: (props) => ({
		alignItems: props.image.align,
		// margin: 'auto',
		paddingTop: '10px',
		paddingBottom: '10px',
		display: 'block',
		maxWidth: '100%',
		maxHeight: '92px',
	}),
	gridContainer: (props) => ({
		marginTop: '0px',
	}),
	gridItem: (props) => ({
		paddingTop: '0px',
	}),
}))

const BannerNavLayout = () => {
	const { coreValues } = useContext(CoreContext)
	const bannerTheme = coreValues.theme.banner
	const classes = useStyles(bannerTheme)
	const token = isAuthenticated().token
	const { environment } = useSelector((state) => state)

	const showLogo = () =>
		bannerTheme.image.visible && (
			<img
				alt="logo"
				src={`${API}${bannerTheme.image.url}?token=${token}`}
				className={classes.logo}
			/>
		)

	const showTitle = () =>
		bannerTheme.title.visible && (
			<Typography variant="h6" className={classes.title}>
				{bannerTheme.title.content}
			</Typography>
		)

	return (
		<AppBar id="midNavLayoutAppbar" position="static" className={classes.appbar}>
			<Collapse in={!environment.fullscreenMode}>
				<Toolbar>
					<Grid container spacing={3} className={classes.gridContainer}>
						<Grid item xs sx={{ width: 128, maxHeight: 128 }} style={{ paddingTop: '0px' }}>
							{bannerTheme.image.visible && showLogo()}
						</Grid>
						<Grid item xs={6} style={{ paddingTop: '0px' }}>
							{bannerTheme.title.visible && showTitle()}
						</Grid>
						<Grid item xs style={{ paddingTop: '0px' }}></Grid>
					</Grid>
				</Toolbar>
			</Collapse>
		</AppBar>
	)
}

export default BannerNavLayout
