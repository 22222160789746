import { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { unionBy, isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

import { FormControl, InputLabel, MenuItem, Select, Skeleton, Stack, TextField } from '@mui/material'

import useTileQuery from '../../hooks/useTileQuery'
import { getViewTabViewRecords } from '../../../../../components/data/dataServices'
import { TYPES_OF_TILE } from '../../../../../constants'

const BrowserForm = ({
	dashboardKey,
	filterData,
	enabledFetchTileList = true,
	datagridListWhenCreating = [],
	onFilterDataChange,
}) => {
	const { t } = useTranslation('common')
	const { tileList, isLoading } = useTileQuery({
		dashboardKey,
		enableConfig: enabledFetchTileList,
	})
	const { environment } = useSelector((state) => state)
	const [selectedViewKey, setSelectedViewKey] = useState('')
	const [columnList, setColumnList] = useState([])
	const [loadingColumns, setLoadingColumns] = useState(false)
	const dataGridTile = useMemo(() => {
		const currentList =
			tileList?.length > 0 ? tileList?.filter((tile) => tile?.type === TYPES_OF_TILE.DATA_GRID) : []

		return unionBy(currentList, datagridListWhenCreating, 'name')
	}, [tileList, datagridListWhenCreating])

	const handleSelectChange = (e) => {
		const key = e.target.value
		const name = dataGridTile.find((tile) => tile?.key === key)?.name
		const selectedTile = dataGridTile.find((tile) => tile?.key === key)
		const viewKey = selectedTile ? JSON.parse(selectedTile?.settings)?.viewKey : ''
		setSelectedViewKey(viewKey)
		onFilterDataChange('connectedDatagridKey', key)
		if (selectedTile?.is_creating) {
			onFilterDataChange('connectedNameWithNewDatagrid', name)
		}
	}

	const handleSelectChangeColumn = (e) => {
		onFilterDataChange('columnName', e.target.value)
	}

	useEffect(() => {
		(async () => {
			try {
				if (isEmpty(selectedViewKey)) {
					setColumnList([])
					return
				}

				setLoadingColumns(true)
				const response = await getViewTabViewRecords({
					viewKey: selectedViewKey,
					viewSession: false,
					token: environment.apiToken,
				})

				setColumnList(response.data ? response.data.columns : [])

			} catch (error) {
				console.log('getViewTabViewRecords', error)
			} finally {
				setLoadingColumns(false)
			}

		})()
	}, [selectedViewKey])

	useEffect(() => {
		if (!filterData?.connectedDatagridKey) {
			setColumnList([])
			return;
		}
		const selectedTile = dataGridTile.find((tile) => tile?.key === filterData?.connectedDatagridKey)
		const viewKey = selectedTile ? JSON.parse(selectedTile?.settings)?.viewKey : ''
		setSelectedViewKey(viewKey)
	}, [filterData?.connectedDatagridKey])

	return (
		<Stack spacing={2}>
			<FormControl variant="outlined" size="small" fullWidth>
				<TextField
					size="small"
					fullWidth
					id="defaultUrl-basic"
					label={t('common:input.defaultUrl')}
					name="defaultUrl"
					variant="outlined"

					value={filterData?.defaultUrl ?? ''}
					onChange={(e) => onFilterDataChange('defaultUrl', e.target.value)}
				/>
			</FormControl>
			{dataGridTile?.length > 0 &&
				(!isLoading ? (
					<FormControl variant="outlined" size="small">
						<InputLabel id="datagrid-select-small-label">
							{t('common:input.datagridToConnect')}
						</InputLabel>
						<Select
							labelId="datagrid-select-small-label"
							id="datagrid-select-small"
							value={filterData?.connectedDatagridKey ?? ''}
							label={t('common:input.datagridToConnect')}
							onChange={handleSelectChange}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{dataGridTile.map((tile) => (
								<MenuItem value={tile?.key} key={tile?.key}>
									{tile?.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : (
					<Skeleton variant="rectangular" height={40} />
				))
			}

			{selectedViewKey &&
				((!(isLoading || loadingColumns)) ? (
					<FormControl variant="outlined" size="small">
						<InputLabel id="columns-select-small-label">
							{t('common:input.urlField')}
						</InputLabel>
						<Select
							labelId="columns-select-small-label"
							id="columns-select-small"
							value={filterData?.columnName ?? ''}
							label={t('common:input.urlField')}
							onChange={handleSelectChangeColumn}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{columnList.map((item) => (
								<MenuItem value={item?.name} key={item?.name}>
									{item?.title}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : (
					<Skeleton variant="rectangular" height={40} />
				))}
		</Stack>
	)
}

export default BrowserForm
