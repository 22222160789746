import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
	List as ListIcon,
	Room as RoomIcon,
	AccessTime as AccessTimeIcon,
} from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { DEVICES_ACTIONS } from '../../../reducers/devicesReducer'
import { isEmpty } from 'lodash'

const useStyles = makeStyles(() => ({
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const DevicesButton = ({ action, team, device }) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)

	const { devicesModule } = useSelector((state) => state)
	const { deviceSelected, action: actionStore } = devicesModule
	const checkDisabledIcon = `${actionStore}${deviceSelected?.key}` === `${action}${device?.key}`

	const classes = useStyles(iconTheme)

	const dispatch = useDispatch()

	const clickHandler = (e) => {
		e.preventDefault()
		e.stopPropagation()
		dispatch({
			type: DEVICES_ACTIONS.DEVICE_SELECTED,
			payload: { ...device, teamKey: team.key },
		})
		dispatch({
			type: DEVICES_ACTIONS.ACTION,
			payload: action,
		})
	}
	const clickGroupHandler = (e) => {
		e.preventDefault()
		e.stopPropagation()
		dispatch({
			type: DEVICES_ACTIONS.DEVICE_SELECTED,
			payload: { team: team, teamKey: team.key, teamName: team.name },
		})
		dispatch({
			type: DEVICES_ACTIONS.ACTION,
			payload: action,
		})
	}

	const computeIcon = (action) => {
		switch (action) {
			case 'audit':
				return (
					<Tooltip
						title={`${t('tooltip.prefix.data')} - ${
							!device.name || isEmpty(device.name) ? device.number : device.name
						}`}
						arrow
						placement="bottom-start"
						disableInteractive
					>
						<span>
							<IconButton
								aria-label="audit"
								size="small"
								color="primary"
								edge="end"
								disabled={checkDisabledIcon}
								onClick={clickHandler}
							>
								<ListIcon
									fontSize="inherit"
									className={classes.icon}
									sx={{ color: checkDisabledIcon ? '#ddd !important' : 'inherit' }}
								/>
							</IconButton>
						</span>
					</Tooltip>
				)
			case 'activity':
				return (
					<Tooltip
						title={`${t('tooltip.prefix.activity')} - ${
							!device.name || isEmpty(device.name) ? device.number : device.name
						}`}
						arrow
						placement="bottom-start"
						disableInteractive
					>
						<span>
							<IconButton
								aria-label="audit"
								size="small"
								color="primary"
								edge="end"
								disabled={checkDisabledIcon}
								onClick={clickHandler}
							>
								<AccessTimeIcon
									fontSize="inherit"
									className={classes.icon}
									sx={{ color: checkDisabledIcon ? '#ddd !important' : 'inherit' }}
								/>
							</IconButton>
						</span>
					</Tooltip>
				)
			case 'map':
				if (device) {
					return (
						<Tooltip
							title={`${t('tooltip.prefix.map')} - ${
								!device.name || isEmpty(device.name) ? device.number : device.name
							}`}
							arrow
							placement="bottom-start"
							disableInteractive
						>
							<span>
								<IconButton
									aria-label="map"
									size="small"
									color="primary"
									edge="end"
									onClick={clickHandler}
								>
									<RoomIcon fontSize="inherit" className={classes.icon} />
								</IconButton>
							</span>
						</Tooltip>
					)
				} else {
					return (
						<Tooltip
							title={`${t('tooltip.mapLocationsOf')} ${team.name}`}
							arrow
							placement="bottom-start"
							disableInteractive
						>
							<span>
								<IconButton
									aria-label="map"
									size="small"
									color="primary"
									edge="end"
									onClick={clickGroupHandler}
								>
									<RoomIcon fontSize="inherit" className={classes.icon} />
								</IconButton>
							</span>
						</Tooltip>
					)
				}
				break
			default:
				break
		}
	}

	return <>{computeIcon(action)}</>
}

export default DevicesButton
