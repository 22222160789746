import { Close as CloseIcon } from '@mui/icons-material'
import { Fade, Grow, IconButton, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PhotoAlbum from 'react-photo-album'
import Lightbox from 'yet-another-react-lightbox'
import Captions from 'yet-another-react-lightbox/dist/plugins/captions'
import Fullscreen from 'yet-another-react-lightbox/dist/plugins/fullscreen'
import Slideshow from 'yet-another-react-lightbox/dist/plugins/slideshow'
import Zoom from 'yet-another-react-lightbox/dist/plugins/zoom'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import { getSubmissionRecordData, getSubmissionRecordImageData } from './recordsService'

import 'yet-another-react-lightbox/dist/plugins/captions/captions.css'
import 'yet-another-react-lightbox/dist/styles.css'
import { useCallback } from 'react'

const useStyles = makeStyles(() => ({
	root: {
		display: 'block',
		height: '100%',
		paddingTop: '5px',
		paddingLeft: '10px',
		paddingRight: '10px',
	},
	closeIcon: {
		display: 'block',
		position: 'absolute',
		top: '3px',
		right: '2px',
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
	imageContainer: {
		display: 'block',
		position: 'relative',
		overflowY: 'auto',
		height: '100%',
	},
	headingLabel: {
		display: 'flex',
		minHeight: '32px',
	},
	hidden: {
		display: 'none',
	},
	recordName: {
		minHeight: '35px',
	},
	imageCard: {
		position: 'relative',
		'&:hover': {
			'& .imageCaption': {
				display: 'block',
				position: 'absolute',
				backgroundColor: '#eee',
				borderRadius: '2px',
				left: '3px',
				bottom: '5px',
				padding: '3px',
				fontSize: '12px',
				lineHeight: '12px',
				maxWidth: '90%',
				opacity: 0.8,
			},
		},
		'& .imageCaption': {
			display: 'none',
		},
	},
}))

const DoformsGalleryView = (props) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const { environment, record, recordBlobs, onClose, viewTitle } = props

	const [loading, setLoading] = useState(false)
	const [titleLoading, setTitleLoading] = useState(true)
	const [error, setError] = useState(null)
	const [index, setIndex] = useState(-1)
	const [hovering, setHovering] = useState(false)
	const [photos, setPhotos] = useState([])
	const [title, setTitle] = useState('')

	useEffect(() => {
		setTitleLoading(true)
		loadRecordData().then((res) => {
			if (res?.data?.name) {
				setTitle(res?.data?.name)
				setTitleLoading(false)
			}
		})

		return () => {
			setPhotos([])
			setHovering(false)
		}
	}, [])

	useEffect(() => {
		if (recordBlobs && recordBlobs.length > 0) {
			initiateLoadAllImageData()
		} else {
			setPhotos([])
		}
	}, [recordBlobs])

	const initiateLoadAllImageData = () => {
		setLoading(true)
		const promises = []
		const imageList = []
		recordBlobs.forEach((blob) => {
			const promise = new Promise((resolve, reject) => {
				loadImageData(blob)
					.then((res) => {
						let img = new Image()
						img.src = URL.createObjectURL(res.data)
						img.onload = function () {
							imageList.push({
								key: blob.key,
								src: this.src,
								width: this.width,
								height: this.height,
								alt: blob.fileName,
								description: blob.field.options.label.en,
							})
							setPhotos(imageList)
							resolve(res)
						}
						img.remove()
					})
					.catch((err) => {
						setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
						reject()
					})
			})
			promises.push(promise)
		})

		Promise.all(promises).then(() => {
			setLoading(false)
		})
	}

	const loadRecordData = async () => {
		let promise = await getSubmissionRecordData(record, environment.apiToken)
		return promise
	}

	const loadImageData = async (blob) => {
		let promise = await getSubmissionRecordImageData(record, blob, environment.apiToken)
		return promise
	}

	const showLoading = () => (loading || titleLoading) && <LoadingSpinner />

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setError(null)
	}

	const handleClose = () => {
		onClose()
	}

	const slides =
		photos.length > 0 &&
		photos.map(({ src, width, height, alt, description }) => ({
			src,
			width,
			height,
			alt,
			description,
		}))

	const handlePopoverOpen = useCallback(
		(newItemName) => (event) => {
			event.preventDefault()
			setHovering(newItemName)
		},
		[]
	)

	const handlePopoverClose = (event) => {
		event.preventDefault()
		setHovering(false)
	}

	const renderRowContainer = ({ rowContainerProps, children }) => {
		const newRowContainerProps = { ...rowContainerProps, style: { ...rowContainerProps.style } }
		return <div {...newRowContainerProps}>{children}</div>
	}

	const renderCustomPhoto = ({ imageProps, photo, layout, layoutOptions }) => {
		const { src, alt, srcSet, sizes, style, ...restImageProps } = imageProps
		const { description } = photo
		const { photoIndex } = layout
		const { spacing } = layoutOptions
		return (
			<div
				className={classes.imageCard}
				style={{ ...style, maxHeight: '200px', position: 'relative' }}
				onMouseOver={handlePopoverOpen(`${photo.key}`)}
				onMouseLeave={handlePopoverClose}
			>
				<Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={300 * (photoIndex + 1)}>
					<img
						src={src}
						alt={alt}
						style={{ width: 'auto', height: '100%' }}
						{...(srcSet ? { srcSet, sizes } : null)}
						{...restImageProps}
					/>
				</Grow>
				{hovering === photo.key && (
					<Fade in={!!hovering}>
						<div className={'imageCaption'}>
							<span>{description}</span>
						</div>
					</Fade>
				)}
			</div>
		)
	}

	return (
		<div className={classes.root}>
			{showErrorMessage()}
			{showLoading()}
			<div className={classes.closeIcon}>
				<Tooltip title={t('tooltip.close')} arrow placement="bottom-start" disableInteractive>
					<span>
						<IconButton className={classes.icon} onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</span>
				</Tooltip>
			</div>
			<Typography variant="h6" className={!viewTitle ? classes.hidden : classes.headingLabel}>
				{viewTitle}
			</Typography>
			<div className={classes.recordName}>{title}</div>
			<div className={classes.imageContainer}>
				{!loading && !titleLoading && photos.length === 0 ? (
					<Typography>{t('misc.noImageFound')}.</Typography>
				) : (
					<>
						<PhotoAlbum
							photos={photos}
							renderRowContainer={renderRowContainer}
							renderPhoto={renderCustomPhoto}
							layout="rows"
							targetRowHeight={200}
							onClick={(event, photo, index) => setIndex(index)}
						/>
						<Lightbox
							slides={slides}
							open={index >= 0}
							index={index}
							close={() => setIndex(-1)}
							carousel={{ finite: false }}
							styles={{
								root: {
									'--yarl__color_backdrop': 'rgba(0, 0, 0, .9)',
									'--yarl__slide_description_text_align': 'center',
								},
							}}
							plugins={[Captions, Fullscreen, Slideshow, Zoom]}
						/>
					</>
				)}
			</div>
		</div>
	)
}

export default DoformsGalleryView
