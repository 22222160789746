import { Box, Button, CircularProgress, Stack, TextField } from '@mui/material'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import ToastAlert from '../../../../core/Layouts/ToastAlert'
import SideDialog from '../../components/SideDialog'
import TextSearchForm from './TextSearchForm'

function TextSearchSettingsDialog({
	tileElementWidth,
	defaultTileWidth,
	tile,
	open,
	settings,
	isSubmitting,
	showStable,
	onClose,
	onSubmit,
	onResizeTileWidth,
	dashboardKeyList,
}) {
	const { t } = useTranslation('common')
	const { id: dashboardKey } = useParams()

	const [alertInfo, setAlertInfo] = useState({
		open: false,
		text: '',
		type: 'success',
	})

	const [filterData, setFilterData] = useState({})

	const dialogWidth = tile?.w ?? 6
	const STABLE_WIDTH = 6

	useEffect(() => {
		if (settings?.connectedDatagridKey) {
			setFilterData((prev) => ({ ...prev, connectedDatagridKey: settings?.connectedDatagridKey }))
		}
	}, [JSON.stringify(settings?.connectedDatagridKey)])

	useEffect(() => {
		if (!isEmpty(dashboardKeyList)) {
			const newPrimaryKeyList = dashboardKeyList
				.filter((item) => item.tileName === tile.i)
				.map((item) => item.key)
			setFilterData((prev) => ({ ...prev, primaryKeyList: newPrimaryKeyList }))
		}
	}, [dashboardKeyList])

	const handleFilterDataChange = (id, value) => {
		setFilterData((prev) => ({ ...prev, [id]: value }))
	}

	const handleSubmit = () => {
		onSubmit?.({
			connectedDatagridKey: filterData?.connectedDatagridKey,
			primaryKeyList: filterData?.primaryKeyList,
		})
	}

	return (
		<>
			<ToastAlert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />

			<SideDialog
				defaultTileWidth={defaultTileWidth}
				open={open}
				onClose={onClose}
				width={showStable ? STABLE_WIDTH : dialogWidth}
				tileElementWidth={tileElementWidth}
				onResizeWidth={onResizeTileWidth}
			>
				<Stack
					sx={{
						'& .MuiInputBase-input': {
							fontSize: '0.9rem !important',
						},
						'& .MuiCheckbox-root': {
							p: 0,
						},
					}}
				>
					<Box sx={{ px: 1 }}>
						<TextField
							fullWidth
							id="label-basic"
							label={t('common:chart.labels')}
							variant="standard"
							value={tile.i}
							disabled={true}
							size="small"
						/>
					</Box>

					<Box sx={{ px: 1, mt: 2 }}>
						<TextSearchForm
							dashboardKey={dashboardKey}
							filterData={filterData}
							onFilterDataChange={handleFilterDataChange}
							dashboardKeyList={dashboardKeyList}
						/>
					</Box>

					<Stack direction="row" justifyContent="flex-end" mt={2}>
						<Stack spacing={2} direction="row">
							<Button
								size="small"
								variant="contained"
								onClick={handleSubmit}
								disabled={isSubmitting}
								startIcon={isSubmitting ? <CircularProgress color="inherit" size="1em" /> : null}
							>
								{t('common:misc.ok')}
							</Button>
							<Button size="small" variant="text" onClick={onClose}>
								{t('common:misc.cancel')}
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</SideDialog>
		</>
	)
}

export default TextSearchSettingsDialog
