import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'

const SkeletonLoaderDataGrid = () => {
	return (
		<Stack sx={{ marginTop: '-8px' }}>
			<Skeleton variant="text" width={400} height={50} style={{ marginLeft: 10 }} />
			<div style={{ display: 'flex' }}>
				<Skeleton variant="text" width={300} height={50} />
				<Skeleton variant="text" width={300} height={50} sx={{ ml: 2 }} />
			</div>
			<Skeleton variant="rectangular" height={950} />
		</Stack>
	)
}

export default SkeletonLoaderDataGrid
