import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { makeStyles } from '@mui/styles'
import { EnvironmentProvider } from '../../../custom-components/context/EnvironmentContext'
import { IconThemeProvider } from '../../../custom-components/context/IconThemesContext'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import { LOOKUPS_ACTIONS } from '../../../reducers/lookupsReducer'
import '../../../styles.css'
import DashboardSplitPane from '../../core/Dashboard/DashboardSplitPane'
import { getAllProjects, getProjectForms } from '../../data/dataServices'
import LookupsDashboard from './LookupsDashboard'
import LookupsData from './LookupsData'
import { useParams } from 'react-router-dom'
import { useUrlQueryParams } from 'utils/hooks/useUrlQueryParams'

const useStyles = makeStyles(() => ({
	formSidePanel: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		height: '100%',
	},
	formBody: {
		display: 'contents',
	},
}))

const LookupsModule = () => {
	const { id: paramId } = useParams()

	const urlQueryParam = useUrlQueryParams()
	const actionQuery = urlQueryParam.get('action')
	const classes = useStyles()

	const { environment, lookupsModule } = useSelector((state) => state)
	const iconTheme = environment.theme.icons

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const dispatch = useDispatch()
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		return () => (isMounted.current = false)
	}, [])

	useEffect(() => {
		var newFullScreenDataModString = localStorage.getItem('newFullScreenData_mod')
		if (!_.isEmpty(paramId) && newFullScreenDataModString != null) {
			var newFullScreenDataMod = JSON.parse(newFullScreenDataModString)
			var lookupsModuleTmp = newFullScreenDataMod.lookupsModule
			dispatch({
				type: LOOKUPS_ACTIONS.LOOKUPS_RESTORE_PRODUCER,
				payload: lookupsModuleTmp,
			})
			localStorage.removeItem('newFullScreenData_mod')
		} else {
			dispatch({
				type: LOOKUPS_ACTIONS.TAB_CHANGE,
				payload: LOOKUPS_ACTIONS.NAME,
			})

			if (_.isEmpty(environment.lookups)) {
				initiateLoadAllProjects()
			}
		}
	}, [])

	useEffect(() => {
		if (!environment.refresh) return
		setLoading(true)
		dispatch({
			type: LOOKUPS_ACTIONS.LOOKUPS_PROJECT,
			payload: [],
		})
		dispatch({
			type: ENV_ACTIONS.CLEAR_LOOKUPS,
		})
		initiateLoadAllProjects()
		dispatch({
			type: ENV_ACTIONS.REFRESH,
			payload: false,
		})
	}, [environment.refresh])

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setError(null)
	}

	const initiateLoadAllProjects = () => {
		setLoading(true)
		loadAllProjects()
			.then((res) => {
				const projects = _.sortBy(res.data, (project) => project.name.toLowerCase())
				const lookupProjects = projects.filter(
					(project) => project && project.name.toLowerCase() === 'lookups'
				)
				initiateLoadFormsByProject(lookupProjects)
			})
			.catch((err) => {
				setError('Code ' + err.response?.data?.code + ': ' + err.response?.data?.message)
			})
	}

	const loadAllProjects = async () => {
		let promise = await getAllProjects(environment.apiToken)
		return promise
	}

	const initiateLoadFormsByProject = (projects) => {
		if (isMounted.current) {
			setLoading(true)
		}
		let promises = []
		let newProjects = []
		for (let i = 0; i < projects.length; i++) {
			const promise = loadFormsByProject(projects[i])
				.then((res) => {
					newProjects[i] = { ...res }
					const lookupProject = newProjects.find(
						(project) => project && project.name.toLowerCase() === 'lookups'
					)
					dispatch({
						type: LOOKUPS_ACTIONS.LOOKUPS_PROJECT,
						payload: lookupProject,
					})
					dispatch({
						type: ENV_ACTIONS.GET_LOOKUPS,
						payload: lookupProject,
					})
				})
				.catch((err) => {
					setError('Code ' + err.response?.data?.code + ': ' + err.response?.data?.message)
				})
			promises.push(promise)
		}

		Promise.all(promises).then(() => {
			if (isMounted.current) {
				setLoading(false)
			}

			/*dispatch({
                type: ENV_ACTIONS.IS_PROJECT_FORMS_LOADED,
                payload: true
            });*/
		})
	}

	const loadFormsByProject = (project) => {
		return getProjectForms(project.key, environment.apiToken).then((resp) => {
			let promiseObject = { key: project.key, name: project.name, forms: [] }
			if (resp?.data && _.isArray(resp.data) && resp.data.length) {
				promiseObject.forms = _.sortBy(resp.data, (project) => project.name.toLowerCase())
				dispatch({
					type: ENV_ACTIONS.GET_FORMS,
					payload: promiseObject.forms,
				})
			}
			return promiseObject
		})
	}

	const sidePanel = () => (
		<div id="form-side-panel" className={classes.formSidePanel}>
			<EnvironmentProvider values={environment}>
				<LookupsDashboard
					lookupsProject={lookupsModule.lookupsProject}
					module={lookupsModule}
					loading={loading}
				/>
			</EnvironmentProvider>
		</div>
	)

	const formBody = () => (
		<div id="form-body" className={classes.formBody}>
			{showErrorMessage()}
			<LookupsData
				lookupsProject={lookupsModule.lookupsProject}
				environment={environment}
				module={lookupsModule}
				action={lookupsModule.action}
			/>
		</div>
	)

	return (
		<IconThemeProvider values={iconTheme}>
			<DashboardSplitPane sidePanel={sidePanel()} mainContent={formBody()} />
		</IconThemeProvider>
	)
}

export default LookupsModule
