import { toast } from 'react-toastify'

export function validateMenuInfo(menuData, t) {
	if (!menuData?.name) {
		toast.error(t('common:dis.pleaseFillInformation'))
		return true
	}

	// Skip validation if there are no items
	if (!menuData?.items?.length) {
		return false
	}

	for (let item of menuData?.items) {
		const missingPagePath = item?.type === 'page' && !item?.page?.path

		if (!item?.name || missingPagePath) {
			toast.error(t('common:dis.pleaseFillInformation'))
			return true
		}
		if (item?.items) {
			for (let subItem of item?.items) {
				const missingPagePath = subItem?.type === 'page' && !subItem?.page?.path

				if (!subItem?.name || missingPagePath) {
					toast.error(t('common:dis.pleaseFillInformation'))
					return true
				}
			}
		}
	}
	return false
}
