import { Box, Button } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import tileApi from 'apis/disApi/tileApi'
import { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isJson, logErrorMessage } from '../../../../../utils/functions/helpers'
import ActivityModule from '../../../activity/ActivityModule'
import DevicesModule from '../../../devices/DevicesModule'
import FormModule from '../../../forms/FormsModule'
import LookupsModule from '../../../lookups/LookupsModule'
import ReportsModule from '../../../reports/ReportsModule'
import ViewsModule from '../../../views/ViewsModule'
import TileWrapper from '../../components/TileWrapper'
import { tileKeys } from '../../hooks/useTileQuery'
import PageSettingsDialog from './PageSettingsDialog'

export const PAGE_ROUTES = [
	{ path: '/forms', component: FormModule },
	{ path: '/devices', component: DevicesModule },
	{ path: '/lookups', component: LookupsModule },
	{ path: '/views', component: ViewsModule },
	{ path: '/reports', component: ReportsModule },
	{ path: '/scheduler' },
	{ path: '/activity', component: ActivityModule },
]

function ScheduleButton() {
	const environment = useSelector((state) => state.environment)

	const launchDispatchScheduler = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(environment.renderHost + '?target=scheduler&token=' + environment.apiToken)
		w.document.title = 'doForms'
	}

	return (
		<Button variant="text" onClick={launchDispatchScheduler}>
			Go to Scheduler
		</Button>
	)
}

function PageTile({ tile }) {
	const queryClient = useQueryClient()
	const { id: dashboardKey } = useParams()
	const environment = useSelector((state) => state.environment)

	const [settingsOpen, setSettingsOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const formMapRef = useRef(null)

	const updateTile = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})

	const settings = useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])
	const { selectedMenuPath } = settings

	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({ ...settings, tileWidth: width })
			setIsLoading(true)
			await updateTile.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
			setIsLoading(false)
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const handleSubmitSettings = async (activePath) => {
		const editedSettings = JSON.stringify({
			...settings,
			selectedMenuPath: activePath,
		})
		await updateTile.mutateAsync({
			dashboardKey,
			tileKey: tile.key,
			data: { settings: editedSettings },
			token: environment.apiToken,
		})
		handleCloseDialog()
	}

	const MenuComponent = useMemo(() => {
		if (!selectedMenuPath) return null
		if (selectedMenuPath === '/scheduler') return ScheduleButton

		return PAGE_ROUTES.find((route) => route.path === selectedMenuPath)?.component
	}, [selectedMenuPath])

	return (
		<TileWrapper title={tile?.i} onSettingClick={handleOpenDialog} ref={formMapRef}>
			<PageSettingsDialog
				defaultTileWidth={300}
				tileElementWidth={formMapRef?.current?.clientWidth}
				open={settingsOpen}
				tile={tile}
				selectedMenuPath={selectedMenuPath}
				isLoading={isLoading}
				onClose={handleCloseDialog}
				onResizeTileWidth={handleResizeTileWidth}
				onSubmit={handleSubmitSettings}
			/>

			{MenuComponent && (
				<Box
					sx={{
						backgroundColor: 'white',
						height: '100%',
						width: '100%',
					}}
				>
					<MenuComponent />
				</Box>
			)}
		</TileWrapper>
	)
}

export default PageTile
