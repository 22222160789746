import { LoadingButton } from '@mui/lab'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { capitalizeFirstLetter } from '../components/data/dataHelpers'

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiButton-root': {
			textTransform: 'none',
		},
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const DoformsDialog = (props) => {
	const { environment, open, dialog, loading, dialogAction, onClose, onConfirm } = props
	const iconTheme = environment.theme.icons
	const classes = useStyles(iconTheme)

	const handleClose = () => {
		onClose()
	}

	const handleConfirm = () => {
		onConfirm()
	}

	const showDialog = () =>
		open && (
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className={classes.root}
			>
				<DialogTitle id="alert-dialog-title">{capitalizeFirstLetter(dialog.title)}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{dialog.message}</DialogContentText>
				</DialogContent>
				{dialogAction && (
					<DialogActions>
						<LoadingButton
							onClick={handleConfirm}
							className={classes.icon}
							loading={loading}
							autoFocus
						>
							Confirm
						</LoadingButton>
						<Button onClick={handleClose} className={classes.icon}>
							Cancel
						</Button>
					</DialogActions>
				)}
			</Dialog>
		)

	return <>{showDialog()}</>
}

export default DoformsDialog
