import React, { useEffect } from 'react'
//3rd libs
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
//context
import { IconThemeProvider } from '../../../custom-components/context/IconThemesContext'
//utils
import { ACTIVITY_ACTIONS } from '../../../reducers/activityReducer'
//components
import ActivityData from './ActivityData'

const ActivityModule = () => {
	const { environment, activityModule } = useSelector((state) => state)
	const iconTheme = environment.theme.icons

	const dispatch = useDispatch()

	useEffect(() => {
		// reset form filter conditions when changed page
		return () => {
			dispatch({
				type: ACTIVITY_ACTIONS.FORM_FILTER_CONDITIONS,
				payload: {},
			})
		}
	}, [])

	useEffect(() => {
		const newFullScreenDataModString = localStorage.getItem('newFullScreenData_mod')
		if (newFullScreenDataModString != null) {
			const newFullScreenDataMod = JSON.parse(newFullScreenDataModString)
			const activityModule = newFullScreenDataMod.activityModule
			dispatch({
				type: ACTIVITY_ACTIONS.ACTIVITY_RESTORE_PRODUCER,
				payload: activityModule,
			})
			localStorage.removeItem('newFullScreenData_mod')
		}
	}, [])

	return (
		<IconThemeProvider values={iconTheme}>
			<Box sx={{ px: 1, pb: (theme) => theme.spacing(4), height: '100%' }}>
				<ActivityData environment={environment} module={activityModule} />
			</Box>
		</IconThemeProvider>
	)
}

export default ActivityModule
