import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List as ListIcon } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { VIEWS_ACTIONS } from '../../../reducers/viewsReducer'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const ViewsButton = ({ action, view, ownerKey }) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const { viewsModule } = useSelector((state) => state)
	const { viewSelected } = viewsModule
	const checkDisabledIcon = viewSelected?.key === view?.key

	const history = useHistory()
	const dispatch = useDispatch()

	const clickHandler = (e) => {
		e.preventDefault()
		e.stopPropagation()
		dispatch({
			type: VIEWS_ACTIONS.VIEW_SELECTED,
			payload: { ...view, ownerKey: ownerKey },
		})
		dispatch({
			type: VIEWS_ACTIONS.ACTION,
			payload: action,
		})
	}

	const computeIcon = (action) => {
		switch (action) {
			case 'audit':
				return (
					<Tooltip
						title={`${t('tooltip.prefix.data')} - ${view.name}`}
						arrow
						placement="bottom-start"
						disableInteractive
					>
						<span>
							<IconButton
								aria-label="audit"
								size="small"
								color="primary"
								edge="end"
								disabled={checkDisabledIcon}
								onClick={clickHandler}
							>
								<ListIcon
									fontSize="inherit"
									className={classes.icon}
									sx={{ color: checkDisabledIcon ? '#ddd !important' : 'inherit' }}
								/>
							</IconButton>
						</span>
					</Tooltip>
				)
			default:
				return
		}
	}

	return <>{computeIcon(action)}</>
}

export default ViewsButton
