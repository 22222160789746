import { QuestionMark } from '@mui/icons-material'
import {
	AppBar,
	Button,
	FormControl,
	FormControlLabel,
	IconButton,
	MenuItem,
	Popover,
	Stack,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { makeStyles } from '@mui/styles'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { HELP_LINK_PROFILE } from '../../../constants'
import { CoreContext } from '../../../custom-components/context/CoreContext'
import DoformsDefaultForm from '../../../custom-components/DoformsDefaultForm'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import DoformsTranslation from '../../../custom-components/DoformsTranslation'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import Signout from '../../user/Signout'
import { updateUserCurrent } from '../services/environmentService'
import DoformsMapLogin from 'custom-components/DoformsMapLogin'
import { getDisplayTimezone } from '../../data/dataHelpers'

const useStyles = makeStyles(() => ({
	root: (props) => ({
		background: props.backgroundColor,
		color: 'white',
		'& .MuiButton-root': {
			textTransform: 'none !important',
			color: props.color,
			borderColor: 'white !important',
			'&:hover': {
				color: props.active.color,
				backgroundColor: 'transparent',
			},
		},
		'& .MuiTypography-root': {
			color: props.color,
		},
	}),

	toolbar: {
		minHeight: 15,
		maxHeight: 24,
		justifyContent: 'end',
		padding: 0,
		WebkitFontSmoothing: 'antialiased',
	},

	button: {
		display: 'inline-block',
		fontSize: '12px',
		lineHeight: '24px',
		fontWeight: 'normal',
		margin: '0 7px',
		letterSpacing: 0,
		paddingTop: 0,
		paddingBottom: 0,
	},

	label: {
		display: 'inline-block',
		fontSize: '12px',
		lineHeight: '24px',
		margin: '0 15px',
		letterSpacing: 0,
	},

	separator: (props) => ({
		display: 'inline-block',
		fontSize: '12px',
		lineHeight: '24px',
		marginTop: '-3px',
		color: props.color,
	}),

	helperIcon: {
		zIndex: 1000,
	},
}))

const TopNavLayout = () => {
	const [t] = useTranslation('common')
	const { coreValues } = useContext(CoreContext)
	const { theme, account, translations, userCurrent } = coreValues
	const environment = useSelector((state) => state.environment)

	const classes = useStyles(theme.topBar)

	const [anchorEl, setAnchorEl] = useState(null)
	const [signOutOpen, setSignOutOpen] = useState(false)
	const [autoRefreshSelected, setAutoRefreshSelected] = useState(
		environment.userCurrent.autoRefresh || 'off'
	)
	const [error, setError] = useState(null)

	const isMenuOpen = Boolean(anchorEl)
	const dispatch = useDispatch()

	const autoRefreshGroup = [
		{ name: 'on', title: t('common:nav.on') },
		{ name: 'notify', title: t('common:nav.notify') },
		{ name: 'off', title: t('common:nav.off') },
	]

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setError(null)
	}

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	const handleSignOut = () => {
		setSignOutOpen(true)
	}
	const signoutClosed = () => {
		setSignOutOpen(false)
	}

	const handleGoToHelpLink = () => {
		window.open(HELP_LINK_PROFILE, '_blank')
	}

	const showTimeZone = () => {
		return (
			<>
				<FormControl sx={{ mt: 1, width: '100%' }}>
					<Stack direction="row" justifyContent="space-between">
						<Typography variant={'subtitle1'}>{t('nav.timezone')}</Typography>
						<Tooltip
							title={`${t('tooltip.goToHelpLink')}`}
							arrow
							placement="bottom-start"
							disableInteractive
						>
							<IconButton
								sx={environment.iconsTheme}
								aria-describedby={'help link'}
								className={classes.helperIcon}
								onClick={handleGoToHelpLink}
								size="small"
								aria-label="help link"
							>
								<QuestionMark fontSize="small" />
							</IconButton>
						</Tooltip>
					</Stack>
					<Typography sx={{ marginTop: '6px' }}>{getDisplayTimezone(environment)}</Typography>
				</FormControl>
			</>
		)
	}

	const handleAutoRefreshOptionSelected = (e) => {
		setAutoRefreshSelected(e.target.value || 'off')
		let newUserCurrentObj = {
			...environment.userCurrent,
			autoRefresh: e.target.value || 'off',
		}

		updateUserCurrent(newUserCurrentObj, environment.apiToken)
			.then((res) => {
				dispatch({
					type: ENV_ACTIONS.AUTO_REFRESH_UPDATE,
					payload: newUserCurrentObj.autoRefresh,
				})
			})
			.catch((err) => {
				//setError('Code ' + err.response.data.code + ": " + err.response.data.message);
			})
			.finally(() => { })
	}

	const showAutoRefreshOptions = () => {
		return (
			<FormControl style={{ width: '100%', display: 'inline-block' }}>
				<Typography variant={'subtitle1'} id="demo-row-radio-buttons-group-label">
					{t('common:nav.autoRefresh')}
				</Typography>
				<RadioGroup
					style={{ float: 'left' }}
					row
					aria-labelledby="demo-row-radio-buttons-group-label"
					name="row-radio-buttons-group"
					onChange={handleAutoRefreshOptionSelected}
				>
					{autoRefreshGroup.map((option, index) => (
						<FormControlLabel
							key={index}
							value={option.name}
							control={<Radio checked={autoRefreshSelected === option.name} />}
							label={option.title}
						/>
					))}
				</RadioGroup>
			</FormControl>
		)
	}

	const showWhatNew = () => {
		if (!account || !account.whatsNew || !account.whatsNew.expires) {
			return false
		}
		var curDate = new Date()
		var whatNewExpires = new Date(account.whatsNew.expires)
		if (curDate.getTime() < whatNewExpires.getTime()) {
			return true
		}
		return false
	}

	const handleClickWhatNew = () => {
		if (!account || !account.whatsNew || !account.whatsNew.url) {
			return
		}
		var w = window.open(account.whatsNew.url)
		w.document.title = t('common:misc.whatsNew')
	}

	const renderMenu = (
		<Popover
			anchorEl={anchorEl}
			id={'my-profile'}
			keepMounted
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}
			PaperProps={{
				style: { maxWidth: '45vw', maxHeight: '55vh' },
			}}
		>
			<MenuItem>{showTimeZone()}</MenuItem>
			{isMenuOpen && (
				<MenuItem>
					<DoformsDefaultForm tab="navigation" source="navigation" />
				</MenuItem>
			)}
			<MenuItem>{showAutoRefreshOptions()}</MenuItem>
			<MenuItem>
				<DoformsTranslation />
			</MenuItem>
			<MenuItem>
				<DoformsMapLogin />
			</MenuItem>
		</Popover>
	)

	return (
		<>
			{showErrorMessage()}
			<AppBar id="appBar" position="static" className={classes.root}>
				<Toolbar id="toolBar" className={classes.toolbar}>
					{showWhatNew() && (
						<Button className={classes.button} onClick={handleClickWhatNew}>
							{account.whatsNew.text}
						</Button>
					)}
					{showWhatNew() && <span className={classes.separator}>|</span>}

					<Typography className={classes.label}>
						{translations.lbAccount ? translations.lbAccount : t('nav.account')}: {account.code}
					</Typography>
					<span className={classes.separator}>|</span>
					<Typography className={classes.label}>
						{translations.lbUser ? translations.lbUser : t('nav.user')}: {userCurrent.email}
					</Typography>
					<span className={classes.separator}>|</span>
					<Button className={classes.button} onClick={handleProfileMenuOpen}>
						{translations.mnProfile ? translations.mnProfile : t('nav.myProfile')}
					</Button>
					<span className={classes.separator}>|</span>
					<Button className={classes.button} onClick={handleSignOut}>
						{translations.lbSignOut ? translations.lbSignOut : t('common:nav.signOut')}
					</Button>
				</Toolbar>
			</AppBar>
			{renderMenu}
			{signOutOpen && (
				<Signout
					open={signOutOpen}
					theme={theme}
					translations={translations}
					source="default"
					onClose={signoutClosed}
				/>
			)}
		</>
	)
}

export default TopNavLayout
