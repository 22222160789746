import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import lngOptions from '../utils/functions/lngOptions'
import { FormControl, MenuItem, Select, Typography } from '@mui/material'

const useStyles = makeStyles(() => ({
	root: {
		'label + &': {
			marginTop: '16px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			'& legend': {
				display: 'none',
			},
		},
	},
}))

const DoformsTranslation = () => {
	const classes = useStyles()
	const languages = lngOptions()
	const width = '400px'
	const [t, i18n] = useTranslation('common')
	const [language, setLanguage] = useState('en')

	const handleChange = (event) => {
		setLanguage(event.target.value)
		i18n.changeLanguage(event.target.value)
	}

	useEffect(() => {
		setLanguage(i18n.language)
	}, [])

	return (
		<>
			<FormControl
				sx={{ mt: 1, mb: 1, width: width }}
				variant="outlined"
				fullWidth
				className={classes.root}
			>
				<Typography variant={'subtitle1'}>{t('misc.language')}</Typography>
				<Select
					labelId="language-select-label"
					id="language-select"
					value={language}
					onChange={handleChange}
					size="small"
				>
					{languages.map((lng, index) => (
						<MenuItem
							key={index}
							value={lng.value}
							onMouseDown={(event) => event.stopPropagation()}
						>
							{t(`common:languages.${lng.name}`)}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	)
}

export default DoformsTranslation
