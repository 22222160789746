import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isEqual } from 'lodash'

import { Typography, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, FormControlLabel, Tooltip } from '@mui/material'

export const DEFAULT_CHECKED_OPTIONS = ['selectAll']

const OperatorCheckboxList = ({
    optionList,
    checkedOptions = DEFAULT_CHECKED_OPTIONS,
    onChangeOptions,
    hideScroll,
    title,
    getLabel,
    getValue,
    hasValueBefore,
    isTileDialogSetting = false
}) => {
    const { t } = useTranslation('common')

    const SELECT_ALL_OPTION = {
        label: 'Select all',
        value: 'selectAll',
    }

    const validcheckedOptions = useMemo(() => {
        return checkedOptions.filter(item => optionList?.find(option => option.value === item))
    }, [optionList, checkedOptions])

    const allOptionsValue = useMemo(() => {
        return optionList.map(option => option.value)
    }, [optionList])

    // const [selectAllChecked, setSelectAllChecked] = useState(false)
    const [currentCheckedOptions, setCurrentCheckedOptions] = useState([])

    const selectAllChecked = useMemo(() => {
        return isEqual(validcheckedOptions.sort(), allOptionsValue.sort())
    }, [validcheckedOptions, allOptionsValue])

    function handleSelectAllChecked(event) {
        const { checked } = event.target
        if (checked) {
            onChangeOptions(allOptionsValue)
            return
        }

        onChangeOptions([])
    }

    useEffect(() => {
        if (!hasValueBefore) {
            onChangeOptions(allOptionsValue)
            return
        }

        const isSelectAllChecked = isEmpty(validcheckedOptions) || isEqual(validcheckedOptions, allOptionsValue)
        if (!isEqual(validcheckedOptions.sort(), checkedOptions.sort())) {
            onChangeOptions(isSelectAllChecked ? allOptionsValue : validcheckedOptions)
            return
        }

        setCurrentCheckedOptions(validcheckedOptions)
    }, [allOptionsValue, validcheckedOptions])

    const handleOtherOptionsCheck = (value) => () => {
        if (!Array.isArray(validcheckedOptions)) return
        const currentIndex = currentCheckedOptions?.indexOf(value)
        const selectAllIndex = currentCheckedOptions?.indexOf(SELECT_ALL_OPTION.value)
        const newChecked = []
        const tmpChecked = [...currentCheckedOptions]

        if (currentIndex === -1) {
            if (selectAllIndex !== -1) {
                tmpChecked.splice(selectAllIndex, 1)
            }
            tmpChecked.push(value)
            newChecked.push(...tmpChecked)
        } else {
            if (selectAllIndex !== -1) {
                tmpChecked.splice(selectAllIndex, 1)
            }
            tmpChecked.splice(currentIndex, 1)
            newChecked.push(...tmpChecked)
        }

        // setSelectAllChecked(isEqual(newChecked.sort(), allOptionsValue.sort()))
        // setCurrentCheckedOptions(newChecked)
        onChangeOptions(newChecked)
    }

    return (
        <>
            <div style={{
                display: 'flex',
                height: isTileDialogSetting ? '' : '30px'
            }}>
                <div>
                    <Typography variant="subtitle2" sx={{ pl: 1, marginTop: '10px' }}>
                        {title}
                    </Typography>
                </div>
                <div style={{
                    marginLeft: 'auto',
                    marginRight: isTileDialogSetting ? '8px' : '0px',
                    marginTop: isTileDialogSetting ? '10px' : '0px'
                }}>
                    <FormControlLabel
                        sx={{
                            marginRight: '34px'
                        }}
                        control={
                            <Tooltip title={`${selectAllChecked ? 'Unselect' : 'Select'} all`} arrow placement="bottom-start" disableInteractive>
                                <Checkbox
                                    edge="end"
                                    checked={selectAllChecked}
                                    tabIndex={-1}
                                    disableRipple
                                    color={'warning'}
                                    inputProps={{
                                        'aria-labelledby': `checkbox-list-label-selectAll`,
                                    }}
                                    size="small"
                                />
                            </Tooltip>
                        }
                        label={isTileDialogSetting ? null :
                            <Typography variant="subtitle2" sx={{ pl: 1 }}>
                                {SELECT_ALL_OPTION.label}
                            </Typography>
                        }
                        labelPlacement='start'
                        onChange={handleSelectAllChecked}
                    />
                </div>

            </div >

            <List
                sx={{
                    width: '100%',
                    maxHeight: 180,
                    overflowY: hideScroll === true ? 'none' : 'scroll',
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    mb: 2,
                }}
                dense
            >
                {optionList?.length === 0 && <Typography px={2}>{t('common:dis.emptyFields')}</Typography>}
                {/* {optionList?.length > 0 &&
                    <ListItem
                        key={SELECT_ALL_OPTION.value}
                        disablePadding
                        sx={{
                            '& .MuiButtonBase-root': {
                                py: '2px',
                            },
                        }}
                    >
                        <ListItemButton
                            role={undefined}
                            onClick={handleSelectAllChecked}
                            dense
                            sx={{
                                '& .MuiListItemIcon-root': {
                                    minWidth: '15px !important',
                                },
                            }}
                        >
                            <ListItemText id={`checkbox-list-label-selectAll`} primary={SELECT_ALL_OPTION.label} />
                            <ListItemIcon>
                                <Checkbox
                                    edge="end"
                                    checked={selectAllChecked}
                                    tabIndex={-1}
                                    disableRipple
                                    color={'warning'}
                                    inputProps={{
                                        'aria-labelledby': `checkbox-list-label-selectAll`,
                                    }}
                                    size="small"
                                />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                } */}
                {optionList?.length > 0 &&
                    optionList.map((option) => {
                        if (option && option?.hide) {
                            return null
                        }

                        const labelId = `checkbox-list-label-${option.value}`
                        const optionValue = getValue(option)

                        return (
                            <ListItem
                                key={optionValue}
                                disablePadding
                                sx={{
                                    '& .MuiButtonBase-root': {
                                        py: '2px',
                                    },
                                }}
                            >
                                <ListItemButton
                                    role={undefined}
                                    onClick={handleOtherOptionsCheck(optionValue)}
                                    dense
                                    sx={{
                                        '& .MuiListItemIcon-root': {
                                            minWidth: '15px !important',
                                        },
                                    }}
                                >
                                    <ListItemText id={labelId} primary={getLabel(option)} />
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="end"
                                            checked={currentCheckedOptions?.indexOf(optionValue) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            color={'warning'}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                            size="small"
                                        />
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
            </List >
        </>
    )
}

export default OperatorCheckboxList
