import React, { useState } from 'react'
import { DialogContent, DialogContentText, DialogActions, Button, Dialog } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
}))

const RecoverAccount = (props) => {
	const classes = useStyles()
	const [t] = useTranslation('common')
	const [value, setValue] = useState(null)
	const { open, loading, onClose, onConfirmed } = props

	const handleChange = (event) => {
		setValue(event.target.value)
	}

	const handleClose = () => {
		onClose()
	}

	const handleConfirm = (event) => {
		event.preventDefault()
		event.target.username = value
		onConfirmed(event)
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			className={classes.root}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{t('common:signIn.forgotPasswordMessage')}
				</DialogContentText>
				<TextField
					value={value}
					onChange={handleChange}
					margin="normal"
					required
					fullWidth
					name="username"
					label={t('common:signIn.username')}
					type="username"
					id="username"
				/>
			</DialogContent>
			<DialogActions>
				<LoadingButton onClick={handleConfirm} loading={loading} disabled={!value} autoFocus>
					{t('common:signIn.reset')}
				</LoadingButton>
				<Button onClick={handleClose}>{t('common:signIn.cancel')}</Button>
			</DialogActions>
		</Dialog>
	)
}

export default RecoverAccount
