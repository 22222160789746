import axios from 'axios'
import qs from 'qs';
import { API } from '../../../config'
import { apiUrl, originUrl } from '../../../apis/apiUrl'

const url = apiUrl()
const origin = originUrl()

const CREATE_PDF_BY_CUSTOM_REPORT_TEMLATE = "create_pdf_by_excel_template";

export const deleteRecord = async (record, token) => {
	const newUrl = record.sourceType === 'DISPATCH' ? `${url}/dispatches` : `${url}/submissions`
	const recordKey =
		record.sourceType === 'SUBMISSION' || record.sourceType === 'ACTIVITY'
			? record.submissionKey
			: record.dispatchKey

	const config = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}

	return await axios.delete(`${newUrl}/${recordKey}`, config)
}

export const viewRecord = async (record, token, formSelected, user) => {
	const data = {
		Form_Record: "",
		formId: formSelected?.key,
		formName: formSelected?.name,
		isIERequest: "false",
		printRowId: record.type === 'SUBMISSION' || record.type === 'ACTIVITY'
			? record.submissionKey
			: record.dispatchKey,
		printRowNum: "1",
		projectId: formSelected?.projectKey,
		strListInvoiceTableDataNames: "",
		userKey: user?.userKey,
		recordKey:
			record.type === 'SUBMISSION' || record.type === 'ACTIVITY'
				? record.submissionKey
				: record.dispatchKey,
	}

	const jsonString = JSON.stringify(data);

	return axios({
		url: `${origin}/dataview/print`,
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${token}`,
		},
		data: jsonString,
		cache: false,
		responseType: 'json',
	});
	// return await axios.post(`${origin}/dataview/print`, jsonString, config);
}

export const getRecordHistory = async (record, token) => {
	const typeUrl = record.sourceType === 'SUBMISSION' ? `${url}/submissions` : `${url}/dispatches`
	const recordKey =
		record.sourceType === 'SUBMISSION' || record.sourceType === 'ACTIVITY'
			? record.submissionKey
			: record.dispatchKey
	const config = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}

	return await axios.get(`${typeUrl}/${recordKey}/history`, config)
}

function getPDFSettings(response, formSelected, user, record) {
	if (response.excel_template_key !== undefined && response.excel_template_key !== ''
		//&& response.using_template_in_email_report === USING_TEMPLATE_EXCEL) 
		&& response.templateUseWhenCreatePdf == CREATE_PDF_BY_CUSTOM_REPORT_TEMLATE) {
		// We are in PDF Report for Excel Template (convert2pdf)
		return {
			isClickMenuPdf: true,
			isExcelTemplate: true,
		}
	}

	return null;
}

export const getPrintPreview = (data, token) => {
	return `${origin}/html/PrintPreview2.html?cacheId=${data.cacheId}&taskInfoKey=${data.taskInfoKey}&token=${token}`
}

export const getTempToken = async (token) => {
	const config = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}
	const bodyParameters = {
		token: token,
	}
	return await axios.post(`${url}/tokens/temp`, bodyParameters, config)
}

export const getSubmissionRecordImageBlobs = async (record, token) => {
	if (record?.type !== 'SUBMISSION' && record?.type !== 'ACTIVITY') return null
	const config = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}

	return await axios.get(`${url}/submissions/${record.submissionKey}/blobs`, config)
}

export const getSubmissionRecordImageData = async (record, blob, token) => {
	// if ((record?.type !== 'SUBMISSION' && record?.type !== 'ACTIVITY') || !blob.key) return null
	const config = {
		headers: {
			'Content-Type': 'application/octet-stream',
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob',
	}

	return await axios.get(
		`${url}/submissions/${record.submissionKey}/blobs/${blob.key}/binary`,
		config
	)
}

export const getSubmissionRecordImageViewerData = async (record, token) => {
	const config = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}

	return await axios.get(`${url}/submissions/${record.submissionKey}/viewers`, config)
}

export const getSubmissionRecordData = async (record, token) => {
	if (record?.type !== 'SUBMISSION' && record?.type !== 'ACTIVITY') return null
	const config = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}

	return await axios.get(`${url}/submissions/${record.submissionKey}/record`, config)
}

export const executePostDispatchLink = async (link, token) => {
	const config = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}

	const bodyParameters = {}

	return await axios.post(link, bodyParameters, config)
}

export const saveFilePDF = (responseData, token) => {
	const data = qs.stringify({
		action: 'save_file',
		taskInfoKey: responseData.taskInfoKey,
		...getPDFSettings(responseData),
	})



	return axios({
		url: `${API}/PrintRPHandlerServlet`,
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${token}`,
		},
		data,
		cache: false,
		responseType: 'json',
	});
}

export const checkFinishTask = async (taskInfoKey, token) => {
	const data = qs.stringify({
		action: 'check_finish_task',
		taskInfoKey
	})

	return axios({
		url: `${API}/PrintRPHandlerServlet`,
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${token}`,
		},
		data,
		cache: false,
		responseType: 'json',
	});
}

export const processDownloadPDF = async (taskInfoKey, token) => {
	return axios({
		url: `${API}/PrintRPHandlerServlet?action=download&taskInfoKey=${taskInfoKey}`,
		method: 'Get',
		headers: {
			'Authorization': `Bearer ${token}`,
		},
		cache: false,
		responseType: 'arraybuffer'
	});
}
