import { Dashboard, Save, AutoFixHigh, Theaters } from '@mui/icons-material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import mapApi from 'apis/disApi/mapApi'
import doFormMapApi from 'apis/disApi/doFormMapApi'
import { generateRandomID } from 'components/pages/dis/helpers'
import useView from 'components/pages/dis/hooks/useView'
import ChartForm from 'components/pages/dis/tile/chart/ChartForm'
import ImageViewerForm from 'components/pages/dis/tile/image-viewer/ImageViewerForm'
import BrowserForm from 'components/pages/dis/tile/browser/BrowserForm'
import MobileUnitsForm from 'components/pages/dis/tile/mobile-unit/MobileUnitsForm'
import FeedForm from 'components/pages/dis/tile/feed/FeedForm'
import DoformsMapForm from 'components/pages/dis/tile/doFormsMap/DoformsMapForm'
import TextSearchForm from 'components/pages/dis/tile/text-search/TextSearchForm'
import DoFormsForm from 'components/pages/dis/tile/doforms-form/DoformsForm'
import MapForm from 'components/pages/dis/tile/map/MapForm'
import PageForm from 'components/pages/dis/tile/page/PageForm'
import MasterDateTimeForm from 'components/pages/dis/tile/master-datetime/MasterDateTimeForm'
import AppButton from 'custom-components/AppButton'
import RouteLeavingGuard from 'custom-components/RouteLeavingGuard'
import produce from 'immer'
import { isEmpty, isEqual, differenceWith } from 'lodash'
import { useCallback, useEffect, useMemo, useReducer, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getMapLoggedInInfoFromStorage, isJson, logErrorMessage } from 'utils/functions/helpers'
import { useSearchParams } from 'utils/hooks/useSearchParams'
import { TYPES_OF_TILE } from '../../../../constants'
import { DEFAULT_CHECKED_OPTIONS } from '../_components/OtherOptions'
import { getNewEmptyPosition, parseRect } from '../menu/_utils/common'
import ButtonWithConfirm from './_components/ButtonWithConfirm'
import MemorizedDatagridSettings from './_components/DatagridSettings'
import DialogOfTilePosition from './_components/DialogOfTilePosition'
import { makeStyles } from '@mui/styles'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'
import { LoadingButton } from '@mui/lab'
import { useDispatch, useSelector } from 'react-redux'
import { ENV_ACTIONS } from '../../../../reducers/environmentReducer'
import { validateDateRange } from '../../dis/tile/doFormsMap/DoformsMapFilters'
import { validURL } from '../../dis/tile/utils/helpers'
import { useMenu } from '../contexts/MenuContext'

const DEFAULT_TYPE_TILE = TYPES_OF_TILE.DATA_GRID

const DISPLAY_COMPONENT_STATUS_TYPE = {
	OK_CANCEL: 'okCancel',
	RETURN_MANAGER: 'returnManager',
}

const useStyles = makeStyles(() => ({
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

function displayComponentStatusReducer(state, action) {
	switch (action.type) {
		case DISPLAY_COMPONENT_STATUS_TYPE.RETURN_MANAGER:
			return {
				...state,
				returnManagerComponent: action.value,
				okCancelComponent: !action.value,
			}
		case DISPLAY_COMPONENT_STATUS_TYPE.OK_CANCEL:
			return {
				...state,
				okCancelComponent: action.value,
				returnManagerComponent: !action.value,
			}
		default:
			return {
				returnManagerComponent: true,
				okCancelComponent: false,
			}
	}
}

function isArrayEqual(x, y) {
	return isEmpty(differenceWith(x, y, isEqual))
}

const TYPE_TILE_MAP = [
	{
		label: 'Datagrid',
		value: TYPES_OF_TILE.DATA_GRID,
	},
	{
		label: 'Geotab map',
		value: TYPES_OF_TILE.GEO_MAP,
	},
	{
		label: 'Page',
		value: TYPES_OF_TILE.PAGE,
	},
	{
		label: 'Chart',
		value: TYPES_OF_TILE.CHART,
	},
	{
		label: 'Form list',
		value: TYPES_OF_TILE.FORM_LIST,
	},
	{
		label: 'Image Viewer',
		value: TYPES_OF_TILE.IMAGE_VIEWER,
	},
	{
		label: 'Mobile units',
		value: TYPES_OF_TILE.MOBILE_UNITS,
	},
	{
		label: 'Feed',
		value: TYPES_OF_TILE.FEED,
	},
	{
		label: 'Doforms Map',
		value: TYPES_OF_TILE.DOFORMS_MAP,
	},
	{
		label: 'Browser',
		value: TYPES_OF_TILE.BROWSER,
	},
	{
		label: 'Text Search',
		value: TYPES_OF_TILE.TEXT_SEARCH,
	},
	{
		label: 'Doforms Form',
		value: TYPES_OF_TILE.DO_FORMS_FORM,
	},
	{
		label: 'Blank',
		value: TYPES_OF_TILE.BLANK,
	},
	{
		label: 'Master DateTime',
		value: TYPES_OF_TILE.MASTER_DATETIME,
	},
]

export const vehicleListKey = (tileKey, userInfo) => ['vehicle-list', tileKey, userInfo]

const TileContent = ({
	draftMenuItems,
	setDraftMenuItems,
	parentMenuItem,
	dashboardKeyList,
	tileList,
	onSubmitTile,
}) => {
	const { t } = useTranslation('common')
	const environment = useSelector((state) => state.environment)
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const dispatch = useDispatch()

	const {
		key: dashboardKey = '',
		isSubmenuItems = '',
		tile: tileKeyInUrl = '',
		isCreatingTile: _isCreatingTile = '',
		isCreatingMenu: _isCreatingMenu = '',
	} = useSearchParams()
	const isSubMenu = useMemo(() => isSubmenuItems === 'true', [isSubmenuItems])
	const isCreatingTile = useMemo(() => _isCreatingTile === 'true', [_isCreatingTile])
	const isCreatingMenu = useMemo(() => _isCreatingMenu === 'true', [_isCreatingMenu])

	const [displayComponentStatus, displayComponentStatusDispatch] = useReducer(
		displayComponentStatusReducer,
		{
			returnManagerComponent: false,
			okCancelComponent: false,
		}
	)

	const [filterData, setFilterData] = useState({})

	const {
		mapTileInfo: { linkedFields: mapTileLinkedFields = {}, viewKey: viewKeySetting = '' } = {},

		linkedFields = {},
	} = filterData

	const [openDynamicDialog, setOpenDynamicDialog] = useState(false)
	const [tilePositionList, setTilePositionList] = useState([])
	const [copiedMenuItems, setCopiedMenuItems] = useState(draftMenuItems)

	const [isCancel, setIsCancel] = useState(false)

	// data for View wizard
	const [columnList, setColumnList] = useState([])
	const [loadingViewData, setLoadingViewData] = useState(false)

	const initTilePositionList = useMemo(() => {
		return (tileList || [])?.map((item) => {
			return parseRect(item)
		})
	}, [tileList])

	const includeFormScreen = Boolean(filterData?.key) || isCreatingTile

	const compareMenuItems = useMemo(() => {
		// loop through the menu items and convert settings in each tileInfo to object
		const formattedMenuItems = produce(draftMenuItems, (draft) => {
			draft.forEach((item) => {
				if (item?.tileInfo) {
					item.tileInfo.forEach((tile) => {
						if (tile?.settings && isJson(tile?.settings)) {
							const parsedSettings = JSON.parse(tile.settings)

							tile.settings = parsedSettings
						}
					})
				}
			})
		})
		return formattedMenuItems
	}, [draftMenuItems])

	const compareCopiedMenuItems = useMemo(() => {
		// loop through the menu items and convert settings in each tileInfo to object
		const formattedMenuItems = produce(copiedMenuItems, (draft) => {
			draft.forEach((item) => {
				if (item?.tileInfo) {
					item.tileInfo.forEach((tile) => {
						if (tile?.settings && isJson(tile?.settings)) {
							const parsedSettings = JSON.parse(tile.settings)

							tile.settings = parsedSettings
						}
					})
				}
			})
		})
		return formattedMenuItems
	}, [copiedMenuItems])

	const hasBeenModifiedSubMenus = useMemo(() => {
		return !isArrayEqual(
			compareMenuItems.map((item) => item.items),
			compareCopiedMenuItems.map((item) => item.items)
		)
	}, [compareMenuItems, compareCopiedMenuItems])

	const hasBeenModifiedMenus = useMemo(
		() =>
			!isEqual(
				compareMenuItems.map((item) => item.tileInfo),
				compareCopiedMenuItems.map((item) => item.tileInfo)
			),
		[compareMenuItems, compareCopiedMenuItems]
	)

	const hasBeenModifiedTilePosition = useMemo(() => {
		return !isArrayEqual(initTilePositionList, tilePositionList)
	}, [initTilePositionList, tilePositionList])

	const hasBeenModified = useMemo(
		() =>
			hasBeenModifiedTilePosition || (isSubMenu ? hasBeenModifiedSubMenus : hasBeenModifiedMenus),
		[isSubMenu, hasBeenModifiedSubMenus, hasBeenModifiedMenus, hasBeenModifiedTilePosition]
	)

	useEffect(() => {
		return () => {
			setIsCancel(false)
		}
	}, [])

	useEffect(() => {
		if (hasBeenModified || isCreatingTile) {
			displayComponentStatusDispatch({
				type: DISPLAY_COMPONENT_STATUS_TYPE.OK_CANCEL,
				value: true,
			})
			return
		}

		displayComponentStatusDispatch({
			type: DISPLAY_COMPONENT_STATUS_TYPE.RETURN_MANAGER,
			value: true,
		})
	}, [isCreatingTile, hasBeenModified, includeFormScreen])

	// const showReturnToMenuManagerBtn = (hasBeenModified && !isCreatingTile) || !includeFormScreen

	const dataGridTile = useMemo(
		() =>
			tileList?.length > 0
				? tileList
						?.filter((tile) => tile?.type === TYPES_OF_TILE.DATA_GRID)
						?.map((tile) => ({ ...tile, is_creating: true }))
				: [],
		[tileList]
	)

	const mobileUnitTile = useMemo(
		() =>
			tileList?.length > 0
				? tileList
						?.filter((tile) => tile?.type === TYPES_OF_TILE.MOBILE_UNITS)
						?.map((tile) => ({ ...tile, is_creating: true }))
				: [],
		[tileList]
	)

	const location = useLocation()
	const history = useHistory()

	const { detailMenu } = useMenu()
	const { views } = useView({ selectedWebGroupKey: detailMenu?.groupKey })

	// get user info from storage
	const userInfo = getMapLoggedInInfoFromStorage()

	const {
		data: vehicleList,
		isLoading,
		isFetching,
	} = useQuery({
		queryKey: vehicleListKey(userInfo),
		queryFn: async () => {
			const response = await mapApi.getAllDevices(userInfo)
			// Trim name to remove white spaces because API returns some names with white spaces
			return response?.data?.map((item) => ({ ...item, name: item.name.trim() })) ?? []
		},
		enabled: filterData?.type === TYPES_OF_TILE.GEO_MAP && Boolean(userInfo),
	})

	const vehicleLoading = isFetching || isLoading

	useEffect(() => {
		// for case delete tile
		setCopiedMenuItems(draftMenuItems)
	}, [draftMenuItems])

	useEffect(() => {
		// reset data
		if (isCreatingTile) {
			// use _id to uniq key for ['map-tile', viewKey, uniqMapKey] query key
			setFilterData({
				_id: crypto.randomUUID(),
				type: DEFAULT_TYPE_TILE,
			})
			setColumnList([])
		} else {
			setFilterData({})
			setCopiedMenuItems(draftMenuItems)
		}
	}, [dashboardKey, tileKeyInUrl, isCreatingTile])

	useEffect(() => {
		try {
			const nextTilePositionList = (tileList || [])?.map((item) => {
				return parseRect(item)
				// const itemTiltPosition = parseRect(item)
				// return {
				// 	...itemTiltPosition,
				// 	settings: item?.settings || {},
				// }
			})
			setTilePositionList(nextTilePositionList)
		} catch (error) {
			logErrorMessage(error)
		}
	}, [tileList])

	useEffect(() => {
		try {
			if (!tileKeyInUrl) return

			const selectedTile = tileList?.find((item) => item?.key === tileKeyInUrl)

			if (!selectedTile) return

			const { settings, primaryKeyList, ...others } = selectedTile
			const formattedSettings = settings && isJson(settings) ? JSON.parse(settings ?? '{}') : {}

			if (selectedTile) {
				const newFilterData = { ...others, ...formattedSettings }
				setFilterData(newFilterData)

				if (!isEmpty(primaryKeyList)) {
					handleFilterDataChange('primaryKeyList', primaryKeyList)
				}
			}
		} catch (error) {
			logErrorMessage(error)
		}
	}, [tileKeyInUrl, tileList])

	const isTileNameDuplicate = (newTile, existingTiles) => {
		const filteredTiles = existingTiles?.filter((tile) => tile?.key !== newTile?.key)
		const duplicateTile = filteredTiles?.find((tile) => tile.name === newTile.name)

		if (duplicateTile) {
			toast.error(t('common:toast.tileNameDuplicate'))
			return true
		}

		return false
	}

	const handleTilePositionChange = (newPositionList) => {
		setTilePositionList(newPositionList)
	}

	const handleClickOpenDialog = () => {
		if (filterData.name) {
			if (isTileNameDuplicate(filterData, tileList)) return
			setOpenDynamicDialog(true)
		} else {
			toast.error(t('common:toast.pleaseFillData'))
		}
	}

	const handleCloseDialog = () => {
		setOpenDynamicDialog(false)
	}

	function generateNewPosition(tileInfoList, tilePositionList) {
		return tileInfoList.map((tile, index) => {
			const currentTilePosition = tilePositionList[index]

			let newPosition

			if (!currentTilePosition) {
				const { x, y } = getNewEmptyPosition(tilePositionList)
				newPosition = `{${x}, ${y}, 1, 1}`
			} else {
				const { x, y, w, h } = currentTilePosition
				newPosition = `{${x}, ${y}, ${w}, ${h}}`
			}

			return {
				...tile,
				position: newPosition,
			}
		})
	}

	const processSaveTile = () => {
		try {
			// Destructure fieldsData to get type, name, and position
			const {
				type = DEFAULT_TYPE_TILE,
				name,
				columnsChecked = [],
				otherOptionsChecked = DEFAULT_CHECKED_OPTIONS,
				selectedMenuPath,

				// data for chart type
				linkedFields,
				viewKey,
				formKey,
				projectKey,
				viewData,

				// data for map type
				locationData,
				showFilters,
				showHistory,
				pointOfInterest,
				repeatReloadMapSeconds,

				// data for image viewer type
				imageType,
				connectedDatagridKey,
				connectedNameWithNewDatagrid,
				defaultUrl,
				defaultProjectForm,
				isReload,
				columnName,

				// master datetime tile
				checkedPrimaryKeys,
				dateTimeOperators,
				textOperators,
			} = filterData

			const chartConfig = viewData?.chart

			const currentView = views.find((view) => view.key === viewKey)

			const newSettings = {
				projectKey: currentView?.projectKey,
				formKey: currentView?.formKey,
				viewKey,
				columnsChecked,
				checkedPrimaryKeys,
				dateTimeOperators,
				textOperators,
				otherOptionsChecked,
				linkedFields,
				selectedMenuPath,
				mapTileInfo: {
					viewKey,
					formKey,
					projectKey,
					linkedFields,
				},

				showFilters: showFilters,
				showHistory: showHistory,
				pointOfInterest: pointOfInterest,
				repeatReloadMapSeconds,
				locationData: {
					...locationData,
					...locationData,
				},

				imageType,
				defaultUrl,
				defaultProjectForm,
				isReload,
				columnName,
				connectedDatagridKey,
			}

			const newTileInfo = {
				key: filterData?.key ?? generateRandomID(),
				name,
				type,
				settings: JSON.stringify(newSettings),
				primaryKeyList: filterData?.primaryKeyList ?? [],
				bgColorRowCondition: filterData?.bgColorRowCondition ?? {},
				chart: chartConfig,
				connectedNameWithNewDatagrid,
			}

			const updatedMenuItem = produce(parentMenuItem, (draft) => {
				if (isSubMenu) {
					// Find the index of the item in the parentMenuItem
					const editedMenuIndex = draft?.items?.findIndex(
						(item) => item?.dashboard?.key === dashboardKey
					)

					if (editedMenuIndex !== -1) {
						// Update the tileInfo of the item in the parentMenuItem
						if (isCreatingTile) {
							const creatingTileInfo = {
								...newTileInfo,
								is_creating: true,
							}

							if (!draft.items[editedMenuIndex].tileInfo) {
								draft.items[editedMenuIndex].tileInfo = [creatingTileInfo]
							} else {
								draft.items[editedMenuIndex].tileInfo.push(creatingTileInfo)
							}
						} else {
							const newTileInfoList = [...tileList].map((tile) => {
								if (tile.key === tileKeyInUrl) {
									if (tile.is_creating) {
										newTileInfo['is_creating'] = tile.is_creating
									}

									return newTileInfo
								}
								return tile
							})

							draft.items[editedMenuIndex].tileInfo = newTileInfoList
						}

						// Additional position for the new tile
						const tileWithPosition = generateNewPosition(
							draft.items[editedMenuIndex].tileInfo,
							tilePositionList
						)
						draft.items[editedMenuIndex].tileInfo = tileWithPosition
					}
				} else {
					// Update the tileInfo of the parentMenuItem
					if (isCreatingTile) {
						const creatingTileInfo = {
							...newTileInfo,
							is_creating: true,
						}

						if (!draft.tileInfo) {
							draft.tileInfo = [creatingTileInfo]
						} else {
							draft.tileInfo.push(creatingTileInfo)
						}
					} else {
						const newTileInfoList = [...tileList].map((tile) => {
							if (tile.key === tileKeyInUrl) {
								if (tile.is_creating) {
									newTileInfo['is_creating'] = tile.is_creating
								}

								return newTileInfo
							}
							return tile
						})

						draft.tileInfo = newTileInfoList
					}

					// Additional position for the new tile
					const tileWithPosition = generateNewPosition(draft.tileInfo, tilePositionList)
					draft.tileInfo = tileWithPosition
				}
			})

			const newDetailMenuItems = draftMenuItems.map((item) => {
				if (item?.dashboard?.key === updatedMenuItem?.dashboard?.key) {
					return updatedMenuItem
				} else {
					return item
				}
			})

			// Update the menu state and UI
			setDraftMenuItems(newDetailMenuItems)
			setCopiedMenuItems(newDetailMenuItems)

			// Show success toast
			toast.success('Save successfully')

			return newDetailMenuItems
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const validationTile = () => {
		const showErrorToast = () => {
			toast.error(t('common:toast.pleaseFillData'))
			return true
		}
		const showInvalidToast = () => {
			toast.error(t('common:dis.urlInvalid'))
			return true
		}

		if (isTileNameDuplicate(filterData, tileList)) {
			return true
		}

		if (!filterData?.name) {
			return showErrorToast()
		}

		if (
			[TYPES_OF_TILE.CHART, TYPES_OF_TILE.DATA_GRID].includes(filterData?.type) ||
			!filterData?.type // Default type is datagrid
		) {
			if (!filterData?.viewKey) {
				return showErrorToast()
			}
		}

		if ([TYPES_OF_TILE.PAGE].includes(filterData?.type)) {
			if (!filterData?.selectedMenuPath) {
				return showErrorToast()
			}
		}

		if ([TYPES_OF_TILE.GEO_MAP].includes(filterData?.type)) {
			if (!filterData?.locationData?.vehicleName) {
				return showErrorToast()
			}
		}

		if ([TYPES_OF_TILE.IMAGE_VIEWER].includes(filterData?.type)) {
			if (isCreatingMenu) {
				if (!filterData?.imageType) {
					return showErrorToast()
				}
			} else {
				if (!filterData?.imageType || !filterData?.connectedDatagridKey) {
					return showErrorToast()
				}
			}
		}

		if ([TYPES_OF_TILE.DOFORMS_MAP].includes(filterData?.type)) {
			if (!filterData?.locationData?.fromDate || !filterData?.locationData?.toDate) {
				return showErrorToast()
			}

			const errorMessage = validateDateRange(
				filterData?.locationData?.fromDate,
				filterData?.locationData?.toDate
			)
			if (!isEmpty(errorMessage)) {
				toast.error(errorMessage)
				return true
			}
		}

		if ([TYPES_OF_TILE.BROWSER].includes(filterData?.type)) {
			if (!validURL(filterData?.defaultUrl, true)) {
				return showInvalidToast()
			}
		}

		if ([TYPES_OF_TILE.DO_FORMS_FORM].includes(filterData?.type)) {
			if (
				!(filterData?.defaultProjectForm?.projectKey || filterData?.defaultProjectForm?.formKey)
			) {
				return showErrorToast()
			}
		}

		if ([TYPES_OF_TILE.MASTER_DATETIME].includes(filterData?.type)) {
			if (isEmpty(filterData?.primaryKeyList)) {
				return showErrorToast()
			}
			if (isEmpty(filterData?.checkedPrimaryKeys)) {
				toast.error('Please show at least one key')
				return true
			}
			if (
				filterData?.checkedPrimaryKeys.includes('Master_DateTime') &&
				isEmpty(filterData?.dateTimeOperators)
			) {
				toast.error('Master DateTime operators is empty')
				return true
			}
			if (
				filterData?.checkedPrimaryKeys.includes('Master_Text') &&
				isEmpty(filterData?.textOperators)
			) {
				toast.error('Master Text operators is empty')
				return true
			}
		}

		return false
	}

	const handleSaveTile = () => {
		if (validationTile()) return

		processSaveTile()

		// Reset data
		setFilterData({})

		const searchParams = new URLSearchParams(location.search)
		searchParams.delete('isCreatingTile')

		history.push({
			pathname: location.pathname,
			search: searchParams.toString(),
		})
	}

	const handleSaveAndClose = () => {
		if (!isEmpty(draftMenuItems)) {
			onSubmitTile?.(draftMenuItems)
		}
	}

	const handleCancel = () => {
		setIsCancel(true)
		handleSaveAndClose()
	}

	const handleFilterDataChange = (id, value) => {
		const orderedValue = Array.isArray(value) ? [...value].sort() : value
		setFilterData((prevFilterData) => ({ ...prevFilterData, [id]: orderedValue }))
		if (id !== undefined && value !== undefined) {
			let currentTileList

			if (isSubMenu) {
				const parentMenuItem = copiedMenuItems.find((item) =>
					item?.items?.find((subItem) => subItem?.dashboard?.key === dashboardKey)
				)

				currentTileList = parentMenuItem?.items.find(
					(subItem) => subItem?.dashboard?.key === dashboardKey
				)?.tileInfo
			} else {
				currentTileList = copiedMenuItems.find(
					(item) => item?.dashboard?.key === dashboardKey
				)?.tileInfo
			}

			if (!currentTileList) return
			const updatedTileList = produce(currentTileList, (draft) => {
				draft.forEach((tile) => {
					if (tile.key === filterData?.key) {
						const isTilePropertyExist = tile[id]

						if (isTilePropertyExist) {
							tile[id] = orderedValue
						} else {
							const tileSettings = JSON.parse(tile.settings ?? '{}')

							const updatedSettings = produce(tileSettings, (draft) => {
								draft[id] = orderedValue
							})
							tile.settings = JSON.stringify(updatedSettings)
						}
					}
				})
			})

			const draftCopiedMenuItems = produce(copiedMenuItems, (draft) => {
				draft.forEach((item) => {
					if (isSubMenu) {
						item.items.forEach((subItem) => {
							if (subItem?.dashboard?.key === dashboardKey) {
								subItem.tileInfo = subItem.tileInfo.map((x) => {
									if (x?.key === tileKeyInUrl) {
										return updatedTileList.find((item) => item?.key === tileKeyInUrl)
									}
									return x
								})
							}
						})
					} else if (item?.dashboard?.key === dashboardKey) {
						item.tileInfo = item.tileInfo.map((x) => {
							if (x?.key === tileKeyInUrl) {
								return updatedTileList.find((item) => item?.key === tileKeyInUrl)
							}
							return x
						})
					}
				})
			})
			setCopiedMenuItems(draftCopiedMenuItems)
		}
	}

	const renderContent = useCallback(() => {
		switch (filterData?.type) {
			case TYPES_OF_TILE.DATA_GRID:
				return (
					<MemorizedDatagridSettings
						dashboardKeyList={dashboardKeyList}
						filterData={filterData}
						onFilterDataChange={(id, value) => {
							if (filterData?.type === TYPES_OF_TILE.DATA_GRID) {
								handleFilterDataChange(id, value)
							}
						}}
						setColumnList={setColumnList}
						setLoadingViewData={setLoadingViewData}
					/>
				)
			case TYPES_OF_TILE.CHART:
				return (
					<ChartForm
						outlinedInput
						dashboardKey={dashboardKey}
						tileKey={filterData?.key}
						dashboardKeyList={dashboardKeyList}
						linkedFields={mapTileLinkedFields}
						viewKeySetting={viewKeySetting}
						filterData={filterData}
						onFilterDataChange={(id, value) => {
							if (filterData?.type === TYPES_OF_TILE.CHART) {
								handleFilterDataChange(id, value)
							}
						}}
						enableLoadView={true}
					/>
				)
			case TYPES_OF_TILE.PAGE:
				return (
					<PageForm
						outlinedInput
						currentPath={filterData?.selectedMenuPath ?? ''}
						onChangeCurrentPath={(path) => handleFilterDataChange('selectedMenuPath', path)}
					/>
				)
			case TYPES_OF_TILE.GEO_MAP:
				return (
					<Stack spacing={2} sx={{ width: '50%' }}>
						<MapForm
							vehicleList={vehicleList}
							vehicleLoading={vehicleLoading}
							filterData={filterData}
							linkedFields={linkedFields}
							dashboardKeyList={dashboardKeyList}
							onFilterDataChange={(id, value) => {
								if (filterData?.type === TYPES_OF_TILE.GEO_MAP) {
									handleFilterDataChange(id, value)
								}
							}}
						/>
					</Stack>
				)
			case TYPES_OF_TILE.IMAGE_VIEWER:
				return (
					<Box spacing={2} sx={{ width: '50%' }}>
						<ImageViewerForm
							dashboardKey={dashboardKey}
							filterData={filterData}
							datagridListWhenCreating={dataGridTile}
							enabledFetchTileList={!isCreatingMenu}
							onFilterDataChange={handleFilterDataChange}
						/>
					</Box>
				)
			case TYPES_OF_TILE.MOBILE_UNITS:
				return (
					<Box spacing={2} sx={{ width: '50%' }}>
						<MobileUnitsForm
							dashboardKey={dashboardKey}
							filterData={filterData}
							datagridListWhenCreating={dataGridTile}
							enabledFetchTileList={!isCreatingMenu}
							onFilterDataChange={handleFilterDataChange}
						/>
					</Box>
				)
			case TYPES_OF_TILE.FEED:
				return (
					<Box spacing={2} sx={{ width: '50%' }}>
						<FeedForm
							dashboardKey={dashboardKey}
							filterData={filterData}
							mobileUnitListWhenCreating={mobileUnitTile}
							enabledFetchTileList={!isCreatingMenu}
							onFilterDataChange={handleFilterDataChange}
						/>
					</Box>
				)
			case TYPES_OF_TILE.DOFORMS_MAP:
				return (
					<Stack spacing={2} sx={{ width: '50%' }}>
						<DoformsMapForm
							filterData={filterData}
							linkedFields={linkedFields}
							dashboardKeyList={dashboardKeyList}
							onFilterDataChange={(id, value) => {
								if (filterData?.type === TYPES_OF_TILE.DOFORMS_MAP) {
									handleFilterDataChange(id, value)
								}
							}}
							dashboardKey={dashboardKey}
							mobileUnitListWhenCreating={mobileUnitTile}
							enabledFetchTileList={!isCreatingMenu}
						/>
					</Stack>
				)

			case TYPES_OF_TILE.BROWSER:
				return (
					<Box spacing={2} sx={{ width: '50%' }}>
						<BrowserForm
							dashboardKey={dashboardKey}
							filterData={filterData}
							datagridListWhenCreating={dataGridTile}
							enabledFetchTileList={!isCreatingMenu}
							onFilterDataChange={handleFilterDataChange}
						/>
					</Box>
				)

			case TYPES_OF_TILE.TEXT_SEARCH:
				return (
					<Box spacing={2} sx={{ width: '50%' }}>
						<TextSearchForm
							dashboardKey={dashboardKey}
							filterData={filterData}
							datagridListWhenCreating={dataGridTile}
							enabledFetchTileList={!isCreatingMenu}
							onFilterDataChange={handleFilterDataChange}
						/>
					</Box>
				)
			case TYPES_OF_TILE.DO_FORMS_FORM:
				return (
					<Box spacing={2}>
						<DoFormsForm
							dashboardKey={dashboardKey}
							filterData={filterData}
							datagridListWhenCreating={dataGridTile}
							enabledFetchTileList={!isCreatingMenu}
							//onFilterDataChange={handleFilterDataChange}
							onFilterDataChange={(id, value) => {
								if (filterData?.type === TYPES_OF_TILE.DO_FORMS_FORM) {
									handleFilterDataChange(id, value)
								}
							}}
						/>
					</Box>
				)
			case TYPES_OF_TILE.MASTER_DATETIME:
				return (
					<Box spacing={2} sx={{ width: '50%' }}>
						<MasterDateTimeForm
							filterData={filterData}
							onFilterDataChange={handleFilterDataChange}
						/>
					</Box>
				)
			case TYPES_OF_TILE.BLANK:
				return null
			default:
				return (
					<MemorizedDatagridSettings
						dashboardKeyList={dashboardKeyList}
						filterData={filterData}
						onFilterDataChange={(id, value) => {
							// Check if the type is not set because the default type is datagrid
							if (filterData?.type === TYPES_OF_TILE.DATA_GRID || !filterData?.type) {
								handleFilterDataChange(id, value)
							}
						}}
						setColumnList={setColumnList}
						setLoadingViewData={setLoadingViewData}
					/>
				)
		}
	}, [
		dashboardKey,
		dashboardKeyList,
		filterData,
		linkedFields,
		mapTileLinkedFields,
		vehicleList,
		vehicleLoading,
		viewKeySetting,
	])

	return (
		<Box sx={{ p: 2 }}>
			<RouteLeavingGuard
				when={!isCancel && hasBeenModified}
				navigate={(path) => {
					history.push(path)
				}}
				shouldBlockNavigation={(nextLocation) => {
					if (hasBeenModified) {
						const isChangeTile = !nextLocation.search.includes(tileKeyInUrl)
						if (isChangeTile) return true

						const comebackMenuDashboard =
							!nextLocation.pathname.includes('menu-items') &&
							nextLocation.pathname === '/dis/menu-manager/form' &&
							nextLocation.search.includes('menu')

						const isOnTileDashboard = nextLocation.pathname.includes('menu-items')
						if (comebackMenuDashboard || isOnTileDashboard) {
							return false
						}

						return true
					}

					return false
				}}
				positiveLabel={t('common:misc.ok')}
				positiveAction={() => setCopiedMenuItems(draftMenuItems)}
				negativeLabel={t('common:misc.cancel')}
				content="Attention: You have unsaved changes. Please save your work to avoid losing your changes."
			/>

			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					position: 'sticky',
					top: 0,
					backgroundColor: '#fff',
					zIndex: 100,
					pb: 2,
				}}
			>
				<Stack gap={1} alignItems="center">
					{displayComponentStatus.returnManagerComponent && (
						<AppButton
							variant="text"
							size="small"
							startIcon={<KeyboardBackspaceIcon />}
							onClick={handleSaveAndClose}
						>
							{t('common:dis.returnToMenuManager')}
						</AppButton>
					)}

					{displayComponentStatus.okCancelComponent && (
						<Stack direction="row" spacing={1}>
							<AppButton
								size="small"
								startIcon={<Save />}
								disabled={!includeFormScreen}
								onClick={handleSaveTile}
							>
								{t('common:misc.ok')}
							</AppButton>

							<ButtonWithConfirm
								title={t('common:misc.confirmCancelAdjustTile')}
								onSubmit={handleCancel}
							>
								<AppButton size="small" disabled={!includeFormScreen}>
									{t('common:misc.cancel')}
								</AppButton>
							</ButtonWithConfirm>
						</Stack>
					)}
				</Stack>
			</Box>

			{includeFormScreen && (
				<>
					<Box sx={{ width: '60%', marginBottom: '15px' }}>
						<Stack direction="row" spacing={2}>
							<LoadingButton
								disabled={filterData?.type !== TYPES_OF_TILE.DATA_GRID}
								className={classes.icon}
								loading={loadingViewData}
								startIcon={<AutoFixHigh />}
								onClick={() => {
									dispatch({
										type: isEmpty(columnList)
											? ENV_ACTIONS.SHOW_ADD_VIEW
											: ENV_ACTIONS.SHOW_EDIT_VIEW,
										payload: true,
									})
								}}
							>
								<span>{t('common:misc.viewWizard')}</span>
							</LoadingButton>
							<AppButton variant="text" startIcon={<Dashboard />} onClick={handleClickOpenDialog}>
								{t('common:dis.placeOnScreen')}
							</AppButton>
							<LoadingButton className={classes.icon} startIcon={<Theaters />}>
								<span>Help video</span>
							</LoadingButton>
						</Stack>
					</Box>

					<Stack spacing={2} sx={{ mt: '5px' }}>
						<Box sx={{ width: '50%' }}>
							<TextField
								size="small"
								fullWidth
								id="name-basic"
								label={t('common:input.name')}
								name="name"
								variant="outlined"
								value={filterData.name || ''}
								onChange={(event) => handleFilterDataChange('name', event.target.value)}
							/>
						</Box>

						<Box sx={{ display: 'flex', gap: 2 }}>
							<Box sx={{ width: '50%' }}>
								<FormControl size="small" fullWidth>
									<InputLabel id="type-select-label">{t('common:input.type')}</InputLabel>
									<Select
										labelId="type-select-label"
										id="type-select"
										value={filterData.type || DEFAULT_TYPE_TILE}
										label={t('common:input.type')}
										name="type"
										onChange={(event) => handleFilterDataChange('type', event.target.value)}
									>
										{TYPE_TILE_MAP.map((type) => (
											<MenuItem key={type.value} value={type.value}>
												{type.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
							{/* <AppButton variant="text" startIcon={<Dashboard />} onClick={handleClickOpenDialog}>
								{t('common:dis.placeOnScreen')}
							</AppButton> */}
							{openDynamicDialog && (
								<DialogOfTilePosition
									tilePositionList={tilePositionList}
									name={filterData?.name}
									onClose={handleCloseDialog}
									open={openDynamicDialog}
									onChangePosition={handleTilePositionChange}
								/>
							)}
						</Box>
					</Stack>

					<Box sx={{ mt: 2 }}>{renderContent()}</Box>
				</>
			)}
		</Box>
	)
}

export default TileContent
