import { cloneDeep, isEmpty } from "lodash";
import moment from 'moment';

import {
    compareDatesWithoutTime,
    compareDateTime,
    compareTimeWithoutDate,
    isNullOrEmptyString
} from '../../../../../../utils/functions/helpers';

const MAIN_OPERATOR = {
    EQUALS: 'Equals',
    NOT_EQUALS: 'Not Equals',
}

const NUMERIC_OPERATOR = {
    GREATER_THAN: 'Greater Than',
    LESS_THAN: 'Less Than',
}

const TEXTUAL_OPERATOR = {
    CONTAINS: 'Contains',
}

const NUMERIC_OPERATORS = {
    ...MAIN_OPERATOR,
    ...NUMERIC_OPERATOR,
}

const TEXTUAL_OPERATORS = {
    ...MAIN_OPERATOR,
    ...TEXTUAL_OPERATOR,
}

export const OPERATOR_TYPE = {
    NUMERIC: 'NUMERIC',
    TEXTUAL: 'TEXTUAL',
    DATE: 'DATE',
    DATETIME: 'DATETIME',
    TIME: 'TIME',
}

const NUMERIC_TYPE = ['FLOAT', 'INTEGER', 'NUMBER'];
const TEXTUAL_TYPE = ['STRING'];
export const DATETIME_TYPE = ['DATE', 'DATETIME', 'TIME'];

export function getOperatorLabel(operator) {
    return MAIN_OPERATOR[operator] || NUMERIC_OPERATOR[operator] || TEXTUAL_OPERATOR[operator];
}

export function getColumnOperatorType(type) {
    if (NUMERIC_TYPE.includes(type)) return OPERATOR_TYPE.NUMERIC;
    if (DATETIME_TYPE.includes(type)) return OPERATOR_TYPE[type];
    return OPERATOR_TYPE.TEXTUAL;
}

export function getOperatorByPrimaryColumnType(type) {
    const result = Object.keys(MAIN_OPERATOR);
    switch (type) {
        case OPERATOR_TYPE.NUMERIC:
        case OPERATOR_TYPE.DATE:
        case OPERATOR_TYPE.DATETIME:
        case OPERATOR_TYPE.TIME:
            return Object.keys(NUMERIC_OPERATORS);
        case OPERATOR_TYPE.TEXTUAL:
            return Object.keys(TEXTUAL_OPERATORS);
        default:
            return result;
    }
}

export const SETTING_DISPATCH_TYPE = {
    ADD_NEW_COLOR: 'add_new_color',
    CHANGE_COLOR: 'change_color',
    CHANGE_VALUE: 'change_value',
    DELETE_COLOR: 'delete_color',
    CHANGE_PRIMARY_COLUMN: 'change_primary_column',
}

export const VALUE_TYPE = {
    CONSTANT: 'constant',
    COLUMN: 'column',
}

export function settingReducer(state, action) {
    switch (action.type) {
        case SETTING_DISPATCH_TYPE.CHANGE_PRIMARY_COLUMN: {
            return handleChangePrimaryColumn(state, action);
        }
        case SETTING_DISPATCH_TYPE.ADD_NEW_COLOR: {
            return handleAddColorCondition(state);
        }
        case SETTING_DISPATCH_TYPE.DELETE_COLOR: {
            return handleDeleteColorCondition(state, action.value);
        }
        case SETTING_DISPATCH_TYPE.CHANGE_VALUE: {
            return handleValueChange(state, action);
        }
        case SETTING_DISPATCH_TYPE.CHANGE_COLOR: {
            return handleColorChange(state, action);
        }
        default:
            throw Error('Unknown action.');
    }
}

function handleChangePrimaryColumn(currentState, action) {
    const { primaryColumn, ...other } = currentState;
    const newState = {
        ...currentState,
        primaryColumn: action.value,
    };

    return isEmpty(other) ? handleAddColorCondition(newState) : newState;
}

function handleAddColorCondition(currentState) {
    const generateID = new Date().getTime();

    const DEFAULT_COLOR = `${generateID}-#000000`;
    const newSetting = { operator: '', value: '', type: VALUE_TYPE.CONSTANT };
    return {
        ...currentState,
        [DEFAULT_COLOR]: [newSetting],
    };
}

function handleDeleteColorCondition(currentState, color) {
    const newBgColorRowCondition = cloneDeep(currentState);
    delete newBgColorRowCondition[color];
    const keys = Object.keys(newBgColorRowCondition);
    // return empty color condition when no color added
    if (keys.length === 1 && keys[0] === 'primaryColumn') {
        return {}
    }
    return { ...newBgColorRowCondition };
}

function handleColorChange(currentState, action) {
    const { oldColor, newColor } = action;
    const newBgColorRowCondition = { ...currentState };
    const formDataList = newBgColorRowCondition[oldColor];
    delete newBgColorRowCondition[oldColor];

    const idFromColor = oldColor.split('-')[0];
    newBgColorRowCondition[`${idFromColor}-${newColor}`] = formDataList;
    return { ...newBgColorRowCondition };
}

function handleValueChange(currentState, action) {
    let colorSettings = currentState[action.color];

    // oldSetting to newSetting
    if (colorSettings != null || colorSettings.length > 0) {
        colorSettings = colorSettings[0];
    }

    const newSetting = {
        ...colorSettings,
        ...action.setting,
    }

    return {
        ...currentState,
        [action.color]: [newSetting],
    }
}

function formatValue(type, value, format) {
    if (NUMERIC_TYPE.includes(type)) {
        if (!value)
            return null;
        return Number(value);
    }

    if (TEXTUAL_TYPE.includes(type)) {
        if (isEmpty(value)) return '';
        return value?.toString();
    }

    if (type === 'TIME') {
        if (isEmpty(value)) return null;
        const fullTimeFormat = 'h:mm:ss A';
        const momentFormat = format ?? fullTimeFormat;
        return moment(value, momentFormat)?.format(momentFormat);
    }

    if (isEmpty(value)) return null;
    return value;
}

function datetimeCompare(type, value1, value2, format) {
    switch (type) {
        case 'DATETIME':
            return compareDateTime(value1, value2)
        case 'DATE':
            return compareDatesWithoutTime(value1, value2);
        case 'TIME':
            return compareTimeWithoutDate(value1, value2, format);
        default:
            // false value
            return -2;
    }

}

export function compareValueWithPrimaryColumn(primaryColumnDetail, condition, rowData) {
    const { field: primaryColumnName, type: primaryColumnType } = primaryColumnDetail;
    const type = primaryColumnDetail.orgType === 'TIME' ? 'TIME' : primaryColumnType.toUpperCase();
    const timeFormat = primaryColumnDetail.format?.date;
    const primaryColumnValue = formatValue(type, rowData.row[primaryColumnName], timeFormat);
    const rowValue = formatValue(
        type,
        condition.type !== VALUE_TYPE.CONSTANT ? rowData.row[condition.value] : condition.value,
        timeFormat
    );
    const operatorLabel = getOperatorLabel(condition.operator);

    switch (operatorLabel) {
        case MAIN_OPERATOR.EQUALS:
            if (DATETIME_TYPE.includes(type))
                return datetimeCompare(type, primaryColumnValue, rowValue, timeFormat) === 0;
            return primaryColumnValue === rowValue;
        case MAIN_OPERATOR.NOT_EQUALS:
            if (DATETIME_TYPE.includes(type))
                return datetimeCompare(type, primaryColumnValue, rowValue, timeFormat) !== 0;
            return primaryColumnValue !== rowValue;
        case NUMERIC_OPERATOR.GREATER_THAN:
            if (DATETIME_TYPE.includes(type))
                return datetimeCompare(type, primaryColumnValue, rowValue, timeFormat) === 1;
            return primaryColumnValue > rowValue;
        case NUMERIC_OPERATOR.LESS_THAN:
            if (DATETIME_TYPE.includes(type))
                return datetimeCompare(type, primaryColumnValue, rowValue, timeFormat) === -1;
            return primaryColumnValue < rowValue;
        case TEXTUAL_OPERATOR.CONTAINS:
            return primaryColumnValue?.includes(rowValue);
        default:
            return false;
    }
}