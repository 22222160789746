import { useQuery } from '@tanstack/react-query'
import mapApi from 'apis/disApi/mapApi'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'

export const vehicleKeys = {
	all: ['vehicle-location'],
	allLocation: (userInfo, vehicleName, fromDate, toDate) => [
		...vehicleKeys.all,
		'all',
		userInfo,
		vehicleName,
		fromDate,
		toDate,
	],
	stopLocation: (userInfo, vehicleName, fromDate, toDate) => [
		...vehicleKeys.all,
		'stop',
		userInfo,
		vehicleName,
		fromDate,
		toDate,
	],
	additionalLocation: (userInfo, vehicleName, fromDate) => [
		...vehicleKeys.all,
		'additional',
		userInfo,
		vehicleName,
		fromDate,
	],
	mergeLocation: (userInfo, vehicleName, fromDate, toVersion) => [
		...vehicleKeys.all,
		'merge',
		userInfo,
		vehicleName,
		fromDate,
		toVersion,
	],
}

export const DEFAULT_REPEAT_RELOAD_MAP_SECONDS = 10

function useVehicleLocation({
	userInfo,
	vehicleName,
	fromDate,
	toDate,
	vehicleList,
	apiCallIntervalInSeconds = DEFAULT_REPEAT_RELOAD_MAP_SECONDS,
	showHistory,
	isDrivingVehicle,
}) {
	const [mergeLocationList, setMergeLocationList] = useState([])

	const hasToDate = Boolean(toDate)
	const hasUserInfo = Boolean(userInfo)
	const hasVehicleName = Boolean(vehicleName)
	const hasAnyVehicles = vehicleList?.length > 0
	const vehicleId = useMemo(
		() => vehicleList?.find((item) => item?.name?.trim() === vehicleName?.trim())?.id ?? '',
		[vehicleList, vehicleName]
	)

	const {
		data: allLocation,
		isLoading: isAllLoading,
		isFetching: isAllFetching,
	} = useQuery({
		queryKey: vehicleKeys.allLocation(userInfo, vehicleName, fromDate, toDate),
		queryFn: () =>
			mapApi.getVehicleLocationFromMygeotab({
				userInfo,
				vehicleId,
				fromDate,
				toDate,
			}),
		enabled: hasUserInfo && hasVehicleName && hasAnyVehicles && hasToDate && showHistory,
	})

	const {
		data: stopLocation,
		isLoading: isStopLoading,
		isFetching: isStopFetching,
	} = useQuery({
		queryKey: vehicleKeys.stopLocation(userInfo, vehicleName, fromDate, toDate),
		queryFn: async () => {
			const result = await mapApi.getStopLocationFromMygeotab({
				userInfo,
				vehicleId,
				fromDate,
				toDate,
			})
			const mapList = result?.data?.result
			if (mapList?.length > 0) {
				return mapList
			}
			return []
		},
		enabled: hasUserInfo && hasVehicleName && hasAnyVehicles && showHistory,
	})

	const {
		data: additionalLocation,
		isLoading: isAdditionalLoading,
		isFetching: isAdditionalFetching,
	} = useQuery({
		queryKey: vehicleKeys.additionalLocation(userInfo, vehicleName, fromDate),
		queryFn: () =>
			mapApi.getAdditionalLocationFromMygeotab({
				userInfo,
				vehicleId,
				fromDate,
			}),
		enabled: hasUserInfo && hasVehicleName && hasAnyVehicles && !hasToDate && showHistory,
	})

	const {
		data: mergeLocation,
		isLoading: isMergeLoading,
		isFetching: isMergeFetching,
	} = useQuery({
		queryKey: vehicleKeys.mergeLocation(
			userInfo,
			vehicleName,
			fromDate,
			additionalLocation?.data?.result?.toVersion
		),
		queryFn: async () =>
			mapApi.getAdditionalLocationFromMygeotab({
				userInfo,
				vehicleId,
				fromDate,
				fromVersion:
					mergeLocation?.data?.result?.toVersion ?? additionalLocation?.data?.result?.toVersion,
			}),
		// Feature Reload this API every `apiCallIntervalInSeconds` seconds
		refetchInterval: apiCallIntervalInSeconds * 1000,
		enabled:
			hasUserInfo &&
			hasVehicleName &&
			hasAnyVehicles &&
			!hasToDate &&
			Boolean(additionalLocation?.data?.result?.toVersion) &&
			showHistory &&
			isDrivingVehicle,
	})

	useEffect(() => {
		// Reset merge location list when vehicle name change
		setMergeLocationList([])
	}, [vehicleName])

	useEffect(() => {
		if (mergeLocation?.data?.result?.data?.length === 0) return

		setMergeLocationList((prev) => {
			const newLocation = mergeLocation?.data?.result?.data ?? []

			const uniqueMergeLocation = _.uniqBy([...prev, ...newLocation], 'id')
			return uniqueMergeLocation
		})
	}, [mergeLocation?.data?.result?.data])

	const neededLocation = useMemo(() => {
		// This is a special case, when we don't have toDate and don't show history
		if (!hasToDate && !showHistory) {
			return []
		}

		// This is a case when we remove toDate
		if (!hasToDate) {
			const additionalLocationData = additionalLocation?.data?.result?.data ?? []

			return [...additionalLocationData, ...mergeLocationList]
		}

		// This is a case when we have toDate
		return allLocation?.data?.result ?? []
	}, [
		allLocation?.data?.result,
		additionalLocation?.data,
		JSON.stringify(mergeLocationList),
		hasToDate,
		showHistory,
	])

	return {
		allLocation: neededLocation,
		stopLocation,
		isLoading: isAllLoading || isStopLoading || isAdditionalLoading || isMergeLoading,
		isFetching: isAllFetching || isStopFetching || isAdditionalFetching || isMergeFetching,
	}
}

export default useVehicleLocation
