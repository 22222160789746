import {
	Collapse,
	List,
	ListItem,
	ListItemButton,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { isEmpty } from 'lodash'
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import SkeletonLoaderSidePanel from '../../../custom-components/skeletons/SkeletonLoaderSidePanel'
import { DEVICES_ACTIONS } from '../../../reducers/devicesReducer'
import DashboardSearchBar from '../../core/Dashboard/DashboardSearchBar'
import DoformsSendDispatch from '../../data/dispatch/DoformsSendDispatch'
import DevicesButton from './DevicesButton'
import { VIEW } from '../../../constants'

const useStyles = makeStyles(() => ({
	deviceHeading: {
		display: 'flex',
		alignSelf: 'stretch',
		paddingTop: 0.5,
		paddingBottom: 0.5,
		paddingRight: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)',
		},
		'& .MuiTypography-root': {
			fontWeight: 'bold',
			display: 'block',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	deviceItem: {
		padding: 0,
		display: 'flex',
		overflow: 'hidden',
		justifyContent: 'center',
		alignItems: 'stretch',
	},
	deviceItemBtn: {
		display: 'flex',
		padding: '1px 0px 1px 16px',
		'&:hover': {
			backgroundColor: 'white',
		},
		'& .MuiTypography-root': {
			display: 'block',
			fontSize: '15px',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	deviceItemToolbar: {
		position: 'relative',
		top: 'auto',
		right: 'auto',
		display: 'flex',
		flex: '1 1 auto',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		backgroundColor: '#fff',
		transform: 'none',
		paddingRight: '2px',
	},
	deviceGroup: {
		padding: 0,
		display: 'flex',
		overflow: 'hidden',
		justifyContent: 'center',
		alignItems: 'stretch',
	},
	deviceGroupToolbar: {
		position: 'relative',
		top: 'auto',
		right: 'auto',
		display: 'flex',
		flex: '1 1 auto',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		transform: 'none',
		paddingRight: '2px',
	},
	deviceMenuListContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		flex: '1 1 0',
		overflow: 'hidden',
	},
	deviceMenuList: {
		paddingTop: 0,
		overflow: 'hidden',
		height: 'inherit',
		'&:not(.loading):hover': {
			overflowY: 'auto',
		},
		'& .MuiListItem-root': {
			flexDirection: 'column',
		},
		'& .MuiCollapse-root': {
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'stretch',
		},
		'& .MuiListItem-container': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		'& .MuiListItem-container:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	deviceMenuChildList: {
		'& ul li:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	loadingWrapper: {
		position: 'absolute',
		top: '20%',
		left: '45%',
		zIndex: '99999',
	},
}))

const DevicesDashboard = (props) => {
	const [t] = useTranslation('common')
	const classes = useStyles()
	const { teams, environment, loading } = props
	const userRights = environment.userCurrent.rights

	const { devicesModule } = useSelector((state) => state)
	const { deviceSelected, action } = devicesModule

	const [collapseTeams, setCollapseTeams] = useState([])
	const [itemName, setItemName] = useState(null)
	const [hovering, setHovering] = useState(false)
	const [groupHovering, setGroupHovering] = useState(false)
	const [dispatchFormSelected, setDispatchFormSelected] = useState({})
	const [openSendDispatch, setOpenSendDispatch] = useState(false)
	const [skeletonLoading, setSkeletonLoading] = useState(true)
	const [searchText, setSearchText] = useState('')

	const filteredTeams = useMemo(() => {
		if (searchText === '') {
			return teams
		} else {
			return teams.reduce((r, { key, name, devices }) => {
				let o = devices.filter(({ name }) => name.toLowerCase().includes(searchText))
				if (o && o.length) r.push({ key, name, devices: [...o] })
				return r
			}, [])
		}
	}, [searchText, teams])

	const dispatch = useDispatch()

	useEffect(() => {
		if (!environment.isProjectFormsLoaded || !environment.projects.length) return
		if (isEmpty(environment.dispatchForm) || !environment.userCurrent?.projectForm) return
		const matchProject = environment.projects.find(
			(item) => item.key === environment.dispatchForm.projectKey
		)
		const matchForm =
			!isEmpty(matchProject) &&
			matchProject.forms.find((item) => item.key === environment.dispatchForm.formKey)
		if (!isEmpty(matchProject) && matchForm) {
			setDispatchFormSelected({ ...matchForm, projectKey: matchProject.key })
		}
	}, [environment.dispatchForm, environment.projects])

	useLayoutEffect(() => {
		if (teams && teams.length > 0) {
			setSkeletonLoading(false)
		} else {
			setSkeletonLoading(true)
		}
	}, [teams])

	const showLoading = () => (
		<>
			<div className={classes.loadingWrapper}>
				<LoadingSpinner withStyle={false} />
			</div>
			{skeletonLoading && <SkeletonLoaderSidePanel />}
		</>
	)

	const handlePopoverOpen = (newItemName) => (event) => {
		event.stopPropagation()
		setHovering((prev) => itemName !== newItemName || !prev)
		setItemName(newItemName)
	}

	const handlePopoverClose = () => {
		if (openSendDispatch) return
		setHovering(false)
	}

	const handleGroupPopoverOpen = (newItemName) => (event) => {
		event.stopPropagation()
		setGroupHovering((prev) => itemName !== newItemName || !prev)
		setItemName(newItemName)
	}

	const handleGroupPopoverClose = () => {
		if (openSendDispatch) return
		setHovering(false)
		setGroupHovering(false)
	}

	const handleOpenSendDispatch = (value) => {
		setOpenSendDispatch(!!value)
	}

	const showItemActions = (device, team) => {
		return (
			itemName === `${team.key} / ${device.key}` && (
				<ListItemSecondaryAction className={classes.deviceItemToolbar}>
					<DevicesButton action={'audit'} team={team} device={device} />
					<DevicesButton action={'activity'} team={team} device={device} />
					<DevicesButton action={'map'} team={team} device={device} />
					{!environment.isReadOnlyDispatch && (
						<DoformsSendDispatch
							onDialogOpen={handleOpenSendDispatch}
							formSelected={dispatchFormSelected}
							action={'send'}
							title={!device.name || isEmpty(device.name) ? device.number : device.name}
							recordsLoading={false}
							source={'button'}
							tab={'devices'}
							deviceSelected={device}
							environment={environment}
						/>
					)}
				</ListItemSecondaryAction>
			)
		)
	}

	const showGroupActions = (team) => {
		return (
			itemName === `${team.key}` && (
				<ListItemSecondaryAction className={classes.deviceGroupToolbar}>
					<DevicesButton action={'map'} team={team} device={null} />
				</ListItemSecondaryAction>
			)
		)
	}

	const onChangeValueHandler = (value) => {
		const term = !value ? '' : value.toLowerCase()
		setSearchText(term)
	}

	const handleCollapse = (teamKey) => {
		const found = collapseTeams.includes(teamKey)
		if (!found) {
			setCollapseTeams([...collapseTeams, teamKey])
		} else {
			setCollapseTeams(collapseTeams.filter((item) => item !== teamKey))
		}
	}

	const clickHandler = (action, team, device) => (event) => {
		event.preventDefault()
		dispatch({
			type: DEVICES_ACTIONS.DEVICE_SELECTED,
			payload: { ...device, teamKey: team.key },
		})
		dispatch({
			type: DEVICES_ACTIONS.ACTION,
			payload: action,
		})
	}

	return (
		<>
			<DashboardSearchBar onChangedValue={onChangeValueHandler} tab={VIEW.VIEW_TAB_DEVICES} />
			<div className={classes.deviceMenuListContainer}>
				{loading && showLoading()}
				<List
					className={`${classes.deviceMenuList} ${loading ? t('common:misc.loading').toLowerCase() : ''
						}`}
				>
					{filteredTeams.length > 0 &&
						filteredTeams.map((team, index) => {
							if (!team.devices) return null
							if (!team.devices.length) return null
							return (
								<ListItem
									key={team.key}
									className={classes.deviceGroup}
									ContainerProps={{ onMouseLeave: handlePopoverClose }}
									disablePadding
								>
									<ListItemButton
										className={classes.deviceHeading}
										onClick={() => handleCollapse(team.key)}
										onMouseEnter={handleGroupPopoverOpen(`${team.key}`)}
										onMouseLeave={handleGroupPopoverClose}
									>
										<ListItemText primary={team.name} />
										{groupHovering && showGroupActions(team)}
									</ListItemButton>
									<Collapse
										id={index}
										in={!collapseTeams.includes(team.key)}
										timeout="auto"
										unmountOnExit
									>
										<List className={classes.deviceMenuChildList} disablePadding>
											{team.devices.map((device) => (
												<ListItem
													key={device.key}
													className={classes.deviceItem}
													onMouseEnter={handlePopoverOpen(`${team.key} / ${device.key}`)}
													ContainerProps={{ onMouseLeave: handlePopoverClose }}
												>
													<ListItemButton
														className={classes.deviceItemBtn}
														disabled={deviceSelected?.key === device?.key && action === 'audit'}
														onClick={clickHandler('audit', team, device)}
													>
														<ListItemText
															primary={
																<Tooltip
																	title={device.number}
																	arrow
																	placement="bottom-start"
																	disableInteractive
																	enterDelay={1000}
																	enterNextDelay={1000}
																>
																	<span>
																		{!device.name || isEmpty(device.name)
																			? device.number
																			: device.name}
																	</span>
																</Tooltip>
															}
														/>
													</ListItemButton>
													{hovering && showItemActions(device, team)}
												</ListItem>
											))}
										</List>
									</Collapse>
								</ListItem>
							)
						})}
				</List>
			</div>
		</>
	)
}

export default DevicesDashboard
