import { Box, Button, CircularProgress, Stack, TextField } from '@mui/material'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import ToastAlert from '../../../../core/Layouts/ToastAlert'
import SideDialog from '../../components/SideDialog'
import MasterDateTimeForm from './MasterDateTimeForm'

function MasterDateTimeSettingsDialog({
    tileElementWidth,
    defaultTileWidth,
    tile,
    open,
    settings,
    isSubmitting,
    showStable,
    onClose,
    onSubmit,
    onResizeTileWidth,
    dashboardKeyList,
}) {
    const { t } = useTranslation('common')
    const { id: dashboardKey } = useParams()

    const [alertInfo, setAlertInfo] = useState({
        open: false,
        text: '',
        type: 'success',
    })

    const [filterData, setFilterData] = useState({})

    const dialogWidth = tile?.w ?? 6
    const STABLE_WIDTH = 6

    const handleFilterDataChange = (id, value) => {
        setFilterData((prev) => ({ ...prev, [id]: value }))
    }

    const handleSubmit = () => {

        const checkedPrimaryKeys = filterData?.checkedPrimaryKeys
        if (isEmpty(checkedPrimaryKeys)) {
            setAlertInfo({
                open: true,
                text: 'Please show at least one key',
                type: 'error',
            })
            return
        }

        if (
            checkedPrimaryKeys.includes('Master_DateTime') &&
            isEmpty(filterData?.dateTimeOperators)
        ) {
            setAlertInfo({
                open: true,
                text: 'Master DateTime operators is empty',
                type: 'error',
            })
            return
        }
        if (
            checkedPrimaryKeys.includes('Master_Text') &&
            isEmpty(filterData?.textOperators)
        ) {
            setAlertInfo({
                open: true,
                text: 'Master Text operators is empty',
                type: 'error',
            })
            return
        }

        onSubmit?.({
            primaryKeyList: filterData?.primaryKeyList,
            otherOptionsChecked: filterData?.otherOptionsChecked,
            checkedPrimaryKeys,
            dateTimeOperators: filterData?.dateTimeOperators,
            textOperators: filterData?.textOperators,
        })
    }

    useEffect(() => {
        if (!isEmpty(dashboardKeyList)) {
            const newPrimaryKeyList = dashboardKeyList
                .filter((item) => item.tileName === tile.i)
                .map((item) => item.key)
            setFilterData((prev) => ({ ...prev, primaryKeyList: newPrimaryKeyList }))
        }
    }, [dashboardKeyList])

    useEffect(() => {
        setFilterData((prev) => ({ ...prev, checkedPrimaryKeys: settings.checkedPrimaryKeys }))
    }, [settings.checkedPrimaryKeys])

    useEffect(() => {
        setFilterData((prev) => ({ ...prev, otherOptionsChecked: settings.otherOptionsChecked }))
    }, [settings.otherOptionsChecked])

    useEffect(() => {
        setFilterData((prev) => ({ ...prev, dateTimeOperators: settings.dateTimeOperators }))
    }, [settings.dateTimeOperators])

    useEffect(() => {
        setFilterData((prev) => ({ ...prev, textOperators: settings.textOperators }))
    }, [settings.textOperators])

    return (
        <>
            <ToastAlert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />

            <SideDialog
                defaultTileWidth={defaultTileWidth}
                open={open}
                onClose={onClose}
                width={showStable ? STABLE_WIDTH : dialogWidth}
                tileElementWidth={tileElementWidth}
                onResizeWidth={onResizeTileWidth}
            >
                <Stack
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '0.9rem !important',
                        },
                        '& .MuiCheckbox-root': {
                            p: 0,
                        },
                    }}
                >
                    <Box sx={{ px: 1, mt: 2 }}>
                        <MasterDateTimeForm
                            filterData={filterData}
                            onFilterDataChange={handleFilterDataChange}
                            isTileDialogSetting={true}
                        />
                    </Box>

                    <Stack direction="row" justifyContent="flex-end" mt={2}>
                        <Stack spacing={2} direction="row">
                            <Button
                                size="small"
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                                startIcon={isSubmitting ? <CircularProgress color="inherit" size="1em" /> : null}
                            >
                                {t('common:misc.ok')}
                            </Button>
                            <Button size="small" variant="text" onClick={onClose}>
                                {t('common:misc.cancel')}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </SideDialog>
        </>
    )
}

export default MasterDateTimeSettingsDialog
