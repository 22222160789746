import { isEmpty, maxBy } from 'lodash'
import { logErrorMessage } from 'utils/functions/helpers'
import produce from 'immer'

export function convertToDndKitData(originalData) {
	try {
		if (isEmpty(originalData) || !originalData) return {}

		const dndKitData = {}
		originalData.forEach((item) => {
			const key = item?.dashboard?.key
			const childrenKeys = item?.items?.map((subItem) => subItem?.dashboard?.key) ?? []
			dndKitData[key] = childrenKeys
		})
		return dndKitData
	} catch (error) {
		logErrorMessage(error)
		return {}
	}
}

export function getSubMenuByKey(rootData, subMenuKey) {
	// Initialize a stack with rootData
	const stack = [...rootData]

	// Loop until the stack is empty
	while (stack.length > 0) {
		// Pop an item from the stack
		const item = stack.pop()

		// If the item key matches subMenuKey, return the item
		if (item?.dashboard?.key === subMenuKey) {
			return item
		}

		// If the item has items, push them to the stack
		if (item.items && item.items.length > 0) {
			stack.push(...item.items)
		}
	}

	// If no matching item is found, return null
	return null
}

export function updateDraggableRowsData(rootData, draggedRows) {
	try {
		if (isEmpty(draggedRows) || isEmpty(rootData)) return rootData

		const result = rootData.map((column) => {
			const {
				dashboard: { key: columnKey },
			} = column

			if (!draggedRows[columnKey]) return column

			const customItems = draggedRows[columnKey].map((id, index) => {
				const columnParent = rootData.find((item) =>
					item?.items?.some((subItem) => subItem?.dashboard?.key === id)
				)
				const data = columnParent?.items?.find((item) => item?.dashboard?.key === id)
				return { ...data, position: `${index + 1}` }
			})

			return { ...column, items: customItems }
		})

		return result
	} catch (error) {
		logErrorMessage(error)
		return rootData
	}
}

export function updateDraggableColumnsData(rootData, draggedColumns) {
	try {
		if (isEmpty(draggedColumns) || isEmpty(rootData)) return []

		return draggedColumns.map((id, index) => {
			const data = rootData.find((item) => item?.dashboard?.key === id)
			return { ...data, position: `${index + 1}` }
		})
	} catch (error) {
		logErrorMessage(error)
		return rootData ?? []
	}
}

export function generateNewPosition(menuItems) {
	if (isEmpty(menuItems) || !Array.isArray(menuItems)) return 1

	const maxPositionMenu = maxBy(menuItems, (o) => {
		return +o.position
	})
	const newPosition = +maxPositionMenu?.position + 1
	return `${newPosition}`
}

export function getParentOfMenuItemKey({ isSubMenu, menuItemsList, menuKey }) {
	try {
		if (isSubMenu) {
			return menuItemsList?.find((item) =>
				item?.items?.find((subItem) => subItem?.dashboard?.key === menuKey)
			)
		} else {
			return menuItemsList?.find((item) => item?.dashboard?.key === menuKey)
		}
	} catch (error) {
		logErrorMessage(error)
		return null
	}
}

function convertStringToRect(inputString) {
	var cleanString = inputString.replace(/[{}]/g, '')

	var numbersArray = cleanString.split(',').map(Number)

	if (numbersArray.length !== 4) {
		console.error('Input string must contain exactly 4 numbers')
		return null
	}

	return {
		x: numbersArray[0],
		y: numbersArray[1],
		w: numbersArray[2],
		h: numbersArray[3],
	}
}

export function parseRect(tileInfo) {
	const { name, position, type, key } = tileInfo

	if (!name || !position || !type || !key) return null

	const { x, y, w, h } = convertStringToRect(position)

	return {
		i: name,
		x,
		y,
		w,
		h,
		type,
		key,
	}
}

export function getNewEmptyPosition(layoutList) {
	if (!layoutList) return { x: 0, y: 0 }

	let maxX = 0
	let maxY = 0
	for (const item of layoutList) {
		const itemRightX = item.x + item.w
		const itemBottomY = item.y + item.h
		if (itemRightX > maxX) maxX = itemRightX
		if (itemBottomY > maxY) maxY = itemBottomY
	}

	return { x: maxX, y: maxY }
}
