import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Box, CircularProgress, Stack, Tooltip } from '@mui/material'
import Fade from '@mui/material/Fade'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { makeStyles } from '@mui/styles'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { useUrlQueryParams } from 'utils/hooks/useUrlQueryParams'
import { CoreContext } from '../../../custom-components/context/CoreContext'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import '../../../styles.css'
import { checkAdminRole } from '../../../utils/functions/helpers'
import { RIGHT_SIDE_MENU_LIST, RIGHT_SIDE_MENU_LIST_WITH_DEFAULT } from './DisLayout'
import { isEmpty } from 'lodash'

const useStyles = makeStyles(() => ({
	menuAppbar: (props) => ({
		background: props.backgroundColor,
		minHeight: '30px',
		border: 'none',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 0,
		marginBottom: 0,
		listStyle: 'none',
	}),
	toolbar: {
		minHeight: 'fit-content',
	},
	menuNavLink: (props) => ({
		display: 'flex',
		padding: '0.2rem 1rem',
		color: props.color,
		'&:hover': {
			backgroundColor: props.active.backgroundColor,
			color: props.active.color,
			textDecoration: 'none',
			cursor: 'pointer',
		},
	}),
	menuNavIconLink: (props) => ({
		display: 'flex',
		padding: '0.2rem 0.5rem',
		color: props.isActive ? props.active.color : props.color,
		backgroundColor: props.isActive ? props.active.backgroundColor : undefined,

		'&:hover, &.isActive': {
			cursor: 'pointer',
			backgroundColor: props.active.backgroundColor,
			color: props.active.color,
			textDecoration: 'none',
		},
	}),

	selected: (props) => ({
		backgroundColor: props.active.backgroundColor,
		color: props.active.color,
	}),

	icon: (props) => ({
		fontSize: '1.5em',
	}),
}))

const subMenuLinks = {
	mnMobileUnits: 'https://support.doforms.com/hc/en-us/articles/1500007337962',
	mnForms: 'https://support.doforms.com/hc/en-us/articles/1500007337942',
	mnActivity: 'https://support.doforms.com/hc/en-us/articles/31955009641875',
	mnScheduler: 'https://support.doforms.com/hc/en-us/articles/360046564933',
	mnLookups: 'https://support.doforms.com/hc/en-us/articles/1500005519222',
	mnViews: 'https://support.doforms.com/hc/en-us/articles/1500005483522',
	mnMyReports: 'https://support.doforms.com/hc/en-us/articles/1500005519862',
	mnVideos: 'https://www.doforms.com/video-library/',
	mnSupport:
		'https://mydoforms.appspot.com/previewSubmit?id=ag9zfm15ZG9mb3Jtcy1ocmRyGAsSC1Byb2plY3RGb3JtGICAsNeX65sJDA&embedhtml=null&embedlink=null&email=null&hideToolbar=null&viewmode=null&hideLogo=null&mobileUserKey=&recordKey=&view=null&ornt=null&size=null&formlink=null&canSave=null&canSchedule=null&hideComplete=&hideSubmit=',
	mnMarketplace: 'https://www.doforms.com/marketplace/',
}

const linkTo = (dashboardKey, menuKey) => {
	if (isEmpty(menuKey))
		return `/dis/${dashboardKey}`

	return `/dis/${dashboardKey}?menuKey=${menuKey}`
}

const linkToPage = (path) => {
	const newPath = path?.slice(1)

	return `/dis/default/${newPath}`
}

const DisNavTabs = ({
	menuItemList,
	isShowAllMenus,
	setIsShowAllMenus,
	isShowIconLoading,
	menuSettings,
	setCurrentMenu,
	listAllMenu,
	defaultChildMenu
}) => {
	const urlQueryParam = useUrlQueryParams()
	const fullscreenQuery = urlQueryParam.get('fullscreen')
	const history = useHistory();

	const { coreValues } = useContext(CoreContext);
	const { environment } = useSelector((state) => state)

	useEffect(() => {
		const displayDefaultMenu = sessionStorage.getItem('displayDefaultMenu')
		if (displayDefaultMenu || !defaultChildMenu) return;

		const isPage = defaultChildMenu?.type === 'page';
		const isPageScheduler = defaultChildMenu?.page?.path?.slice(1) === 'scheduler';
		if (isPageScheduler) return;

		sessionStorage.setItem('displayDefaultMenu', 'false')
		const url = isPage ? linkToPage(defaultChildMenu?.page?.path) : linkTo(defaultChildMenu?.dashboard?.key, defaultChildMenu?.menuKey);
		history.push(url);
	}, [defaultChildMenu])

	const { translations, theme } = coreValues
	const menuBarTheme = theme.menuBar
	const classes = useStyles(menuBarTheme)
	const [t] = useTranslation('common')

	const [anchorEl, setAnchorEl] = useState(null)
	const [open, setOpen] = useState(false)
	const [iconMenuSelected, setIconMenuSelected] = useState(null)
	const [fullscreenMode, setFullscreenMode] = useState(fullscreenQuery === '1' ? true : false)
	const [fullscreenHover, setFullscreenHover] = useState(false)
	const [fullscreenExitHover, setFullscreenExitHover] = useState(false)

	const dispatch = useDispatch()

	const isAdmin = checkAdminRole(environment?.userCurrent)
	const isShowBuildForms = environment?.userCurrent?.rights?.form?.length > 0
	const isShowBuildReports = environment?.userCurrent?.rights?.report?.length > 0

	const isShowBuildIcon = isShowBuildForms || isShowBuildReports

	const isShowManageList = environment?.userCurrent?.rights?.manage?.includes('update')
	const isShowAccount = environment?.userCurrent?.rights?.account?.length > 0

	const showMenuItems = 'show-menu-items'

	const handleToggleFullscreen = (e, toggle) => {
		setFullscreenMode(toggle)
		dispatch({
			type: ENV_ACTIONS.TOGGLE_FULLSCREEN_MODE,
			payload: toggle,
		})

		if (toggle) {
			setTimeout(() => {
				setFullscreenHover(false)
			}, 500)
		} else {
			setTimeout(() => {
				setFullscreenExitHover(false)
			}, 500)
		}
	}

	const handleMenuIconClick = (e, target) => {
		setAnchorEl(e.currentTarget)
		setIconMenuSelected(target)

		switch (target) {
			case showMenuItems:
				break
			case 'question':
				break
			case 'video':
				break
			case 'headset':
				break
			case 'contruction':
				break
			case 'setting':
				break
			default:
				return
		}
		setOpen(true)
	}

	const handleClose = () => {
		setAnchorEl(null)
		setOpen(false)
	}

	const launchBuildForms = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(environment.renderHost + '?target=buildforms&token=' + environment.apiToken)
		w.document.title = 'doForms'
		handleClose()
	}

	const launchBuildReports = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(
			environment.renderHost + '?target=buildreports&token=' + environment.apiToken
		)
		w.document.title = 'doForms'
		handleClose()
	}

	const launchMobileUsers = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(environment.renderHost + '?target=devices&token=' + environment.apiToken)
		w.document.title = 'doForms'
		handleClose()
	}

	const launchWebUsers = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(environment.renderHost + '?target=users&token=' + environment.apiToken)
		w.document.title = 'doForms'
		handleClose()
	}

	const launchConfigSystem = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(environment.renderHost + '?target=manage&token=' + environment.apiToken)
		w.document.title = 'doForms'
		handleClose()
	}

	const launchMyAccount = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(environment.renderHost + '?target=account&token=' + environment.apiToken)
		w.document.title = 'doForms'
		handleClose()
	}

	const launchChat = (event) => {
		event.preventDefault()
		event.stopPropagation()
		window.olark('api.box.expand')
		handleClose()
	}

	return (
		<Box sx={{ '& li': { listStyle: 'none' } }}>
			<Stack className={classes.menuAppbar} direction="row" justifyContent="space-between">
				<Box
					component="ul"
					sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', p: 0, m: 0 }}
				>
					{menuItemList?.length > 0 &&
						menuItemList.map((tab, index) => {
							return <NavItem tab={tab} key={index} />
						})}
				</Box>

				<Stack direction="row">
					<Box component="ul" sx={{ display: 'flex', alignItems: 'center', mb: '0 !important' }}>
						{isAdmin && (
							<>
								<li className="nav-item">
									{isShowIconLoading ? (
										<CircularProgress
											sx={{
												position: 'relative',
												top: '3px',
												mr: 1,
											}}
											color="warning"
											size={20}
										/>
									) : (
										<Tooltip
											key={showMenuItems}
											title="Show menu items"
											arrow
											placement="bottom-start"
										>
											<div
												onClick={(e) => handleMenuIconClick(e, showMenuItems)}
												className={`${classes.menuNavIconLink} ${isShowAllMenus ? 'isActive' : ''}`}
											>
												<VisibilityIcon />
											</div>
										</Tooltip>
									)}
								</li>
								<li className="nav-item">
									<Tooltip
										key="menu-manager"
										title={t('common:dis.menuManager')}
										arrow
										placement="bottom-start"
									>
										<NavLink className={classes.menuNavIconLink} to="/dis/menu-manager">
											<svg
												stroke="currentColor"
												fill="none"
												strokeWidth="2"
												viewBox="0 0 24 24"
												strokeLinecap="round"
												strokeLinejoin="round"
												height="1.4em"
												width="1.4em"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
												<path d="M4 12v-6a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-6"></path>
												<path d="M4 10h16"></path>
												<path d="M10 4v9"></path>
												<path d="M5.281 18.5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
												<path d="M5.281 15v1.5"></path>
												<path d="M5.281 20.5v1.5"></path>
												<path d="M8.312 16.75l-1.299 .75"></path>
												<path d="M3.55 19.5l-1.3 .75"></path>
												<path d="M2.25 16.75l1.3 .75"></path>
												<path d="M7.013 19.5l1.3 .75"></path>
											</svg>
										</NavLink>
									</Tooltip>
								</li>
							</>
						)}
						{menuSettings.includes('fullScreen') && (
							<li className="nav-item">
								{fullscreenMode ? (
									<Tooltip
										key="expand"
										title={`${t('tooltip.expand')}`}
										arrow
										placement="bottom-start"
										disableInteractive
										disableHoverListener
										open={!fullscreenHover && fullscreenExitHover}
									>
										<NavLink
											className={classes.menuNavIconLink}
											to="#"
											onClick={(e) => handleToggleFullscreen(e, !fullscreenMode)}
											onMouseEnter={() => setFullscreenExitHover(true)}
											onMouseLeave={() => setFullscreenExitHover(false)}
										>
											<FullscreenExitIcon className={classes.icon} />
										</NavLink>
									</Tooltip>
								) : (
									<Tooltip
										key="fullscreen"
										title={`${t('tooltip.fullscreen')}`}
										arrow
										placement="bottom-start"
										disableInteractive
										disableHoverListener
										open={!fullscreenExitHover && fullscreenHover}
									>
										<NavLink
											className={classes.menuNavIconLink}
											to="#"
											onClick={(e) => handleToggleFullscreen(e, !fullscreenMode)}
											onMouseEnter={() => setFullscreenHover(true)}
											onMouseLeave={() => setFullscreenHover(false)}
										>
											<FullscreenIcon className={classes.icon} />
										</NavLink>
									</Tooltip>
								)}
							</li>
						)}

						{RIGHT_SIDE_MENU_LIST.map((menu, index) => {
							const Icon = menu.icon
							return (
								<li hidden={!menuSettings.includes(menu.name)} className="nav-item" key={index}>
									<div
										className={classes.menuNavIconLink}
										onClick={(e) => handleMenuIconClick(e, menu.name)}
									>
										<Icon className={classes.icon} />
									</div>
								</li>
							)
						})}
						{RIGHT_SIDE_MENU_LIST_WITH_DEFAULT.map((menu, index) => {
							const Icon = menu.icon
							const isShow = menu.name === 'contruction' ? isShowBuildIcon : true

							return (
								<li hidden={!isShow} className="nav-item" key={index}>
									<div
										className={classes.menuNavIconLink}
										onClick={(e) => handleMenuIconClick(e, menu.name)}
									>
										<Icon className={classes.icon} />
									</div>
								</li>
							)
						})}
					</Box>
				</Stack>
			</Stack>

			{iconMenuSelected === showMenuItems && (
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
				>
					{listAllMenu?.map((x, index) => {
						return (
							<MenuItem
								onClick={(e) => {
									setCurrentMenu(x.key)
									setIsShowAllMenus(!x.isDefault)
									handleClose()
								}}
								key={index}
							>
								{x.name}
							</MenuItem>
						)
					})}
				</Menu>
			)}
			{iconMenuSelected === 'question' && (
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
				>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnMobileUnits'], '_blank')
							handleClose()
						}}
					>
						{translations.mnDevices ? translations.mnDevices : t('common:tabs.devices')}
					</MenuItem>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnForms'], '_blank')
							handleClose()
						}}
					>
						{translations.mnForms ? translations.mnForms : t('common:tabs.forms')}
					</MenuItem>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnActivity'], '_blank')
							handleClose()
						}}
					>
						{translations.mnActivity ? translations.mnActivity : t('common:tabs.activity')}
					</MenuItem>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnScheduler'], '_blank')
							handleClose()
						}}
					>
						{translations.mnScheduler ? translations.mnScheduler : t('common:tabs.scheduler')}
					</MenuItem>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnLookups'], '_blank')
							handleClose()
						}}
					>
						{translations.mnLookups ? translations.mnLookups : t('common:tabs.lookups')}
					</MenuItem>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnViews'], '_blank')
							handleClose()
						}}
					>
						{translations.mnView ? translations.mnView : t('common:tabs.views')}
					</MenuItem>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnMyReports'], '_blank')
							handleClose()
						}}
					>
						{translations.mnReports ? translations.mnReports : t('common:tabs.reports')}
					</MenuItem>
				</Menu>
			)}
			{iconMenuSelected === 'video' && (
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
				>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnVideos'], '_blank')
							handleClose()
						}}
					>
						{translations.mnVideos ? translations.mnVideos : t('common:tabs.videos')}
					</MenuItem>
				</Menu>
			)}
			{iconMenuSelected === 'headset' && (
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
				>
					<MenuItem
						onClick={() => {
							window.open(
								subMenuLinks['mnSupport'],
								'supportwindow',
								'menubar=1,resizable=1,width=1000,height=1000'
							)
							handleClose()
						}}
					>
						{t('common:tabs.openSupportTicket')}
					</MenuItem>
					<MenuItem onClick={(e) => launchChat(e)}>{t('common:tabs.chatWithSupport')}</MenuItem>
				</Menu>
			)}
			{iconMenuSelected === 'contruction' && (
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
				>
					{isShowBuildForms && (
						<MenuItem onClick={(e) => launchBuildForms(e)}>{t('common:tabs.buildForms')}</MenuItem>
					)}
					{isShowBuildReports && (
						<MenuItem onClick={(e) => launchBuildReports(e)}>
							{t('common:tabs.buildReports')}
						</MenuItem>
					)}
				</Menu>
			)}
			{iconMenuSelected === 'setting' && (
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
				>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnMarketplace'], '_blank')
							handleClose()
						}}
					>
						{translations.mnMarketplace ? translations.mnMarketplace : t('common:tabs.marketplace')}
					</MenuItem>
					{isShowManageList && (
						<>
							<MenuItem onClick={(e) => launchMobileUsers(e)}>
								{t('common:tabs.manageMobile')}
							</MenuItem>
							<MenuItem onClick={(e) => launchWebUsers(e)}>{t('common:tabs.manageWeb')}</MenuItem>
							<MenuItem onClick={(e) => launchConfigSystem(e)}>
								{translations.mnConfigureSystem
									? translations.mnConfigureSystem
									: t('common:tabs.configureSystem')}
							</MenuItem>
						</>
					)}
					{isShowAccount && (
						<MenuItem onClick={(e) => launchMyAccount(e)}>
							{translations.mnManageAccount
								? translations.mnManageAccount
								: t('common:tabs.manageAccount')}
						</MenuItem>
					)}
				</Menu>
			)}
		</Box>
	)
}

function NavItem({ tab }) {
	const { environment } = useSelector((state) => state)
	const { coreValues } = useContext(CoreContext)
	const { theme } = coreValues
	const menuBarTheme = theme.menuBar
	const classes = useStyles(menuBarTheme)

	if (!tab) return null

	const isPage = tab?.type === 'page'
	const isPageScheduler = tab?.page?.path?.slice(1) === 'scheduler'

	const launchDispatchScheduler = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(environment.renderHost + '?target=scheduler&token=' + environment.apiToken)
		w.document.title = 'doForms'
	}

	return (
		<li className="nav-item">
			{isPageScheduler ? (
				<span className={classes.menuNavLink} onClick={(e) => launchDispatchScheduler(e)}>
					{tab.name}
				</span>
			) : (
				<NavLink
					className={classes.menuNavLink}
					activeClassName={classes.selected}
					to={isPage ? linkToPage(tab?.page?.path) : linkTo(tab?.dashboard?.key, tab?.menuKey)}
				>
					{tab.name}
				</NavLink>
			)}

			{tab?.items?.length > 0 && (
				<ul
					className="menu-dropdown-list"
					style={{
						backgroundColor: menuBarTheme.backgroundColor,
					}}
				>
					{tab?.items?.map((child, index) => {
						return <NavItem key={index} tab={child} />
					})}
				</ul>
			)}
		</li>
	)
}

export default DisNavTabs
