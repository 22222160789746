import { Box, Button, CircularProgress, Stack, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SideDialog from '../../components/SideDialog'
import PageForm from './PageForm'

function PageSettingsDialog({
	defaultTileWidth,
	tileElementWidth,
	open,
	tile,
	isLoading,
	selectedMenuPath,

	onClose,
	onResizeTileWidth,
	onSubmit,
}) {
	const { t } = useTranslation('common')
	const [currentPath, setCurrentPath] = useState('')

	useEffect(() => {
		if (selectedMenuPath) {
			setCurrentPath(selectedMenuPath)
		}
	}, [selectedMenuPath])

	const handleSubmit = () => {
		onSubmit?.(currentPath)
	}

	return (
		<SideDialog
			defaultTileWidth={defaultTileWidth}
			open={open}
			onClose={onClose}
			width={tile?.w ?? 6}
			tileElementWidth={tileElementWidth}
			onResizeWidth={onResizeTileWidth}
		>
			<Stack
				sx={{
					'& .MuiInputBase-input': {
						fontSize: '0.9rem !important',
					},
					'& .MuiCheckbox-root': {
						p: 0,
					},
				}}
			>
				<Box sx={{ px: 1 }}>
					<TextField
						fullWidth
						id="label-basic"
						label={t('common:chart.labels')}
						variant="standard"
						value={tile.i}
						disabled={true}
						size="small"
					/>
					<PageForm
						currentPath={currentPath}
						onChangeCurrentPath={(path) => setCurrentPath(path)}
					/>
				</Box>

				<Stack direction="row" justifyContent="flex-end" mt={2}>
					<Stack spacing={2} direction="row">
						<Button
							size="small"
							variant="contained"
							onClick={handleSubmit}
							disabled={isLoading}
							startIcon={isLoading ? <CircularProgress color="inherit" size="1em" /> : null}
						>
							OK
						</Button>
						<Button size="small" variant="text" onClick={onClose}>
							Cancel
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</SideDialog>
	)
}

export default PageSettingsDialog
