import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List as ListIcon, Room as RoomIcon } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { FORM_ACTIONS } from '../../../reducers/formsReducer'

const useStyles = makeStyles(() => ({
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const FormsButton = ({ action, form, projectKey }) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const { formsModule } = useSelector((state) => state)
	const { formSelected, action: actionStore } = formsModule
	const checkDisabledIcon = `${actionStore}${formSelected?.key}` === `${action}${form?.key}`

	const dispatch = useDispatch()

	const clickHandler = (e) => {
		e.preventDefault()
		e.stopPropagation()
		dispatch({
			type: FORM_ACTIONS.FORM_SELECTED,
			payload: { ...form, projectKey: projectKey },
		})
		dispatch({
			type: FORM_ACTIONS.ACTION,
			payload: action,
		})
	}
	const computeIcon = (action) => {
		switch (action) {
			case 'audit':
				return (
					<Tooltip
						title={`${t('tooltip.prefix.data')} - ${form.displayName}`}
						arrow
						placement="bottom-start"
						disableInteractive
					>
						<IconButton
							aria-label="audit"
							size="small"
							color="primary"
							edge="end"
							disabled={checkDisabledIcon}
							onClick={clickHandler}
						>
							<ListIcon
								fontSize="inherit"
								className={classes.icon}
								sx={{ color: checkDisabledIcon ? '#ddd !important' : 'inherit' }}
							/>
						</IconButton>
					</Tooltip>
				)
			case 'map':
				return (
					<Tooltip
						title={`${t('tooltip.prefix.map')} - ${form.name}`}
						arrow
						placement="bottom-start"
						disableInteractive
					>
						<span>
							<IconButton
								aria-label="map"
								size="small"
								color="primary"
								edge="end"
								onClick={clickHandler}
							>
								<RoomIcon fontSize="inherit" className={classes.icon} />
							</IconButton>
						</span>
					</Tooltip>
				)
			default:
				return
		}
	}

	return <>{computeIcon(action)}</>
}

export default FormsButton
