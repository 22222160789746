import { API } from '../../../config'
import { getAccountsCurrent, getAllLinks, getThemePortal, getUserCurrent } from './coreServices'
import { storeToStorage } from './coreLocalStorage'

export const getApiHost = () => {
	const apiHost = `${API}/api/v2`
	return apiHost
}

export const loadLinks = () => {
	if (typeof window !== 'undefined') {
		if (!sessionStorage.getItem('links')) {
			getAllLinks().then((data) => {
				if (data.error) {
					console.log(data.error)
				} else {
					storeToStorage(data, 'links')
				}
			})
		}
	}
}

export const loadTheme = (token) => {
	if (!token) return
	if (typeof window !== 'undefined') {
		if (!sessionStorage.getItem('theme')) {
			getThemePortal(token).then((data) => {
				if (data.error) {
					console.log(data.error)
				} else {
					storeToStorage(data, 'theme')
				}
			})
		}
	}
}

export const loadAccountsCurrent = (token) => {
	if (!token) return
	if (typeof window !== 'undefined') {
		if (!sessionStorage.getItem('account')) {
			getAccountsCurrent(token).then((data) => {
				if (data.error) {
					console.log(data.error)
				} else {
					storeToStorage(data, 'account')
				}
			})
		}
	}
}

export const loadUserCurrent = (token) => {
	if (!token) return
	if (typeof window !== 'undefined') {
		if (!sessionStorage.getItem('user')) {
			getUserCurrent(token).then((data) => {
				if (data.error) {
					console.log(data.error)
				} else {
					storeToStorage(data, 'user')
				}
			})
		}
	}
}
