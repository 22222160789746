import axios from 'axios'
import { apiUrl } from '../apiUrl'

const url = apiUrl()
const apiHeader = (token) => {
	return {
		Accept: 'application/json',
		Authorization: `Bearer ${token}`,
	}
}

const menuGroupApi = {
	getAll(token) {
		return axios.get(`${url}/groups/detail`, {
			headers: apiHeader(token),
		})
	},

	add({ token, data }) {
		return axios.post(`${url}/groups`, data, {
			headers: apiHeader(token),
		})
	},

	update({ token, groupKey, data }) {
		return axios.put(`${url}/groups/${groupKey}`, data, {
			headers: apiHeader(token),
		})
	},

	remove({ token, groupKey }) {
		return axios.delete(`${url}/groups/${groupKey}`, {
			headers: apiHeader(token),
		})
	},

	assignToMenu({ groupKey, data, token }) {
		return axios.put(`${url}/groups/${groupKey}`, data, {
			headers: apiHeader(token),
		})
	},
}

export default menuGroupApi
