import { TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns'
import { utcToZonedTime } from 'date-fns-tz'
import { enUS } from 'date-fns/locale'
import { useState, useEffect, } from 'react'
import { useTranslation } from 'react-i18next'
import { getUserTimeZone } from 'utils/functions/helpers'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { toast } from 'react-toastify'

export function validateDateRange(beginDate, endDate) {
    const beginMoment = moment(beginDate)
    const endMoment = moment(endDate)
    if (endMoment.isBefore(beginMoment)) return 'From date must be a day before to date.'

    if (endMoment.diff(beginMoment, 'days') > 10) return 'Maximum date range is ten days.'
    return ''
}

export default function DoformsMapFilters({
    fromDate,
    toDate,
    disableFilters,
    onFilters,
    setErrorMessage
}) {
    const { t } = useTranslation('common')
    const [locale, setLocale] = useState(enUS)

    const handleFromDateChange = (newValue) => {
        if (newValue == null) {
            onFilters?.('fromDate', newValue)
            return
        }

        const errMessage = validateDateRange(newValue, toDate)
        if (setErrorMessage) {
            if (!isEmpty(errMessage)) {
                toast.error(errMessage)
                setErrorMessage(errMessage)
                return
            }

            setErrorMessage('')
        }

        newValue.setHours(0, 0, 0, 0)
        const localDate = utcToZonedTime(newValue, getUserTimeZone())

        onFilters?.('fromDate', localDate)
    }

    const handleToDateChange = (newValue) => {
        if (newValue == null) {
            onFilters?.('toDate', newValue)
            return
        }

        const errMessage = validateDateRange(fromDate, newValue)
        if (setErrorMessage) {
            if (!isEmpty(errMessage)) {
                toast.error(errMessage)
                setErrorMessage(errMessage)
                return
            }

            setErrorMessage('')
        }

        newValue.setHours(23, 59, 59, 999)
        const localDate = utcToZonedTime(newValue, getUserTimeZone())

        onFilters?.('toDate', localDate)
    }

    useEffect(() => {
        const importLocaleFile = async () => {
            const localeToSet = await import(
                `date-fns/locale/${t('common:languages.dateFnsLocale')}/index.js`
            )
            setLocale(localeToSet.default)
        }

        if (locale.code !== t('common:languages.dateFnsLocale')) {
            importLocaleFile()
        }
    }, [t('common:languages.dateFnsLocale')])

    return (
        <>
            <LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
                <DatePicker
                    componentsProps={{
                        actionBar: {
                            actions: ['clear'],
                        },
                    }}
                    id="map-date"
                    label={t('common:input.fromDate')}
                    disabled={disableFilters}
                    value={fromDate || null}
                    maxDate={new Date()}
                    emptyLabel=""
                    onChange={handleFromDateChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={t('common:input.fromDate')}
                            // Turn off the error when the user clears the date
                            error={false}
                        ></TextField>
                    )}
                />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
                <DatePicker
                    componentsProps={{
                        actionBar: {
                            actions: ['clear'],
                        },
                    }}
                    id="map-date"
                    label={t('common:input.toDate')}
                    disabled={disableFilters}
                    value={toDate || null}
                    maxDate={new Date()}
                    emptyLabel=""
                    onChange={handleToDateChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={t('common:input.toDate')}
                            // Turn off the error when the user clears the date
                            error={false}
                        />
                    )}
                />
            </LocalizationProvider>
        </>
    )
}