import { Fragment, useState, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { cloneDeep, isEmpty } from 'lodash'

import { TextField, MenuItem, Grid, Tooltip, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { nonDatesValues, operatorOptions, getConditionString } from '../../../../../utils/functions/conditionsOptions'
import { TEXT_CONDITION_FILTER_TYPE } from './MasterDatetimeTile'

const useStyles = makeStyles(() => ({
    root: {
        minWidth: '300px',
    },

    columnBox: {
        maxWidth: '15em',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '0',
            },
        },
    },

    filtersContainer: {
        margin: '16px',
    },

    footer: {
        // marginTop: theme.spacing(1),
        display: 'flex',
        minHeight: '52px',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .MuiButton-root': {
            textTransform: 'none !important',
        },
    },

    footerLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '8px',
    },

    footerRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },

    formRoot: {
        padding: '16px',
        justifyContent: 'space-around',
    },

    menuItem: {
        fontSize: '13px',
        paddingTop: 'none',
        paddingBottom: 'none',
    },

    subheader: {
        margin: 'none',
    },

    operatorBox: {
        minWidth: '120px',
        width: '100%',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },
    },

    customValueBox: {
        display: 'inline-grid',
        width: '100%',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },

        '& .MuiOutlinedInput-input': {
            minHeight: '1.65em',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: '400',
            fontSize: '0.875rem',
            lineHeight: '1.4375em',
            letterSpacing: '0.00938em',
        },
    },

    presetsValueBox: {
        display: 'inline-grid',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0 4px 4px 0`,
            },
        },
    },
}))

const DEFAULT_TEXT_OPERATOR = 'ALL'

const VALUE_RENDER_TYPE = {
    RANGE: 'range',
    SINGLE: 'single'
}

function buildCondition(operator, values) {
    const isOperatorType = ['EQ', 'NE', 'BT', 'CT'].includes(operator)

    if (isOperatorType) {
        switch (operator) {
            case 'BT':
                return { type: operator, join: 'AND', values: values }
            default:
                return {
                    type: operator,
                    values: values ? [values[0]] : [''],
                }
        }
    } else {
        return { type: 'EQ', preset: operator }
    }
}

export default function MasterTextSelectComponent(props) {
    const { disablePicker, textConditionFilterDispatch, hasDateTime, handleApply, textOperators = [] } = props
    const [t] = useTranslation('common')
    const classes = useStyles()
    const operators = operatorOptions(t)
    const validOperators = operators.filter((op) => !['EMPTY', 'NOTEMPTY'].includes(op.type))
    const presets = nonDatesValues(t)
    const [textOperatorValue, setTextOperatorValue] = useState(DEFAULT_TEXT_OPERATOR)
    const [displayType, setDisplayType] = useState(null)
    const [values, setValues] = useState([])

    const handleDateOperatorChange = (event) => {
        const value = event.target.value
        setTextOperatorValue(value)
    }

    const [displayOperator, setDisplayOperator] = useState(operators)
    const [displayPresets, setDisplayPresets] = useState(presets)

    useEffect(() => {
        if (isEmpty(textOperators)) return

        const selectedOperators = validOperators.filter(operator => {
            const operatorValue = getConditionString(operator)
            return textOperators.includes(operatorValue)
        })

        const selectedPresets = presets.filter(preset => {
            const presetValue = getConditionString(preset)
            return textOperators.includes(presetValue)
        })

        setDisplayOperator(selectedOperators)
        setDisplayPresets(selectedPresets)
    }, [textOperators])

    useEffect(() => {
        const currentCondition = buildCondition(textOperatorValue, [])

        if (!currentCondition) return

        const { type: operator, preset } = currentCondition
        if (!operator && !preset) return

        textConditionFilterDispatch({
            type: TEXT_CONDITION_FILTER_TYPE.OPERATOR,
            value: currentCondition
        })
        if (preset) {
            setDisplayType(null)
            setValues([])
            return
        } else {
            switch (operator) {
                case 'BT':
                    setDisplayType(VALUE_RENDER_TYPE.RANGE)
                    break
                default:
                    setDisplayType(VALUE_RENDER_TYPE.SINGLE)
            }
        }

        setValues(currentCondition.values || [])
    }, [textOperatorValue])

    const singleInputChanged = (event) => {
        const newValue = [event.target.value]
        setValues(newValue)
        textConditionFilterDispatch({
            type: TEXT_CONDITION_FILTER_TYPE.TEXT_COMPONENT,
            value: newValue
        })
    }

    const rangeInputChanged = (event) => {
        const eventId = event.target.id
        const index = eventId === 'nonDateBetweenInput1' ? 0 : 1;
        const newValue = cloneDeep(values)
        newValue[index] = event.target.value || ''
        setValues(newValue)
        textConditionFilterDispatch({
            type: TEXT_CONDITION_FILTER_TYPE.TEXT_COMPONENT,
            value: newValue
        })
    }

    return <Grid
        container
        sx={hasDateTime ? { paddingTop: '8px' } : {}}
    >
        <Grid item xs md lg>
            <TextField
                disabled={disablePicker}
                id="textOperatorsSelection"
                className={classes.operatorBox}
                size="small"
                select={true}
                label={t('common:filters.operator')}
                color="primary"
                variant="outlined"
                value={textOperatorValue}
                // defaultValue={masterDateTimeOperatorSettings?.type || masterDateTimeOperatorSettings?.name}
                onChange={(e) => {
                    const newOperator = e.target.value
                    setTextOperatorValue(newOperator)
                    handleDateOperatorChange(e)
                }}
            >
                {!isEmpty(displayOperator) && displayOperator.map((option) => (
                    <MenuItem key={option.name} value={option.type} className={classes.menuItem}>
                        {option.name}
                    </MenuItem>
                ))}
                {!isEmpty(displayOperator) && !isEmpty(displayPresets) && <hr className={classes.subheader} />}
                {!isEmpty(displayPresets) && displayPresets.map((option) => (
                    <MenuItem key={option.preset} value={option.preset} className={classes.menuItem}>
                        {option.name}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>
        {displayType === VALUE_RENDER_TYPE.SINGLE && <Grid item xs={8} md={8} lg={8}>
            <TextField
                id="nonDateInput"
                // inputRef={callbackInputRef}
                disabled={disablePicker}
                className={classes.customValueBox}
                value={isEmpty(values) ? '' : values[0]}
                onChange={singleInputChanged}
                label={t('common:filters.value')}
                size="small"
                variant="outlined"
            />
        </Grid>}
        {displayType === VALUE_RENDER_TYPE.RANGE && <Grid item xs={8} md={8} lg={8}>
            <Grid container>
                <Grid item xs md lg>
                    <TextField
                        id="nonDateBetweenInput1"
                        disabled={disablePicker}
                        // inputRef={callbackInputRef}
                        className={classes.customValueBox}
                        value={isEmpty(values) ? '' : (values[0] || '')}
                        onChange={rangeInputChanged}
                        label={t('common:filters.from')}
                        size="small"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs md lg>
                    <TextField
                        id="nonDateBetweenInput2"
                        className={classes.customValueBox}
                        value={isEmpty(values) ? '' : (values[1] || '')}
                        onChange={rangeInputChanged}
                        label={t('common:filters.to')}
                        size="small"
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </Grid>}
        <Grid item xs={1} md={1} lg={1}>
            {hasDateTime
                ? <Fragment></Fragment>
                : <Tooltip
                    title={`Fetch data`}
                    arrow
                    placement="bottom-start"
                    disableInteractive
                    sx={{ flexShrink: 0 }}
                >
                    <Button onClick={() => handleApply(true)}> Apply </Button>
                </Tooltip>}
        </Grid>
    </Grid>
}