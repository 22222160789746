import mime from 'mime-types'
import moment from 'moment/moment'
import {
	CALL_RECORDS_STORAGE,
	CHECK_LOAD_COLUMN_STORAGE,
	MAP_LOGGED_IN_INFO,
	SEARCH_INPUT_STORAGE,
	VIEW_TYPE_NOT_SHOW,
} from '../../constants'
import { isEmpty, isEqual } from 'lodash'

const MAX_SESSIONS_STORAGE_SIZE = 5000000

export const DATE_DATAGRID_FILTER_OPERATORS = {
	IS: 'is',
	IS_NOT: 'not',
	IS_AFTER: 'after',
	IS_ON_OR_AFTER: 'onOrAfter',
	IS_BEFORE: 'before',
	IS_ON_OR_BEFORE: 'onOrBefore',
	IS_EMPTY: 'isEmpty',
	IS_NOT_EMPTY: 'isNotEmpty'
}

export const getProjectName = (projectKey, projects) => {
	if (!projectKey || !projects) return
	if (!projects.length) return

	const found = projects.find((project) => project.key === projectKey)
	if (found) {
		return found.name
	}
	return ''
}

export const getFormName = (formKey, forms) => {
	if (!formKey || !forms) return
	if (!forms.length) return

	const foundForm = forms.find((form) => form.key === formKey)
	if (foundForm) {
		return foundForm.name
	}
	return ''
}

export const getDeviceName = (deviceKey, devices) => {
	if (!deviceKey || !devices) return
	if (!devices.length) return

	const found = devices.find((device) => device.key === deviceKey)
	if (found) {
		return found.name
	}
	return ''
}

export const checkNeedCallQueryAPI = (columnsKeys) => {
	const fullColumnsKeys = window.sessionStorage.getItem('full_columns_keys')
	const fullColumnsKeysData = fullColumnsKeys ? JSON.parse(fullColumnsKeys) : []

	if (fullColumnsKeysData.length === 0) return false
	return columnsKeys.some((key) => !fullColumnsKeysData.includes(key))
}

export function subtractSeconds(numOfSeconds, date = new Date()) {
	const dateCopy = new Date(date.getTime())

	dateCopy.setSeconds(dateCopy.getSeconds() - numOfSeconds)

	return dateCopy
}

export function isNotEqualWithPosition(objectA, objectB) {
	if (objectA.length !== objectB.length) return false
	return JSON.stringify(objectA) !== JSON.stringify(objectB)
}

function createSessionStorage(key) {
	const store = JSON.parse(sessionStorage.getItem(key)) ?? {}

	const save = () => {
		sessionStorage.setItem(key, JSON.stringify(store))
	}

	const storage = {
		get(key) {
			return store[key]
		},
		set(key, value) {
			store[key] = value
			save()
		},
		remove(key) {
			delete store[key]
			save()
		},
	}

	return storage
}
export const callRecordsStorage = createSessionStorage(CALL_RECORDS_STORAGE)
export const searchInputStorage = createSessionStorage(SEARCH_INPUT_STORAGE)

function createLocalStorage(key) {
	const store = JSON.parse(localStorage.getItem(key)) ?? {}

	const save = () => {
		localStorage.setItem(key, JSON.stringify(store))
	}

	const storage = {
		get(key) {
			return store[key]
		},
		set(key, value) {
			store[key] = value
			save()
		},
		remove(key) {
			delete store[key]
			save()
		},
	}

	return storage
}

export const checkLoadColumnStorage = createLocalStorage(CHECK_LOAD_COLUMN_STORAGE)

export const convertToKebabCase = (string) => {
	return string
		.replace(/([a-z])([A-Z])/g, '$1-$2')
		.replace(/[\s_]+/g, '-')
		.toLowerCase()
}

export const getDisplayedViews = (views) => {
	if (!Array.isArray(views) || views?.length === 0) return []
	return views?.filter((view) => !VIEW_TYPE_NOT_SHOW.includes(view.type)) ?? []
}

export function isJson(str) {
	if (!str) return false
	try {
		JSON.parse(str)
	} catch (e) {
		return false
	}
	return true
}

export function logErrorMessage(error) {
	console.error('💥 ERROR::', error)
}

export function getUserTimeZone() {
	// Lấy múi giờ hiện tại của người dùng
	return Intl.DateTimeFormat().resolvedOptions().timeZone
}

function getDateInUserTimeZone(date) {
	const userTimeZone = getUserTimeZone()
	const dateOptions = { timeZone: userTimeZone }
	return new Date(date.toLocaleString('en-US', dateOptions))
}

export function getDefaultDateRange() {
	// Lấy ngày hiện tại
	const currentDate = new Date()

	// Lấy ngày mai
	const tomorrow = new Date(currentDate)
	tomorrow.setDate(currentDate.getDate() + 1)

	// Chuyển đổi ngày và giờ sang múi giờ của người dùng
	const fromDate = getDateInUserTimeZone(currentDate)
	const toDate = getDateInUserTimeZone(tomorrow)

	// Đặt giờ cho ngày bắt đầu và kết thúc
	fromDate.setHours(0, 0, 0, 0)
	toDate.setHours(0, 0, 0, 0)

	return { fromDate, toDate }
}

export function convertKmHToMph(kmH) {
	if (typeof kmH !== 'number') return 0
	const mph = kmH / 1.60934
	return Math.round(mph)
}

export function getMapLoggedInInfoFromStorage() {
	try {
		if (localStorage.getItem(MAP_LOGGED_IN_INFO)) {
			return JSON.parse(localStorage.getItem(MAP_LOGGED_IN_INFO))
		}
		return null
	} catch {
		return null
	}
}

export function checkAdminRole(userCurrent) {
	return userCurrent?.rights?.manage?.includes('update')
}

export function shouldShowDefaultFormSelector(permissions) {
	return permissions?.dispatch?.includes('create') || permissions?.submission?.includes('create')
}

export function hasUpdatingPermission(permissions) {
	return permissions?.submission?.includes('update')
}

export function hasCreatePermission(permissions) {
	return permissions?.submission?.includes('create')
}

export function isNumber(value) {
	if (!value) return false

	return !isNaN(value)
}

export function getAggregationModel(columns) {
	const aggregationModel = {}
	columns.forEach((column) => {
		if (column.aggregate) {
			aggregationModel[column?.name ?? column?.field] = column.aggregate
		}
	})
	return aggregationModel
}

//convert sort
//API: [{ column: 1, order: "DESC" }, { column: 2, order: "ASC" }]
//MUI Grid : [{field:"columnName",sort:"desc"},...]
export function getSortModel(allColumns, sortArr) {
	if (isEmpty(allColumns) || isEmpty(sortArr)) return []
	const sortArray = []
	sortArr.forEach((item) => {
		const column = allColumns[item.column]
		if (column) {
			sortArray.push({
				field: column?.name ?? column?.field,
				sort: item.order.toLowerCase(),
			})
		}
	})
	return sortArray
}

export function getRowGroupingModel(columns) {
	const rowGroupingModel = []
	columns.forEach((column) => {
		if (column.group) {
			rowGroupingModel.push(column.group)
		}
	})
	return [...new Set(rowGroupingModel)]
}

export function areDatesEqual(dateString1, dateString2, format = 'MM/DD/YYYY HH:mm:ss') {
	const date1 = moment(dateString1, format)
	const date2 = moment(dateString2, format)

	return date1.format('mm:ss') === date2.format('mm:ss')
}

export function getFileMimeType(fileType) {
	switch (fileType) {
		// Exception cases
		case 'mov':
			return 'video/mp4'
		// Main case
		default:
			return mime.lookup(fileType) || ''
	}
}

export function isIsoDate(value) {
	return !isNaN(Date.parse(value))
}

export function getColorWithoutId(color) {
	return color.split('-')[1]
}

export function compareDatesWithoutTime(date1, date2) {
	// if compare 2 null
	if (!date1 && !date2) return 0

	const d1 = new Date(date1)
	const d2 = new Date(date2)

	const day1 = d1.getDate()
	const month1 = d1.getMonth()
	const year1 = d1.getFullYear()

	const day2 = d2.getDate()
	const month2 = d2.getMonth()
	const year2 = d2.getFullYear()

	if (year1 === year2 && month1 === month2 && day1 === day2) {
		return 0 // Dates are equal
	} else if (
		year1 > year2 ||
		(year1 === year2 && month1 > month2) ||
		(year1 === year2 && month1 === month2 && day1 > day2)
	) {
		return 1 // First date is greater than the second date
	} else {
		return -1 // First date is smaller than the second date
	}
}

export function compareDateTime(date1, date2) {
	// if compare 2 null value
	if (!date1 && !date2) return 0

	const moment1 = moment(date1)
	const moment2 = moment(date2)

	if (!moment1.isValid() || !moment2.isValid()) return -2

	if (moment1.isSame(moment2)) return 0
	if (moment1.isAfter(moment2)) return 1
	return -1
}

export function compareTimeWithoutDate(time1, time2, format) {
	if (!time1 && !time2) return 0

	const fullTimeFormat = 'h:mm:ss A'
	const momentFormat = format ?? fullTimeFormat
	const moment1 = moment(time1, momentFormat)
	const moment2 = moment(time2, momentFormat)

	if (!moment1.isValid() || !moment2.isValid()) return -2

	if (moment1.isSame(moment2)) return 0
	if (moment1.isAfter(moment2)) return 1
	return -1
}

export function isNullOrEmptyString(valueToCheck) {
	return valueToCheck === undefined || valueToCheck === ''
}

export function sleep(ms) {
	return new Promise((r) => setTimeout(r, ms))
}

export function saveSizedSessionStorage(key, data) {
	const valueRecords = JSON.stringify(data)
	if (valueRecords.length >= MAX_SESSIONS_STORAGE_SIZE) return

	try {
		window.sessionStorage.setItem(key, valueRecords)
	} catch (error) {
		console.log(error)
	}
}

export function getSizedSessionStorage(key) {
	const dataAtIndex = window.sessionStorage.getItem(key)
	const parsedData = JSON.parse(dataAtIndex)
	return parsedData
}

export function getLinkPrefix(url) {
	return '/dis/default' + url
}

function getDatagridOperators(operator, preset, type) {
	const isDateTime = type === 'DATETIME'
	if (preset) {
		switch (preset) {
			case 'EMPTY':
				return [DATE_DATAGRID_FILTER_OPERATORS.IS_EMPTY]
			case 'NOTEMPTY':
				return [DATE_DATAGRID_FILTER_OPERATORS.IS_NOT_EMPTY]
			case 'ALL':
				return null
			case 'TODAY':
			case 'YESTERDAY':
			case 'TOMORROW':
				return isDateTime ? [DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_AFTER, DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_BEFORE] : [DATE_DATAGRID_FILTER_OPERATORS.IS]
			default:
				return [DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_AFTER, DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_BEFORE]
		}
	} else {
		switch (operator) {
			case 'EQ':
			case 'CT':
				return isDateTime ? [DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_AFTER, DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_BEFORE] : [DATE_DATAGRID_FILTER_OPERATORS.IS]
			case 'NE':
				return isDateTime ? [DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_BEFORE, DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_AFTER] : [DATE_DATAGRID_FILTER_OPERATORS.IS_NOT]
			case 'BT':
				return [DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_AFTER, DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_BEFORE]
			default:
				return null
		}
	}
}

export function generateRandomID() {
	return Math.random().toString(36).substring(2)
}

export function checkByDateCondition(valueToCheck, condition, granularity = 'date') {
	if (isEmpty(condition)) return true

	const valueMoment = moment(valueToCheck)
	if (!valueMoment.isValid()) return false

	const { operator, value } = condition
	const expectedValue = moment(value)

	switch (operator) {
		case DATE_DATAGRID_FILTER_OPERATORS.IS:
			return valueMoment.isSame(expectedValue, granularity)
		case DATE_DATAGRID_FILTER_OPERATORS.IS_NOT:
			return !valueMoment.isSame(expectedValue, granularity)
		case DATE_DATAGRID_FILTER_OPERATORS.IS_AFTER:
			return valueMoment.isAfter(expectedValue, granularity)
		case DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_AFTER:
			return valueMoment.isSameOrAfter(expectedValue, granularity)
		case DATE_DATAGRID_FILTER_OPERATORS.IS_BEFORE:
			return valueMoment.isBefore(expectedValue, granularity)
		case DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_BEFORE:
			return valueMoment.isSameOrBefore(expectedValue, granularity)
		case DATE_DATAGRID_FILTER_OPERATORS.IS_EMPTY:
			return isEmpty(valueToCheck?.toString())
		case DATE_DATAGRID_FILTER_OPERATORS.IS_NOT_EMPTY:
			return !isEmpty(valueToCheck?.toString())
		default:
			return false
	}
}

function checkDateCondition(valueToCheck, operator, preset, values = [], granularity) {
	const valueMoment = moment.utc(valueToCheck)
	if (!valueMoment.isValid()) return false

	const fromDateMoment = moment.utc(values[0])
	const toDateMoment = moment.utc(values[1])
	if (preset) {
		switch (preset) {
			case 'EMPTY':
				return isEmpty(valueToCheck?.toString())
			case 'NOTEMPTY':
				return !isEmpty(valueToCheck?.toString())
			case 'ALL':
				return true
			case 'TODAY':
			case 'YESTERDAY':
			case 'TOMORROW':
				return valueMoment.isSame(fromDateMoment, granularity)
			default:
				return valueMoment.isBetween(fromDateMoment, toDateMoment, granularity, '[]')
		}
	} else {
		switch (operator) {
			case 'EQ':
			case 'CT':
				return valueMoment.isSame(fromDateMoment, granularity)
			case 'NE':
				return !valueMoment.isSame(fromDateMoment, granularity)
			case 'BT':
				return valueMoment.isBetween(fromDateMoment, toDateMoment, granularity, '[]')
			default:
				return false
		}
	}
}

function checkTextCondition(valueToCheck, operator, preset, values = []) {
	if (preset) {
		if (preset === 'ALL') return true
		return isEqual(valueToCheck, preset)
	}

	const fromText = values[0]
	const toText = values[1]
	switch (operator) {
		case 'EQ':
		case 'CT':
			return isEqual(valueToCheck, fromText)
		case 'NE':
			return !isEqual(valueToCheck, fromText)
		case 'BT':
			return isEqual(valueToCheck, fromText)
		default:
			return false
	}

}

export function checkByMasterDateTimeCondition(valueToCheck, condition, isDateTimeCondition) {
	if (isEmpty(condition)) return true
	const { type: operator, preset, values } = condition
	if (isDateTimeCondition) {
		return checkDateCondition(valueToCheck, operator, preset, values, 'day')
	}

	return checkTextCondition(valueToCheck, operator, preset, values)
}

export function buildDatagridDateFilterModel(condition, customOptions = {}) {
	const { type: operator, preset, values, columnType } = condition
	const dateOperators = getDatagridOperators(operator, preset, columnType)

	// PRESET === "ALL"
	if (isEmpty(dateOperators)) return []
	const isRange = dateOperators?.length === 2
	if (!isRange) {
		return [{
			...customOptions,
			operator: dateOperators[0],
			value: values[0],
			id: generateRandomID()
		}]
	}

	let valueToCheck = []
	if (values.length === 1) {
		const selectedDate = values[0]

		const fromDateTime = moment(selectedDate)?.clone().startOf('day')
		const endDateTime = moment(selectedDate)?.clone().endOf('day')

		valueToCheck.push(fromDateTime)
		valueToCheck.push(endDateTime)
	} else {
		valueToCheck = values
	}
	return dateOperators.map((operator, index) => ({
		...customOptions,
		operator,
		value: valueToCheck[index],
		id: generateRandomID()
	}))
}
