import { Close as CloseIcon } from '@mui/icons-material'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ACTIVITY_DEVICE_LIST, ACTIVITY_RECEIVE_TIME_KEY } from '../../../constants'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import DoformsDefaultForm from '../../../custom-components/DoformsDefaultForm'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import {
	callRecordsStorage,
	checkLoadColumnStorage,
	checkNeedCallQueryAPI,
	subtractSeconds,
	getLinkPrefix,
} from '../../../utils/functions/helpers'
import { useFirstRender } from 'utils/hooks/useFirstRender'
import useGetPathName from 'utils/hooks/useGetPathName'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import SkeletonLoaderDataGrid from '../../../custom-components/skeletons/SkeletonLoaderDataGrid'
import { DEVICES_ACTIONS } from '../../../reducers/devicesReducer'
import DoformsDataGrid from '../../data/datagrid/DoformsDataGrid'
import UpdateAlert from '../../data/datagrid/UpdateAlert'
import {
	cancelLoadNextRecordsAction,
	getActivityDeviceView,
	getDeviceViewRecords,
	loadDeviceRecordsQuery,
	loadRecordsQuery,
} from '../../data/dataServices'
import DoformsSendDispatch from '../../data/dispatch/DoformsSendDispatch'
import DoformsMap from '../../data/map/DoformsMap'
import useGetCommonFunc from 'utils/hooks/useGetCommonFunc'

const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		padding: '8px',
		'& .MuiButton-root': {
			textDecoration: 'none',
		},
	},
	dataGridToolbar: {
		minHeight: 20,
		paddingLeft: '0px',
		paddingBottom: '8px',
	},
	dialog: {
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	dialogTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
	},
	loading: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		position: 'absolute',
		top: '50%',
		left: '50%',
	},
	headingContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignContent: 'center',
		alignItems: 'center',
		paddingLeft: '10px',
	},
	headingContent: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'baseline',
	},
	headingLabel: {
		display: 'block',
		minWidth: '200px',
	},
	headingAction: {
		display: 'flex',
		alignItems: 'center',
	},
	headingProjectSelection: {
		display: 'inline-flex',
		width: '24em',
		maxWidth: '24em',
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
	datagrid: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: '30px',
		padding: '8px',
	},
}))

const DevicesData = ({ environment, module }) => {
	const [t] = useTranslation('common')

	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const isFirstRender = useFirstRender()

	const apiToken = environment.apiToken
	const {
		action,
		dataGrid: { columns, query, queryView, records, viewSession },
		formDeleteSelection,
		deviceRefresh,
		deviceSelected,
		formColumns,
		formFilterConditions,
		formSelected,
		viewCreate,
		viewData,
		viewUpdate,
		rowsPerPage,
		clientFilters,
	} = module
	const firstPathName = useGetPathName()
	const dispatch = useDispatch()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [info, setInfo] = useState(null)
	const [linkViewInfo, setLinkViewInfo] = useState(null)

	const [hasGetViewData, setHasGetViewData] = useState(false)
	const [allRecordsLoading, setAllRecordsLoading] = useState(false)
	const [startLoadAllRecords, setStartLoadAllRecords] = useState(false)
	const [recordLoading, setRecordLoading] = useState(false)
	const [actionView, setActionView] = useState(null)
	const [isCancelLoadingRecords, setIsCancelLoadingRecords] = useState(false)

	const { getAllRecords, loadUpdateColumns } = useGetCommonFunc({
		tab: 'devices',
		firstPathName,
		viewData,
		query,
		queryView,
		viewSession,
		apiToken,
		records,
		formColumns,
		setAllRecordsLoading,
		setStartLoadAllRecords,
		setLoading,
		setError,
	})

	useEffect(() => {
		// Checked when change column then switch tab will call back api to get correct data
		;(async () => {
			if (
				!_.isEmpty(formColumns) &&
				viewData.key &&
				isFirstRender &&
				checkLoadColumnStorage.get(firstPathName)
			) {
				await loadUpdateColumns()
				checkLoadColumnStorage.set(firstPathName, false)
				setRecordLoading(true)
			}
		})()
	}, [])

	useEffect(() => {
		setActionView(null)
	}, [action, deviceSelected.key])

	useEffect(() => {
		if (recordLoading) {
			loadRecords()
			setRecordLoading(false)
		}
	}, [recordLoading])

	useEffect(() => {
		if (!action) return
		if (!_.isEmpty(queryView)) return
		dispatch({
			type: DEVICES_ACTIONS.ACTION,
			payload: action,
		})
		dispatch({
			type: DEVICES_ACTIONS.DEVICE_SELECTED,
			payload: deviceSelected,
		})
	}, [action, deviceSelected])

	useEffect(() => {
		if (action === 'activity') return
		const cancelToken = axios.CancelToken.source()

		const getViewData = () => {
			if (!formSelected.key || !formSelected.projectKey) return
			setLoading(true)
			getDeviceViewRecords(
				formSelected.key,
				formSelected.projectKey,
				deviceSelected.number,
				viewSession,
				apiToken,
				cancelToken
			)
				.then((response) => {
					let responseData = response.data
					const clientFilter = responseData?.clientFilter
						? JSON.parse(responseData?.clientFilter)
						: undefined
					if (clientFilter) {
						const clientFilterKey = deviceSelected?.key ?? ''
						dispatch({
							type: DEVICES_ACTIONS.CLIENT_FILTERS,
							payload: {
								...clientFilters,
								[clientFilterKey]: clientFilter,
							},
						})
					}
					dispatch({
						type: DEVICES_ACTIONS.VIEW_DATA,
						payload: responseData,
					})
					dispatch({
						type: DEVICES_ACTIONS.DATA_GRID,
						payload: {
							columns: responseData.columns,
							query: responseData.queries[0],
						},
					})
					setRecordLoading(true)
				})
				.catch((err) => {
					if (!axios.isCancel(err)) {
						setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
					}
				})
		}

		if (!_.isEmpty(deviceSelected) && !_.isEmpty(formSelected)) {
			if (!hasGetViewData && _.isEmpty(queryView)) {
				getViewData()
			} else {
				setHasGetViewData(false)
			}
		}

		return () => {
			cancelToken.cancel()
		}
	}, [deviceSelected, formSelected])

	useEffect(() => {
		if (action !== 'activity') return
		const cancelToken = axios.CancelToken.source()

		const getActivityViewData = async () => {
			try {
				setLoading(true)
				const response = await getActivityDeviceView(deviceSelected.key, apiToken, cancelToken)
				const responseData =
					response.data.queries[0].filter.conditions.findIndex(
						(item) => item.target === ACTIVITY_RECEIVE_TIME_KEY
					) !== -1
						? response.data
						: {
								...response.data,
								queries: response.data.queries.map((item, index) =>
									index === 0
										? {
												...item,
												filter: {
													...item.filter,
													conditions: [
														{
															target: ACTIVITY_RECEIVE_TIME_KEY,
															type: 'EQ',
															values: [],
															preset: 'TODAY',
															join: 'AND',
														},
														...item.filter.conditions,
													],
												},
										  }
										: item
								),
						  }
				const clientFilter = responseData?.clientFilter
					? JSON.parse(responseData?.clientFilter)
					: undefined
				if (clientFilter) {
					const clientFilterKey = deviceSelected?.key ?? ''
					dispatch({
						type: DEVICES_ACTIONS.CLIENT_FILTERS,
						payload: {
							...clientFilters,
							[clientFilterKey]: clientFilter,
						},
					})
				}
				dispatch({
					type: DEVICES_ACTIONS.VIEW_DATA,
					payload: responseData,
				})
				dispatch({
					type: DEVICES_ACTIONS.DATA_GRID,
					payload: {
						columns: responseData.columns,
						query: responseData.queries[0],
						queryView: {
							queries: responseData.queries,
						},
					},
				})
				setRecordLoading(true)
			} catch (err) {
				if (!axios.isCancel(err)) {
					setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
				}
			}
		}

		if (!_.isEmpty(deviceSelected)) {
			if (!hasGetViewData && _.isEmpty(queryView)) {
				getActivityViewData()
			} else {
				setHasGetViewData(false)
			}
		}
		dispatch({
			type: DEVICES_ACTIONS.FORM_COLUMNS,
			payload: [],
		})
		checkLoadColumnStorage.set(firstPathName, false)

		return () => {
			cancelToken.cancel()
		}
	}, [deviceSelected])

	useEffect(() => {
		if (_.isEmpty(environment.deviceAuditForm)) return
		if (
			_.isEqual(environment.deviceAuditForm.projectKey, formSelected.projectKey) &&
			_.isEqual(environment.deviceAuditForm.formKey, formSelected.key)
		)
			return
		if (!environment.projects.length) return
		const matchProject = environment.projects.find(
			(project) => project.key === environment.deviceAuditForm.projectKey
		)
		const matchForm = matchProject.forms.find(
			(form) => form.key === environment.deviceAuditForm.formKey
		)

		if (matchProject && matchForm) {
			const newFormSelected = {
				...matchForm,
				projectKey: matchProject.key,
				projectName: matchProject.name,
			}
			dispatch({
				type: DEVICES_ACTIONS.FORM_SELECTED,
				payload: newFormSelected,
			})
			dispatch({
				type: DEVICES_ACTIONS.DATA_GRID,
				payload: {
					queryView: {},
					records: [],
				},
			})
		}
	}, [environment.deviceAuditForm])

	useEffect(() => {
		if (_.isEmpty(formFilterConditions)) return
		if (query.filter?.conditions && _.isEqual(formFilterConditions, query.filter.conditions)) return
		dispatch({
			type: DEVICES_ACTIONS.DATA_GRID,
			payload: {
				query: {
					...query,
					filter: {
						conditions: formFilterConditions,
					},
				},
			},
		})
		setRecordLoading(true)
	}, [formFilterConditions])

	useEffect(() => {
		if (startLoadAllRecords) {
			setStartLoadAllRecords(false)
			try {
				getAllRecords()
			} catch (err) {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
			} finally {
			}
		}
	})

	useEffect(() => {
		if (isFirstRender) return
		checkLoadColumnStorage.set(firstPathName, true)
	}, [formColumns])

	useEffect(() => {
		if (formDeleteSelection.length === 0) return
		setInfo(null)
		const selectedRecords = formDeleteSelection
		if (viewData.type === 'RECORD') {
			dispatch({
				type: DEVICES_ACTIONS.DATA_GRID,
				payload: {
					records: records.filter((rec) =>
						rec.type === 'DISPATCH'
							? !selectedRecords.includes(rec.dispatchKey)
							: !selectedRecords.includes(rec.submissionKey)
					),
				},
			})
		}
		const infoMessage =
			selectedRecords.length > 1
				? t('common:misc.successfullyDeleted') +
				  ` ${selectedRecords.length} ` +
				  t('common:misc.records') +
				  `.`
				: t('common:misc.successfullyDeleted1Record')
		setInfo(infoMessage)
		dispatch({
			type: DEVICES_ACTIONS.FORM_DELETE_SELECTION,
			payload: [],
		})
	}, [formDeleteSelection])

	useEffect(() => {
		;(async () => {
			try {
				if (_.isEmpty(formColumns) || isFirstRender) return
				setLoading(true)
				if (checkNeedCallQueryAPI(formColumns.map((item) => item.name)) || isCancelLoadingRecords) {
					if (!_.isEmpty(formColumns)) {
						await loadUpdateColumns()
					}
					setRecordLoading(true)
					setIsCancelLoadingRecords(false)
					checkLoadColumnStorage.set(firstPathName, false)
				} else {
					dispatch({
						type: DEVICES_ACTIONS.DATA_GRID,
						payload: {
							columns: formColumns,
							viewSession: true,
						},
					})
					setLoading(false)
				}
			} catch (err) {
				setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
				setLoading(false)
			}
		})()
	}, [formColumns])

	useEffect(() => {
		;(async () => {
			if (!deviceRefresh) return
			try {
				setIsCancelLoadingRecords(false)
				if (ACTIVITY_DEVICE_LIST.includes(viewData.type)) {
					await getActivityDeviceView(deviceSelected.key, apiToken)
				}
				if (
					!_.isEmpty(formColumns) &&
					!isFirstRender &&
					checkLoadColumnStorage.get(firstPathName)
				) {
					await loadUpdateColumns()
					checkLoadColumnStorage.set(firstPathName, false)
				}
				loadRecords()
			} catch (err) {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
			}
		})()
	}, [deviceRefresh])

	useEffect(() => {
		if (_.isEmpty(viewUpdate)) return
		setInfo(t('formsData.successfullySavedChanges'))
		dispatch({
			type: DEVICES_ACTIONS.VIEW_UPDATE,
			payload: {},
		})
	}, [viewUpdate])

	useEffect(() => {
		if (_.isEmpty(viewCreate)) return
		setInfo(t('common:misc.successfullyCreatedView') + ' ')
		setLinkViewInfo(viewCreate)
		dispatch({
			type: DEVICES_ACTIONS.VIEW_CREATE,
			payload: {},
		})
	}, [viewCreate])

	const loadRecords = () => {
		const isActivityViewDataType = ACTIVITY_DEVICE_LIST.includes(viewData.type)
		setLoading(true)
		loadDeviceRecordsQuery(
			viewData.key,
			viewData.deviceNumber,
			viewSession,
			query.filter,
			apiToken,
			isActivityViewDataType
		)
			.then((response) => {
				let queries = [...response.data.view.queries]
				queries[0].filter = { ...query.filter }
				dispatch({
					type: DEVICES_ACTIONS.DATA_GRID,
					payload: {
						queryView: { ...response.data.view, queries: queries },
						records: response.data.records,
					},
				})
			})
			.catch((err) => {
				setError(
					err.response
						? 'Code ' + err.response.data.code + ': ' + err.response.data.message
						: err.message
				)
			})
			.finally(() => {
				setLoading(false)
				dispatch({
					type: DEVICES_ACTIONS.DEVICE_REFRESH,
					payload: false,
				})
				callRecordsStorage.remove(firstPathName)
			})
	}

	const handleReLoadFirstPage = async (viewData, lastUpdatedTime) => {
		setLoading(true)

		const endOfDay = new Date()
		endOfDay.setHours(23, 59, 59, 999)

		const newDate = new Date(`${lastUpdatedTime} +0`)
		const lastUpdatedTimeMinus5Second = subtractSeconds(5, newDate)

		let filter
		const isRightColumnMissingItem = callRecordsStorage.get(firstPathName)
		if (isRightColumnMissingItem) {
			filter = query.filter
		} else {
			filter = {
				conditions: query.filter.conditions.map((item) =>
					item.target === ACTIVITY_RECEIVE_TIME_KEY
						? {
								target: ACTIVITY_RECEIVE_TIME_KEY,
								type: 'BT',
								join: 'AND',
								values: [lastUpdatedTimeMinus5Second, endOfDay],
						  }
						: item
				),
			}
		}

		if (!_.isEmpty(formColumns)) {
			await loadUpdateColumns()
		}
		loadRecordsQuery(viewData.key, viewSession, filter, apiToken)
			.then((response) => {
				const newRecords = _.uniqBy([...response.data.records, ...records], 'activityKey')
				dispatch({
					type: DEVICES_ACTIONS.DATA_GRID,
					payload: {
						records: newRecords,
					},
				})
				setLoading(false)
				callRecordsStorage.remove(firstPathName)
				return newRecords
			})
			.catch((err) => {
				setLoading(false)
				setError(
					err.response
						? 'Code ' + err.response.data.code + ': ' + err.response.data.message
						: err.message
				)
				return err
			})
	}

	const handlePresetReloadAll = () => {
		let queries = [].concat(queryView.queries)
		queries[0] = { ...queries[0], cursor: null, more: true }
		dispatch({
			type: DEVICES_ACTIONS.DATA_GRID,
			payload: {
				queryView: { ...queryView, more: true, queries: queries },
			},
		})
	}

	const showLoading = () => {
		if (loading)
			return (
				<>
					<LoadingSpinner />
					{_.isEmpty(viewData) && <SkeletonLoaderDataGrid />}
				</>
			)
	}

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const showInfoMessage = () =>
		info && (
			<DoformsMessage
				linkViewInfo={linkViewInfo}
				message={info}
				severity={'success'}
				onMessageClosed={handleMessageClosed}
			/>
		)

	const handleMessageClosed = () => {
		setError(null)
		setInfo(null)
	}

	const handleResizeColumn = (selectedField, newWidth) => {
		const newColumns = columns.map((col) => {
			if (col.name === selectedField) {
				col.width = newWidth
			}
			return col
		})
		dispatch({
			type: DEVICES_ACTIONS.DATA_GRID,
			payload: {
				columns: newColumns,
			},
		})
	}

	const handleLoadAllRecords = () => {
		setStartLoadAllRecords(true)
	}

	const handleCancelLoadingRecords = () => {
		setIsCancelLoadingRecords(true)
		setStartLoadAllRecords(false)
		cancelLoadNextRecordsAction()
	}

	const showMapAction = () =>
		action === 'map' && (
			<DoformsMap
				action={'map'}
				tab={'devices'}
				title={''}
				environment={environment}
				viewData={viewData}
				formSelected={formSelected}
				deviceSelected={deviceSelected}
				module={module}
				onHandleOpenFullScreenFormsTab={onHandleOpenFullScreenTabMap}
				onHandleCloseMap={onHandleCloseMap}
			/>
		)
	const showAuditAction = () =>
		action === 'audit' && (
			<DoformsDataGrid
				action={action}
				isLoading={loading}
				key={`audit${deviceSelected.key}`}
				deviceSelected={deviceSelected}
				setActionView={setActionView}
				isRefresh={deviceRefresh}
				title={''}
				environment={environment}
				query={query}
				viewData={viewData}
				formSelected={formSelected}
				columns={columns}
				records={records}
				queryView={queryView}
				clientFilters={clientFilters}
				recordsLoading={false}
				allRecordsLoading={allRecordsLoading}
				tab={'devices'}
				rowsPerPage={rowsPerPage}
				setError={setError}
				onHandledResizeColumn={handleResizeColumn}
				onHandledLoadAllRecords={handleLoadAllRecords}
				onHandledCancelLoadingRecords={handleCancelLoadingRecords}
				onHandleOpenFullScreenDevicesTab={onHandleOpenFullScreenTabDevice}
				setLoading={setLoading}
			/>
		)

	const showActivityAction = () =>
		action === 'activity' && (
			<DoformsDataGrid
				action={action}
				isLoading={loading}
				key={`activity${deviceSelected.key}`}
				isRefresh={deviceRefresh}
				title={''}
				environment={environment}
				query={query}
				viewData={viewData}
				formSelected={formSelected}
				columns={columns}
				records={records}
				queryView={queryView}
				clientFilters={clientFilters}
				recordsLoading={false}
				allRecordsLoading={allRecordsLoading}
				deviceSelected={deviceSelected}
				tab={'devices'}
				rowsPerPage={rowsPerPage}
				setError={setError}
				onHandledResizeColumn={handleResizeColumn}
				onHandledLoadAllRecords={handleLoadAllRecords}
				onHandledCancelLoadingRecords={handleCancelLoadingRecords}
				onHandleOpenFullScreenDevicesTab={onHandleOpenFullScreenTabDevice}
				onHandleReLoadFirstPage={handleReLoadFirstPage}
				onHandlePresetReloadAll={handlePresetReloadAll}
				setLoading={setLoading}
			/>
		)

	const showTitleForDeviceTab = () => (
		<>
			{deviceSelected && (
				<div className={classes.headingContainer}>
					<UpdateAlert
						key={deviceSelected.name}
						formSelected={formSelected}
						viewData={viewData}
						queryView={queryView}
						deviceSelected={deviceSelected}
						onHandlePresetReloadAll={handlePresetReloadAll}
						onHandleReLoadFirstPage={handleReLoadFirstPage}
						viewDataType={viewData.type}
					>
						<div className={classes.headingContent}>
							<Typography className={classes.headingLabel} variant={'h6'}>
								{deviceSelected.name || deviceSelected.number || deviceSelected.teamName}
							</Typography>
							{!actionView &&
								action === 'audit' &&
								!_.isEmpty(
									deviceSelected.name || deviceSelected.number || deviceSelected.teamName
								) && (
									<div className={classes.headingProjectSelection}>
										<DoformsDefaultForm
											tab="devices"
											source={'grid'}
											formSelected={formSelected}
											viewData={viewData}
											queryView={queryView}
											deviceSelected={deviceSelected}
											onHandlePresetReloadAll={handleReLoadFirstPage}
											onHandleReLoadFirstPage={handlePresetReloadAll}
										/>
									</div>
								)}
						</div>
					</UpdateAlert>
					{action === 'map' && (
						<div className={classes.headingAction}>
							<Tooltip
								title={`${t('tooltip.close')}`}
								arrow
								placement="bottom-start"
								disableInteractive
							>
								<span>
									<IconButton
										aria-label="Close map"
										size="small"
										color="primary"
										edge="end"
										onClick={() => onHandleCloseMap()}
									>
										<CloseIcon fontSize="inherit" className={classes.icon} />
									</IconButton>
								</span>
							</Tooltip>
						</div>
					)}
				</div>
			)}
		</>
	)

	const onHandleOpenFullScreenTabMap = () => {
		onHandleOpenFullScreenTab()
	}

	const onHandleOpenFullScreenTabDevice = () => {
		if (action == 'map') {
			dispatch({
				type: DEVICES_ACTIONS.DEVICES_OPEN_NEW_SCREEN_MAP,
				payload: true,
			})
		} else {
			onHandleOpenFullScreenTab()
		}
	}

	const onHandleOpenFullScreenTab = () => {
		localStorage.setItem('newFullScreenData_env', JSON.stringify({ environment: environment }))
		localStorage.setItem('newFullScreenData_mod', JSON.stringify({ devicesModule: module }))
		//window.open('/devices/' + deviceSelected.key + '?action=' + action, '_blank_' + Math.random())
		window.open(getLinkPrefix('/devices'), '_blank_' + Math.random())
	}

	const onHandleCloseMap = () => {
		dispatch({
			type: DEVICES_ACTIONS.ACTION,
			payload: 'audit',
			showMapCloseIcon: false,
		})
	}

	return (
		<div className={classes.root}>
			{showLoading()}
			{showErrorMessage()}
			{showInfoMessage()}
			{action && showTitleForDeviceTab()}
			{viewData && showMapAction()}
			{viewData && showActivityAction()}
			{viewData && showAuditAction()}
			{!environment.isReadOnlyDispatch && !formSelected.abandoned && (
				<DoformsSendDispatch
					environment={environment}
					formSelected={formSelected}
					action={'send'}
					title={''}
					tab={'devices'}
					source={'dashboard'}
				/>
			)}
		</div>
	)
}

export default DevicesData
