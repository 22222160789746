import { createContext, useState } from 'react'

export const IconThemeContext = createContext()

export const IconThemeProvider = (props) => {
	const { values } = props
	const [iconTheme, setIconTheme] = useState(values)

	return (
		<IconThemeContext.Provider value={{ iconTheme, setIconTheme }}>
			{props.children}
		</IconThemeContext.Provider>
	)
}
