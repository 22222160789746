import { DefaultMenuPage, EmptyMenuPage } from 'components/pages/dis-v2/menu'
import MenuDetailPage from 'components/pages/dis-v2/menu-items'
import { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import DisLayout from './components/core/Layouts/DisLayout'
import LoadingSpinner from './custom-components/LoadingSpinner'

const Dashboard = lazy(() => import('./components/pages/dis/dashboard/Dashboard'))
const DefaultDashboard = lazy(() => import('./components/pages/dis/dashboard/DefaultDashboard'))

const Routes = () => {
	return (
		<Suspense fallback={<LoadingSpinner />}>
			<Switch>
				<Route exact path="/dis" render={() => <DisLayout></DisLayout>} />

				<Route
					exact
					path="/dis/menu-manager"
					render={() => (
						<DisLayout>
							<EmptyMenuPage />
						</DisLayout>
					)}
				/>
				<Route
					exact
					path="/dis/menu-manager/form"
					render={() => (
						<DisLayout>
							<DefaultMenuPage />
						</DisLayout>
					)}
				/>
				<Route
					exact
					path="/dis/menu-manager/form/menu-items"
					render={() => (
						<DisLayout>
							<MenuDetailPage />
						</DisLayout>
					)}
				/>

				<Route
					exact
					path="/dis/:id"
					render={() => (
						<DisLayout>
							<Dashboard />
						</DisLayout>
					)}
				/>
				<Route
					exact
					path="/dis/default/:id"
					render={() => (
						<DisLayout>
							<DefaultDashboard />
						</DisLayout>
					)}
				/>
				<Route path="*">
					<Redirect to="/dis" />
				</Route>
			</Switch>
		</Suspense>
	)
}

export default Routes
