import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'

const firebaseConfig = {
	apiKey: 'AIzaSyB1k7bh013J8OWcoUFwLrR-OwyuwTrshfU',
	authDomain: 'mydoforms-hrd.firebaseapp.com',
	databaseURL: 'https://mydoforms-hrd.firebaseio.com',
	projectId: 'mydoforms-hrd',
	storageBucket: 'mydoforms-hrd.appspot.com',
	messagingSenderId: '793359194105',
}

const app = initializeApp(firebaseConfig)
const database = getDatabase(app)

export default database
