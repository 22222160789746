import axios from 'axios'
import { checkLoadColumnStorage } from 'utils/functions/helpers'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	loadNextRecordsQuery,
	loadRecordsQuery,
	updateColumns,
} from '../../components/data/dataServices'
import { ACTIVITY_ACTIONS } from '../../reducers/activityReducer'
import { DEVICES_ACTIONS } from '../../reducers/devicesReducer'
import { FORM_ACTIONS } from '../../reducers/formsReducer'
import { LOOKUPS_ACTIONS } from '../../reducers/lookupsReducer'
import { VIEWS_ACTIONS } from '../../reducers/viewsReducer'

export default function useGetCommonFunc({
	tab,
	firstPathName,
	viewData,
	query,
	queryView,
	viewSession,
	apiToken,
	records,
	formColumns,
	setAllRecordsLoading,
	setStartLoadAllRecords,
	setLoading,
	setError,
}) {
	const dispatch = useDispatch()
	const [fallbackRecords, setFallbackRecords] = useState(null)

	const handleDispatch = (typeName, payloadParams) => {
		switch (tab) {
			case 'lookups':
				dispatch({
					type: LOOKUPS_ACTIONS[typeName],
					payload: payloadParams,
				})
				break
			case 'views':
				dispatch({
					type: VIEWS_ACTIONS[typeName],
					payload: payloadParams,
				})
				break
			case 'devices':
				dispatch({
					type: DEVICES_ACTIONS[typeName],
					payload: payloadParams,
				})
				break
			case 'activity':
				dispatch({
					type: ACTIVITY_ACTIONS[typeName],
					payload: payloadParams,
				})
				break
			default: //FORMS
				dispatch({
					type: FORM_ACTIONS.DATA_GRID,
					payload: payloadParams,
				})
				break
		}
	}

	const handleLoadNextRecordsQuery = async (currentRecords) => {
		try {
			const response = await loadNextRecordsQuery(queryView, viewSession, apiToken)
			let newDatas = [].concat(currentRecords ?? records)
			response.data.records.forEach((item) => {
				const existedRecords = newDatas.filter((filterItem, index) => {
					if (item.submissionKey === filterItem.submissionKey) {
						newDatas[index] = item
						return true
					}
					return false
				})
				if (existedRecords.length === 0) {
					newDatas.push(item)
				}
			})

			handleDispatch('DATA_GRID', {
				queryView: response.data.view,
				records: newDatas,
			})

			if (response.data.records.length === 0) {
				setAllRecordsLoading(false)
				setStartLoadAllRecords(false)
			} else {
				setStartLoadAllRecords(true)
			}
			setLoading(false)
		} catch (err) {
			setAllRecordsLoading(false)
			setLoading(false)
			if (!axios.isCancel(err)) {
				setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
			}
		}
	}

	// ==================== COMMON FUNCTIONS ====================

	const getAllRecords = async () => {
		try {
			let response

			setAllRecordsLoading(true)
			setLoading(true)

			// call this api exactly 1 time
			if (!fallbackRecords) {
				if (checkLoadColumnStorage.get(firstPathName)) {
					await loadUpdateColumns()
					checkLoadColumnStorage.set(firstPathName, false)
				}
				response = await loadRecordsQuery(viewData.key, viewSession, query.filter, apiToken)
				setFallbackRecords(response.data.records)

				handleDispatch('DATA_GRID', {
					queryView: response.data.view,
					records: response.data.records,
				})
			}

			handleLoadNextRecordsQuery(response?.data?.records ?? records)
		} catch (err) {
			setAllRecordsLoading(false)
			setLoading(false)
			if (!axios.isCancel(err)) {
				setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
			}
		}
	}

	async function loadUpdateColumns() {
		try {
			setFallbackRecords(null)
			setLoading(true)
			const newViewSession = true
			await updateColumns({
				viewKey: viewData.key,
				columnsArr: formColumns,
				viewSession: newViewSession,
				token: apiToken,
			}).then((response) => {
				const columnsData = response.data
				handleDispatch('DATA_GRID', {
					columns: columnsData,
					viewSession: true,
				})
			})
			setLoading(false)
		} catch (err) {
			setLoading(false)
			setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
		}
	}

	return { getAllRecords, loadUpdateColumns }
}
