import React, { useState, useEffect } from 'react'
import {
	ArrowDropDown,
	ArrowDropUp,
	FilterCenterFocus,
	MapsHomeWork,
	PlayArrow,
	Refresh,
	ViewList,
	OpenInNew as OpenInNewIcon,
	QuestionMark,
} from '@mui/icons-material'
import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	IconButton,
	InputAdornment,
	MenuItem,
	Popover,
	Radio,
	RadioGroup,
	TextField,
	Tooltip,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from '../dataHelpers'
import enUS from 'date-fns/locale/en-US'
import moment from 'moment'
import { HELP_LINK_MAP } from '../../../constants'

const useStyles = makeStyles(() => ({
	locationData: {
		display: 'flex',
		flex: '0 0 auto',
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		paddingBottom: '10px',
		'& .MuiOutlinedInput-input': {
			cursor: 'pointer',
		},
	},
	mapActions: {
		display: 'flex',
		flexDirection: 'row',
	},
	customIconButton: {
		'&:hover, &.Mui-focusVisible': (props) => ({
			backgroundColor: props.active.color,
		}),
	},
	dateBox: {
		width: 175,
		display: 'inline-flex',
		paddingRight: '10px',
	},
	statusBox: {
		minWidth: 200,
		display: 'inline-flex',
		paddingRight: '10px',
	},
	detailBox: {
		width: 130,
		display: 'inline-flex',
		paddingRight: '10px',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				marginTop: '5px',
			},
		},
		'& .MuiOutlinedInput-notchedOutline': {
			'& legend': {
				display: 'none',
			},
		},
	},
}))

const DoformsMapFilters = (props) => {
	const [t] = useTranslation('common')
	const {
		environment,
		selectedActions,
		dateValue,
		detailState,
		reportState,
		onHandledDate,
		onHandledActions,
		onHandledDetails,
		onHandledReports,
		tab,
		selectedOption,
		module,
		onHandleOpenFullScreenTab,
	} = props
	const classes = useStyles(environment.theme.icons)

	const statusOptions = [{ name: 'ALL', label: t('common:filters.all') }]
	const radioOptions = ['details', 'report']

	const [status, setStatus] = useState(statusOptions[0].name)
	const [detailAnchorEl, setDetailAnchorEl] = useState(null)
	const [reportAnchorEl, setReportAnchorEl] = useState(null)
	const [radioOptionCheck, setRadioOptionCheck] = useState(
		!selectedOption || selectedOption == 'details' || selectedOption == 'detail'
			? radioOptions[0]
			: radioOptions[1]
	)

	const isDetailsOpen = Boolean(detailAnchorEl)
	const isReportOpen = Boolean(reportAnchorEl)
	const [locale, setLocale] = useState(enUS)
	const [isOpenDate, setIsOpenDate] = useState(false)

	useEffect(() => {
		const importLocaleFile = async () => {
			const localeToSet = await import(
				`date-fns/locale/${t('common:languages.dateFnsLocale')}/index.js`
			)
			setLocale(localeToSet.default)
		}

		if (locale.code !== t('common:languages.dateFnsLocale')) {
			importLocaleFile()
		}
	}, [t('common:languages.dateFnsLocale')])

	const handleDetailsOpen = (event) => {
		event.preventDefault()
		handleReportClose()
		setDetailAnchorEl(event.currentTarget)
	}

	const handleReportsOpen = (event) => {
		event.preventDefault()
		handleDetailsClose()
		setReportAnchorEl(event.currentTarget)
	}

	const handleDetailsClose = () => {
		setDetailAnchorEl(null)
	}

	const handleReportClose = () => {
		setReportAnchorEl(null)
	}

	const handleDetailCheck = (event) => {
		setRadioOptionCheck(radioOptions[0])
		onHandledDetails(event)
		handleDetailsClose()
	}

	const handleReportCheck = (event) => {
		setRadioOptionCheck(radioOptions[1])
		onHandledReports(event)
		handleReportClose()
	}

	const handleSelectedDetailCheck = (event) => {
		setRadioOptionCheck(radioOptions[0])
		onHandledDetails(undefined)
	}

	const handleSelectedReportCheck = (event) => {
		setRadioOptionCheck(radioOptions[1])
		onHandledReports(undefined)
	}

	const handleDateChange = (newValue, isOnBlur) => {
		if (isOnBlur) {
			const newValueWhenBlur = new Date(newValue)
			if (
				newValueWhenBlur &&
				(newValueWhenBlur instanceof Date ||
					(moment.isMoment(newValueWhenBlur) && newValueWhenBlur.isValid()))
			) {
				onHandledDate(newValueWhenBlur)
			}
		} else {
			if (!isOpenDate) return
			if (
				newValue &&
				(newValue instanceof Date || (moment.isMoment(newValue) && newValue.isValid()))
			) {
				onHandledDate(newValue)
			}
		}
	}

	const handleStatusChange = (event) => {
		setStatus(event.target.value)
	}

	const handleAction = (target) => {
		onHandledActions(target)
	}

	const handleRadioOptionsChange = (event) => {
		setRadioOptionCheck(event.target.value || radioOptions[0])
	}

	const handleGoToHelpLink = () => {
		window.open(HELP_LINK_MAP, '_blank')
	}

	const showDatePicker = () => {
		return (
			<LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
				<DatePicker
					id="map-date"
					label={t('common:filters.date')}
					value={dateValue}
					onChange={(newValue) => handleDateChange(newValue, false)}
					onOpen={() => setIsOpenDate(true)}
					onClose={() => setIsOpenDate(false)}
					renderInput={(params) => (
						<TextField
							{...params}
							onBlur={(e) => handleDateChange(e.target.value, true)}
							className={classes.dateBox}
							size="small"
							variant="outlined"
							label={t('common:filters.date')}
						></TextField>
					)}
				/>
			</LocalizationProvider>
		)
	}

	const showStatusOptions = () => (
		<TextField
			id="map-status"
			className={classes.statusBox}
			size="small"
			color="primary"
			variant="outlined"
			select={true}
			label={t('common:filters.status')}
			value={status}
			onChange={handleStatusChange}
		>
			{statusOptions.map((option, index) => (
				<MenuItem key={index} value={option.name}>
					{option.label}
				</MenuItem>
			))}
		</TextField>
	)

	const showRadioOptions = () => (
		<FormControl>
			<RadioGroup
				row
				aria-labelledby="radio-btn-group"
				sx={{ ml: 1.5 }}
				name="radio-btn-group"
				defaultValue={radioOptions[0]}
				onChange={handleRadioOptionsChange}
			>
				<FormControlLabel
					sx={{ mr: 0.5 }}
					control={
						<Radio
							checked={radioOptionCheck === radioOptions[0]}
							onChange={handleSelectedDetailCheck}
						/>
					}
					label={showDetails(radioOptionCheck === radioOptions[0] ? 1 : 0)}
					value={radioOptions[0]}
				/>
				{tab === 'devices' && (
					<FormControlLabel
						control={
							<Radio
								checked={radioOptionCheck === radioOptions[1]}
								onChange={handleSelectedReportCheck}
							/>
						}
						label={showReport(radioOptionCheck === radioOptions[1] ? 1 : 0)}
						value={radioOptions[1]}
					/>
				)}
			</RadioGroup>
		</FormControl>
	)

	const showDetails = (active) => (
		<FormControl className={classes.detailBox} variant="outlined">
			<TextField
				disabled={!active}
				id="select-details"
				size="small"
				onClick={
					radioOptionCheck === radioOptions[1] ? handleSelectedDetailCheck : handleDetailsOpen
				}
				value={t('common:filters.detail')}
				title={t('common:filters.detailsSelection')}
				fullWidth={true}
				InputProps={{
					readOnly: true,
					endAdornment: (
						<InputAdornment position="end">
							<IconButton edge="end">
								{isDetailsOpen ? <ArrowDropUp /> : <ArrowDropDown />}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			{renderDetailOptions()}
		</FormControl>
	)

	const showReport = (active) => (
		<FormControl className={classes.detailBox} variant="outlined">
			<TextField
				disabled={!active}
				id="select-report"
				size="small"
				onClick={
					radioOptionCheck === radioOptions[0] ? handleSelectedReportCheck : handleReportsOpen
				}
				value={t('common:filters.report')}
				title={t('common:filters.unitReport')}
				fullWidth={true}
				InputProps={{
					readOnly: true,
					endAdornment: (
						<InputAdornment position="end">
							<IconButton edge="end">
								{isReportOpen ? <ArrowDropUp /> : <ArrowDropDown />}
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			{renderReportOptions()}
		</FormControl>
	)

	const renderDetailOptions = () => (
		<Popover
			anchorEl={detailAnchorEl}
			id={'details-container'}
			keepMounted
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={isDetailsOpen}
			onClose={handleDetailsClose}
			PaperProps={
				{
					//style: {width: 120},
				}
			}
		>
			<Box sx={{ display: 'flex' }}>
				<FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
					<FormGroup>
						{detailState.map((item) => (
							<FormControlLabel
								key={item.name}
								label={capitalizeFirstLetter(item.label)}
								control={
									<Checkbox checked={item.checked} onChange={handleDetailCheck} name={item.name} />
								}
							/>
						))}
					</FormGroup>
				</FormControl>
			</Box>
		</Popover>
	)

	const renderReportOptions = () => (
		<Popover
			anchorEl={reportAnchorEl}
			id={'report-container'}
			keepMounted
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={isReportOpen}
			onClose={handleReportClose}
			PaperProps={
				{
					//style: {width: 120},
				}
			}
		>
			<Box sx={{ display: 'flex' }}>
				<FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
					<FormGroup>
						{reportState.map((item) => (
							<FormControlLabel
								key={item.name}
								label={capitalizeFirstLetter(item.label)}
								control={
									<Checkbox checked={item.checked} onChange={handleReportCheck} name={item.name} />
								}
							/>
						))}
					</FormGroup>
				</FormControl>
			</Box>
		</Popover>
	)

	const showMapActions = () => (
		<div className={classes.mapActions}>
			<Tooltip
				title={`${t('tooltip.showPointOfInterests')}`}
				arrow
				placement="bottom-start"
				disableInteractive
			>
				<IconButton
					id="poi"
					sx={{ m: 0.5 }}
					size="small"
					onClick={() => handleAction('poi')}
					className={classes.customIconButton}
					aria-label="point of interests"
				>
					<MapsHomeWork
						style={{
							color: selectedActions.poi
								? environment.theme.icons.active.color
								: environment.theme.icons.color,
						}}
						fontSize="small"
					/>
				</IconButton>
			</Tooltip>
			<Tooltip
				title={`${t('tooltip.showListOfAllMappedItems')}`}
				arrow
				placement="bottom-start"
				disableInteractive
			>
				<IconButton
					id="report"
					sx={{ m: 0.5 }}
					size="small"
					onClick={() => handleAction('report')}
					aria-label="Report of all mapped items"
				>
					<ViewList
						style={{
							color: selectedActions.report
								? environment.theme.icons.active.color
								: environment.theme.icons.color,
						}}
						fontSize="small"
					/>
				</IconButton>
			</Tooltip>
			<Tooltip
				title={`${t('tooltip.recenterMapAroundAllItems')}`}
				arrow
				placement="bottom-start"
				disableInteractive
			>
				<IconButton
					id="recenter"
					color="inherit"
					sx={{ m: 0.5 }}
					size="small"
					onClick={() => handleAction('recenter')}
					aria-label="Center"
				>
					<FilterCenterFocus
						style={{
							color: selectedActions.recenter
								? environment.theme.icons.active.color
								: environment.theme.icons.color,
						}}
						fontSize="small"
					/>
				</IconButton>
			</Tooltip>
			<Tooltip
				title={`${t('tooltip.refreshItemsOnMap')}`}
				arrow
				placement="bottom-start"
				disableInteractive
			>
				<IconButton
					id="refresh"
					color="inherit"
					sx={{ m: 0.5 }}
					size="small"
					onClick={() => handleAction('refresh')}
					aria-label="Refresh"
				>
					<Refresh
						style={{
							color: selectedActions.refresh
								? environment.theme.icons.active.color
								: environment.theme.icons.color,
						}}
						fontSize="small"
					/>
				</IconButton>
			</Tooltip>
			<Tooltip
				title={`${t('tooltip.animateItemsOnMap')}`}
				arrow
				placement="bottom-start"
				disableInteractive
			>
				<IconButton
					id="animate"
					color="inherit"
					sx={{ m: 0.5 }}
					size="small"
					onClick={() => handleAction('animate')}
					aria-label="Animate"
				>
					<PlayArrow
						style={{
							color: selectedActions.animate
								? environment.theme.icons.active.color
								: environment.theme.icons.color,
						}}
						fontSize="small"
					/>
				</IconButton>
			</Tooltip>
			<Tooltip
				title={`${t('tooltip.goToHelpLink')}`}
				arrow
				placement="bottom-start"
				disableInteractive
			>
				<IconButton
					id="helper"
					color="inherit"
					sx={{ m: 0.5 }}
					size="small"
					onClick={handleGoToHelpLink}
					aria-label="Helper"
				>
					<QuestionMark
						style={{
							color: selectedActions.help
								? environment.theme.icons.active.color
								: environment.theme.icons.color,
						}}
						fontSize="small"
					/>
				</IconButton>
			</Tooltip>
			<Tooltip
				title={`${t('tooltip.openInNewTab')}`}
				arrow
				placement="bottom-start"
				disableInteractive
			>
				<span>
					<IconButton
						aria-label="Open in new tab"
						color="inherit"
						sx={{ m: 0.5 }}
						size="small"
						edge="end"
						onClick={() => onHandleOpenFullScreenTab()}
					>
						<OpenInNewIcon fontSize="inherit" />
					</IconButton>
				</span>
			</Tooltip>
		</div>
	)

	return (
		<div className={classes.locationData}>
			{!module.dispatchRecord4Map && showDatePicker()}
			{showStatusOptions()}
			{showRadioOptions()}
			{/* { tab === "devices" && <div style={{ flexBasis: "100%", height: 0 }}></div>} */}
			{showMapActions()}
		</div>
	)
}

export default DoformsMapFilters
