import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import useMenuQuery from 'components/pages/dis/hooks/useMenuQuery'
import { useTranslation } from 'react-i18next'

export default function WebGroupSelect({
	currentMenuName,
	label,
	options,
	value,
	groupKeyByOtherMenu,
	onChange,
}) {
	const { t } = useTranslation('common')
	const { menuList } = useMenuQuery()

	const handleChange = (event) => {
		const {
			target: { value },
		} = event
		onChange(value)
	}

	return (
		<div>
			<FormControl size="small" fullWidth>
				<InputLabel id={`label-${label}`}>{label}</InputLabel>
				<Select
					labelId={`label-${label}`}
					id={label}
					value={value}
					onChange={handleChange}
					input={<OutlinedInput label={label} />}
				>
					{options.map((option) => {
						const currentOption = groupKeyByOtherMenu.find((x) => x.key === option.value)
						const currentMenu = menuList.find((x) => x.key === currentOption?.menuKey)

						return (
							<MenuItem key={option.value} value={option.value}>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									width="100%"
								>
									<ListItemText primary={option.label} />
									{currentMenu !== undefined && (
										<Tooltip
											title={t('common:misc.thisWebGroupIsAlreadyAssigned', {
												oldName: currentMenu?.name || '',
												newName: currentMenuName || '',
											})}
										>
											<InfoIcon fontSize="small" color="primary" />
										</Tooltip>
									)}
								</Stack>
							</MenuItem>
						)
					})}
				</Select>
			</FormControl>
		</div>
	)
}
