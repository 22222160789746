import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Modal,
	Tooltip,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import DoformsPortal from '../../../custom-components/DoformsPortal'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import SkeletonLoaderDialog from '../../../custom-components/skeletons/SkeletonLoaderDialog'
import { performFormAction } from '../datagrid/recordsHelper'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import { CoreContext } from '../../../custom-components/context/CoreContext'
import { isEmpty } from 'lodash'

const useStyles = makeStyles(() => ({
	dialog: {
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	dialogTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
	exitConfirmation: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	paper: {
		position: 'absolute',
		padding: '16px 32px 24px',
		borderRadius: '5px',
	},
	hidden: {
		display: 'none',
	},
}))

const DoformsCreateSubmission = (props) => {
	const [t] = useTranslation('common')
	const { formSelected, action, title, recordsLoading, tab, source, onDialogOpen } = props
	const { environment } = useSelector((state) => state)
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const initialState = {
		loaded: false,
		error: null,
	}

	const [values, setValues] = useState(initialState)
	const [open, setOpen] = useState(false)
	const [exitConfirmation, setExitConfirmation] = useState(false)
	const [iframe, setIframe] = useState(null)

	const { loaded, error } = values

	const dispatch = useDispatch()

	useEffect(() => {
		if (
			(source === 'dashboard' || source === 'button') &&
			environment.showCreateSubmission === source
		) {
			confirmAdd()
		}
	}, [environment.showCreateSubmission])

	useEffect(() => {
		if (source === 'formTiles' && !isEmpty(formSelected)) {
			confirmAdd()
		}
	}, [formSelected, source])

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setValues({ ...values, error: null })
	}

	const showLoading = () => (
		<>
			<LoadingSpinner />
			<SkeletonLoaderDialog />
		</>
	)

	const confirmAdd = () => {
		if (_.isEmpty(formSelected)) return
		setOpen(true)
		setValues({ ...values, loaded: false })
		performFormAction(environment, formSelected, action, tab)
			.then((res) => {
				setIframe(res)
			})
			.catch((err) => {
				setValues({
					...values,
					error: 'Code ' + err.response.data.code + ': ' + err.response.data.message,
				})
			})
	}

	const handleOpen = (e, source) => {
		e.preventDefault()
		if (onDialogOpen !== undefined) {
			onDialogOpen(true)
		}

		if (source === 'grid') source = 'dashboard'

		dispatch({
			type: ENV_ACTIONS.SHOW_CREATE_SUBMISSION,
			payload: source,
		})
	}

	const handleClose = (event, reason) => {
		if (reason !== 'backdropClick' && reason !== 'escapeKeyDown' && reason !== 'buttonClick') {
			setExitConfirmation(false)
			setOpen(false)
			setValues(initialState)
			setIframe(null)

			dispatch({
				type: ENV_ACTIONS.SHOW_CREATE_SUBMISSION,
				payload: false,
			})
		} else {
			setExitConfirmation(true)
		}
	}

	const handleLoaded = (event) => {
		event.preventDefault()
		setValues({ ...values, loaded: true })
	}

	const showTitle = () => {
		return `${t('tooltip.add')} ${title}`
	}

	const ExitConfirmationDialog = (props) => {
		const { open, onClose } = props
		const { coreValues } = useContext(CoreContext)
		const { theme } = coreValues

		const classes = useStyles(theme.topBar)

		const buttonStyle = [
			{ color: theme.icons.color },
			{
				'&:hover': {
					color: theme.icons.active.color,
				},
			},
		]

		const handleCloseRecordDialog = (event) => {
			event.preventDefault()
			setExitConfirmation(false)
			onClose()
		}

		const handleCloseExitConfirmation = (event) => {
			event.preventDefault()
			setExitConfirmation(false)
		}

		return (
			<div>
				<Modal
					open={open}
					onClose={handleCloseExitConfirmation}
					className={classes.exitConfirmation}
				>
					<div className={classes.paper} style={{ backgroundColor: 'white' }}>
						<p>{t('common:dialog.cancelSendDispatch')}</p>
						<Button onClick={handleCloseRecordDialog} sx={buttonStyle}>
							{t('common:misc.continue')}
						</Button>
						<Button onClick={handleCloseExitConfirmation} sx={buttonStyle}>
							{t('common:misc.cancel')}
						</Button>
					</div>
				</Modal>
			</div>
		)
	}

	return (
		<>
			{source === 'grid' && (
				<LoadingButton
					id={'create-submission'}
					className={classes.icon}
					disabled={recordsLoading}
					loadingPosition="start"
					onClick={(e) => handleOpen(e, source)}
					startIcon={<AddIcon />}
				>
					{t('common:misc.new')}
				</LoadingButton>
			)}
			{source === 'button' && (
				<Tooltip
					title={`${t('tooltip.prefix.new')} - ${title}`}
					arrow
					placement="bottom-start"
					disableInteractive
				>
					<span>
						<IconButton
							aria-label="add"
							size="small"
							edge="end"
							onClick={(e) => handleOpen(e, source)}
						>
							<AddIcon fontSize="inherit" className={classes.icon} />
						</IconButton>
					</span>
				</Tooltip>
			)}
			{(source === 'dashboard' || source === 'button' || source === 'formTiles') && (
				<>
					<Dialog
						open={open}
						onClose={handleClose}
						fullWidth={true}
						maxWidth="md"
						aria-labelledby="record-dialog-title"
						aria-describedby="record-dialog-description"
						className={classes.dialog}
						sx={{
							'& .MuiDialog-paper': {
								height: '90% !important',
							},
						}}
					>
						<DialogTitle id="record-dialog-title" className={classes.dialogTitle}>
							{showTitle()}
							<IconButton onClick={(e) => handleClose(e, 'buttonClick')}>
								<CloseIcon />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							{showErrorMessage()}
							{iframe ? (
								<DoformsPortal iframeSrc={iframe} onClose={handleClose} onLoaded={handleLoaded} />
							) : (
								showLoading()
							)}
							{/* {!loaded && showLoading()}
                        {iframe && <div className={!loaded ? classes.hidden : undefined}>
                            <DoformsPortal
                                iframeSrc={iframe}
                                onClose={handleClose}
                                onLoaded={handleLoaded}
                            />
                        </div>} */}
						</DialogContent>
					</Dialog>
					<ExitConfirmationDialog
						onClose={(e) => handleClose(e, null)}
						onMouseDown={(e) => e.stopPropagation()}
						open={exitConfirmation}
					/>
				</>
			)}
		</>
	)
}

export default DoformsCreateSubmission
