import { FormControl, InputLabel, MenuItem, Select, Skeleton, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns'
import { utcToZonedTime } from 'date-fns-tz'
import { enUS } from 'date-fns/locale'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getUserTimeZone } from 'utils/functions/helpers'

function MapFilters({
	vehicleList,
	vehicleName,
	vehicleLoading,
	fromDate,
	toDate,
	disableFilters,
	onFilters,
}) {
	const { t } = useTranslation('common')
	const [locale, setLocale] = useState(enUS)

	const handleFromDateChange = (newValue) => {
		if (newValue == null) {
			onFilters?.('fromDate', newValue)
			return
		}

		const localDate = utcToZonedTime(newValue, getUserTimeZone())

		onFilters?.('fromDate', localDate)
	}

	const handleToDateChange = (newValue) => {
		if (newValue == null) {
			onFilters?.('toDate', newValue)
			return
		}

		const localDate = utcToZonedTime(newValue, getUserTimeZone())

		onFilters?.('toDate', localDate)
	}

	return (
		<>
			{!vehicleLoading ? (
				<FormControl sx={{ minWidth: 120 }} size="small" disabled={disableFilters}>
					<InputLabel id="vehicle-select-small-label">{t('common:input.vehicleName')}</InputLabel>
					<Select
						labelId="vehicle-select-small-label"
						id="vehicle-select-small"
						value={vehicleName || ''}
						label={t('common:input.vehicleName')}
						onChange={(e) => {
							onFilters?.('vehicleName', e.target.value)
						}}
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{vehicleList?.length > 0 &&
							vehicleList.map((vehicle) => (
								<MenuItem value={vehicle.name} key={vehicle.name}>
									{vehicle.name}
								</MenuItem>
							))}
					</Select>
				</FormControl>
			) : (
				<Skeleton variant="rectangular" height={45} sx={{ minWidth: 120 }} />
			)}

			<LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
				<DatePicker
					componentsProps={{
						actionBar: {
							actions: ['clear'],
						},
					}}
					id="map-date"
					label={t('common:input.fromDate')}
					disabled={disableFilters}
					value={fromDate}
					maxDate={new Date()}
					onChange={handleFromDateChange}
					renderInput={(params) => (
						<TextField
							{...params}
							size="small"
							variant="outlined"
							label={t('common:input.fromDate')}
							// Turn off the error when the user clears the date
							error={false}
						></TextField>
					)}
				/>
			</LocalizationProvider>

			<LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
				<DatePicker
					componentsProps={{
						actionBar: {
							actions: ['clear'],
						},
					}}
					id="map-date"
					label={t('common:input.toDate')}
					disabled={disableFilters}
					value={toDate}
					maxDate={new Date()}
					onChange={handleToDateChange}
					renderInput={(params) => (
						<TextField
							{...params}
							size="small"
							variant="outlined"
							label={t('common:input.toDate')}
							// Turn off the error when the user clears the date
							error={false}
						/>
					)}
				/>
			</LocalizationProvider>
		</>
	)
}

export default MapFilters
