import { LoadingButton } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'
import React from 'react'

const useStyles = makeStyles(() => ({
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const AppButton = ({ children, ...delegated }) => {
	const { iconTheme } = React.useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	return (
		<LoadingButton className={classes.icon} {...delegated}>
			{children}
		</LoadingButton>
	)
}

export default AppButton
