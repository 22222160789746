import { Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckboxList from '../_components/CheckboxList'
import { DEFAULT_CHECKED_OPTIONS } from './OtherOptions'

const SelectFields = ({
	columnList = [],
	isColumnListLoading,
	primaryKeyList = [],
	columnsChecked = [],
	otherOptionsChecked = DEFAULT_CHECKED_OPTIONS,
	onChangeColumnsCheck,
	onChangePrimaryKeyList,
}) => {
	const { t } = useTranslation('common')

	const isShowDataNames = otherOptionsChecked?.includes('showDataNames')

	const handleSetPrimaryKey = (id) => {
		if (primaryKeyList.includes(id)) {
			onChangePrimaryKeyList(primaryKeyList.filter((item) => item !== id))
		} else {
			onChangePrimaryKeyList([...primaryKeyList, id])
		}
	}

	const handleColumnsCheck = (value) => () => {
		if (!Array.isArray(columnsChecked)) return
		const currentIndex = columnsChecked?.indexOf(value)
		const newChecked = [...columnsChecked]

		if (currentIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		onChangeColumnsCheck(newChecked)
	}

	return (
		<>
			{!isColumnListLoading ? (
				<CheckboxList
					title={t('common:dis.selectFields')}
					enableIcon
					onIconClick={handleSetPrimaryKey}
					checkedIconList={primaryKeyList}
					data={columnList}
					onItemClick={handleColumnsCheck}
					checkedList={columnsChecked}
					getLabel={(option) => (isShowDataNames ? option.name : option.title)}
					getValue={(option) => option.name}
				/>
			) : (
				<Skeleton variant="rectangular" height={180} sx={{ mb: 1 }} />
			)}
		</>
	)
}

export default SelectFields
