import { generateRandomID } from 'components/pages/dis/helpers'
import * as React from 'react'
import { convertToDndKitData, generateNewPosition } from '../menu/_utils/common'

const MenuContext = React.createContext({
	detailMenu: null,
	dragDropItems: {},
	containers: [],

	setDragDropItems: () => {},
	setContainers: () => {},
	onAddNewMenuItem: () => {},
	onUpdateMenu: () => {},
	onResetMenu: () => {},
	onAssignMenu: () => {},
})

export default function MenuProvider(props) {
	const [dragDropItems, setDragDropItems] = React.useState({})
	const [containers, setContainers] = React.useState([])

	const [detailMenu, setDetailMenu] = React.useState(null)
	const copiedDetailMenu = React.useRef(null)

	const handleAddNewMenuItem = () => {
		const menuItems = detailMenu?.items ?? []
		const newPosition = generateNewPosition(menuItems)

		const newMenu = {
			name: '',
			position: `${newPosition}`,
			items: [],
			type: 'dashboard',
			dashboard: {
				key: generateRandomID(),
			},
			is_creating: true,
		}

		const nextDetailDataItems = [...menuItems, newMenu]

		const dragDropData = convertToDndKitData(nextDetailDataItems)
		setDragDropItems(dragDropData)
		setContainers(Object.keys(dragDropData))

		setDetailMenu({ ...detailMenu, items: nextDetailDataItems })
	}

	const handleAssignMenu = (newMenu) => {
		const newDetailMenu = { ...detailMenu, ...newMenu }
		setDetailMenu(newDetailMenu)
		copiedDetailMenu.current = newDetailMenu
	}

	const handleUpdateMenu = (name, value) => {
		const newDetailMenu = { ...detailMenu, [name]: value }

		if (name === 'items') {
			const dragDropData = convertToDndKitData(value)
			setDragDropItems(dragDropData)
			setContainers(Object.keys(dragDropData))
		}

		setDetailMenu(newDetailMenu)
	}

	const handleResetMenu = () => {
		setDetailMenu(null)
		setDragDropItems({})
		setContainers([])
	}

	const value = React.useMemo(
		() => ({
			detailMenu,
			dragDropItems,
			containers,
			copiedDetailMenu,
			setDragDropItems,
			setContainers,
			onAddNewMenuItem: handleAddNewMenuItem,
			onUpdateMenu: handleUpdateMenu,
			onResetMenu: handleResetMenu,
			onAssignMenu: handleAssignMenu,
		}),
		[detailMenu, dragDropItems, containers]
	)

	return <MenuContext.Provider value={value} {...props} />
}

export function useMenu() {
	const context = React.useContext(MenuContext)
	if (!context) {
		throw new Error('useMenu must be used within a MenuProvider')
	}
	return context
}
