import { Box, Typography } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import tileApi from 'apis/disApi/tileApi'
import _, { isEmpty } from 'lodash'
import { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useForceRerender from 'utils/hooks/useForceRerender'
import LoadingSpinner from '../../../../../custom-components/LoadingSpinner'
import { IconThemeProvider } from '../../../../../custom-components/context/IconThemesContext'
import { isJson, logErrorMessage } from '../../../../../utils/functions/helpers'
import DoformsGridChart from '../../../../data/datagrid/DoformsGridChart'
import TileWrapper from '../../components/TileWrapper'
import { useTileDashboard } from '../../dashboard/Dashboard'
import { getAutoUpdateFilters } from '../../helpers'
import useDashboardQuery from '../../hooks/useDashboardQuery'
import { tileKeys } from '../../hooks/useTileQuery'
import ChartSettingsDialog from './ChartSettingsDialog'
import { checkByMasterDateTimeCondition } from 'utils/functions/helpers'

const ChartTile = ({ tile }) => {
	const { environment } = useSelector((state) => state)
	const iconTheme = environment.theme.icons
	const queryClient = useQueryClient()

	const { id: dashboardKey } = useParams()
	const { dashboardKeyList } = useDashboardQuery({
		dashboardKey,
	})

	const { selectedFields } = useTileDashboard()

	const updateMutation = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})

	const settings = useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])
	const { linkedFields = {} } = settings.mapTileInfo || {}

	const [forceId, setForceRerender] = useForceRerender()
	const [settingsOpen, setSettingsOpen] = useState(false)
	const [chartData, setChartData] = useState(null)
	const [isChartLoading, setIsChartLoading] = useState(false)

	const { filterConfigs, conditions } = useMemo(
		() => getAutoUpdateFilters(linkedFields, selectedFields, dashboardKeyList),
		[linkedFields, selectedFields, dashboardKeyList]
	)

	const getFilteredRows = (rows, filterConfigs, conditions) => {
		if (_.isEmpty(rows)) return []

		return rows.filter((row) => {
			if (filterConfigs?.length > 0) {
				return filterConfigs.every((filter) => {
					if (row) {
						const valueColumnField = row?.[filter.field] ?? ''

						return valueColumnField?.toLowerCase()?.includes(filter.value?.toLowerCase()) ?? false
					}
					return false
				})
			}

			if (!_.isEmpty(conditions)) {
				for (const key in conditions) {
					const columnCondition = conditions[key]
					const rowValue = row[key]
					if (_.isEmpty(columnCondition)) return true

					const isValid = checkByMasterDateTimeCondition(rowValue, columnCondition, columnCondition?.keyData === 'Master_DateTime')
					return isValid
				}
			}
			return true
		})
	}

	const filteredRow = useMemo(() => {
		return getFilteredRows(chartData?.rows, filterConfigs, conditions)
	}, [chartData?.rows, filterConfigs, conditions])

	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	const tileRef = useRef(null)
	const formMapRef = useRef(null)

	const handleSubmitSetting = async ({
		viewKey,
		formKey,
		projectKey,
		linked,
		rows,
		columns,
		viewData,
	}) => {
		try {
			const editedSettings = JSON.stringify({
				...settings,
				mapTileInfo: {
					viewKey,
					formKey,
					projectKey,
					linkedFields: linked,
				},
			})

			await updateMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})

			if (viewData?.chart) {
				await tileApi.update({
					dashboardKey,
					tileKey: tile.key,
					data: { chart: viewData?.chart },
					token: environment.apiToken,
				})
			}

			setChartData({
				rows,
				columns,
				viewData,
			})

			handleCloseDialog()
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({ ...settings, tileWidth: width })

			await updateMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const onHandleFilters = (params, xAxisSelected, dateGroupSelected) => {
		setForceRerender()
	}

	const renderChart = useCallback(() => {
		if (!chartData?.viewData?.chart?.display) return null

		if (filteredRow?.length === 0)
			return (
				<Box
					sx={{
						height: '50%',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Typography variant="h6" color="text.secondary">
						Chart no data
					</Typography>
				</Box>
			)

		return (
			<DoformsGridChart
				id={'MapTileGridChart'}
				formMapRef={formMapRef}
				environment={environment}
				columns={chartData?.columns ?? []}
				gridRows={filteredRow ?? []}
				viewData={chartData?.viewData ?? {}}
				onHandleFilters={onHandleFilters}
				hiddenDisplayChartBtn
				hiddenDisplayEditChartBtn
			/>
		)
	}, [filteredRow, chartData?.viewData, chartData?.columns])

	return (
		<IconThemeProvider values={iconTheme}>
			<TileWrapper title={tile?.i} onSettingClick={handleOpenDialog} ref={tileRef}>
				{isChartLoading && <LoadingSpinner />}
				{!_.isEmpty(chartData) && (
					<Box
						ref={formMapRef}
						sx={{
							height: '100%',
							width: '100%',
							backgroundColor: 'white',
							p: 2,
						}}
					>
						{renderChart()}
					</Box>
				)}

				<ChartSettingsDialog
					tileElementWidth={tileRef?.current?.clientWidth}
					defaultTileWidth={settings.tileWidth}
					tile={tile}
					open={settingsOpen}
					settings={settings}
					setChartData={setChartData}
					isChartLoading={isChartLoading}
					setIsChartLoading={setIsChartLoading}
					onClose={handleCloseDialog}
					onSubmit={handleSubmitSetting}
					onResizeTileWidth={handleResizeTileWidth}
				/>
			</TileWrapper>
		</IconThemeProvider>
	)
}

export default ChartTile
