const defaultQuery = {
	columns: [],
	filter: {
		conditions: [
			{ preset: 'TODAY', target: '@StartTime', type: 'EQ' },
			{ preset: 'ALL', target: '@UserStatus', type: 'EQ' },
		],
	},
}

export const ACTIVITY_ACTIONS = {
	ACTION: 'ACTIVITY_ACTION',
	ACTIVITY_REFRESH: 'ACTIVITY_ACTIVITY_REFRESH',
	DATA_GRID: 'ACTIVITY_DATA_GRID',
	FORM_SELECTED: 'ACTIVITY_FORM_SELECTED',
	FORM_COLUMNS: 'ACTIVITY_FORM_COLUMNS',
	FORM_FILTER_CONDITIONS: 'ACTIVITY_FORM_FILTER_CONDITIONS',
	FORM_DELETE_SELECTION: 'ACTIVITY_FORM_DELETE_SELECTION',
	VIEW_DATA: 'ACTIVITY_VIEW_DATA',
	VIEW_UPDATE: 'ACTIVITY_VIEW_UPDATE',
	VIEW_CREATE: 'ACTIVITY_VIEW_CREATE',
	RESET: 'ACTIVITY_RESET',
	ACTIVITY_RESTORE_PRODUCER: 'ACTIVITY_RESTORE_PRODUCER',
	ACTIVITY_OPEN_NEW_SCREEN_MAP: 'ACTIVITY_OPEN_NEW_SCREEN_MAP',
	TAB_CHANGE: 'ACTIVITY_TAB_CHANGE',
	NAME: 'Activity',
	ROWS_PER_PAGE: 'ACTIVITY_ROWS_PER_PAGE',
	CLIENT_FILTERS: 'ACTIVITY_CLIENT_FILTERS',
}

const initialState = {
	action: 'activity-page',
	formColumns: [],
	formFilterConditions: {},
	formDeleteSelection: [],
	formSelected: {},
	activityRefresh: false,
	viewUpdate: {},
	viewCreate: {},
	viewData: {},
	dataGrid: {
		title: null,
		query: defaultQuery,
		queryView: {},
		columns: [],
		records: [],
		viewSession: false,
	},
	rowsPerPage: 100,
	clientFilters: {},
}

const activityReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTIVITY_ACTIONS.ACTION: {
			return {
				...state,
				action: action.payload,
			}
		}
		case ACTIVITY_ACTIONS.FORM_COLUMNS: {
			return {
				...state,
				formColumns: action.payload,
			}
		}
		case ACTIVITY_ACTIONS.FORM_SELECTED: {
			return {
				...state,
				formSelected: action.payload,
			}
		}
		case ACTIVITY_ACTIONS.FORM_FILTER_CONDITIONS: {
			return {
				...state,
				formFilterConditions: action.payload,
			}
		}
		case ACTIVITY_ACTIONS.VIEW_DATA: {
			return {
				...state,
				viewData: action.payload,
			}
		}
		case ACTIVITY_ACTIONS.VIEW_UPDATE: {
			return {
				...state,
				viewUpdate: action.payload,
			}
		}
		case ACTIVITY_ACTIONS.VIEW_CREATE: {
			return {
				...state,
				viewCreate: action.payload,
			}
		}
		case ACTIVITY_ACTIONS.ACTIVITY_REFRESH: {
			return {
				...state,
				activityRefresh: action.payload,
			}
		}
		case ACTIVITY_ACTIONS.FORM_DELETE_SELECTION: {
			return {
				...state,
				formDeleteSelection: action.payload,
			}
		}
		case ACTIVITY_ACTIONS.DATA_GRID: {
			return {
				...state,
				dataGrid: { ...state.dataGrid, ...action.payload },
			}
		}
		case ACTIVITY_ACTIONS.RESET:
			return initialState
		case ACTIVITY_ACTIONS.ACTIVITY_RESTORE_PRODUCER: {
			return {
				...state,
				action: action?.payload?.action || initialState.action,
				activitySelected: action?.payload?.activitySelected || {},
				formColumns: action?.payload?.formColumns || [],
				formFilterConditions: action?.payload?.formFilterConditions || {},
				formDeleteSelection: action?.payload?.formDeleteSelection || [],
				activityRefresh: action?.payload?.activityRefresh,
				viewUpdate: action?.payload?.viewUpdate || {},
				viewCreate: action?.payload?.viewCreate || {},
				viewData: action?.payload?.viewData || {},
				dataGrid: action?.payload?.dataGrid || initialState.dataGrid,
				rowsPerPage: action?.payload?.rowsPerPage || 100,
			}
		}
		case ACTIVITY_ACTIONS.TAB_CHANGE: {
			if (ACTIVITY_ACTIONS.NAME === action.payload) {
				return state
			} else {
				return {
					...state,
					// activitySelected: {}
				}
			}
		}
		case ACTIVITY_ACTIONS.ROWS_PER_PAGE: {
			return {
				...state,
				rowsPerPage: action.payload,
			}
		}
		case ACTIVITY_ACTIONS.CLIENT_FILTERS: {
			return {
				...state,
				clientFilters: action.payload,
			}
		}
		default:
			return state
	}
}

export default activityReducer
