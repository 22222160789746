import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { capitalizeFirstLetter, getSelectedProject } from '../dataHelpers'
import {
	deleteRecord,
	getSubmissionRecordImageBlobs,
	getPrintPreview,
	getRecordHistory,
	viewRecord,
	executePostDispatchLink,
	saveFilePDF,
	checkFinishTask,
	processDownloadPDF,
} from './recordsService'
import DoformsPortal from '../../../custom-components/DoformsPortal'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'

import { performRecordAction } from './recordsHelper'
import DoformsRecordHistory from './DoFormsRecordHistory'

import { FORM_ACTIONS } from '../../../reducers/formsReducer'
import SkeletonLoaderDialog from '../../../custom-components/skeletons/SkeletonLoaderDialog'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { LOOKUPS_ACTIONS } from '../../../reducers/lookupsReducer'
import { VIEWS_ACTIONS } from '../../../reducers/viewsReducer'

import DoformsGalleryView from './DoformsGalleryView'
import { DEVICES_ACTIONS } from '../../../reducers/devicesReducer'
import { ACTIVITY_ACTIONS } from '../../../reducers/activityReducer'
import { isNullOrEmptyString, sleep } from '../../../utils/functions/helpers'
import { API } from '../../../config';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	dialogTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

export const DoformsRecordsActions = (props) => {
	const [t] = useTranslation('common')
	const {
		setIsFormDeleted,
		setExternalError,
		setGridRows,
		open,
		environment,
		action,
		setAction,
		actionRecord,
		formSelected,
		tab,
		onClose,
		onCallback,
		columns,
		viewTitle,
		popupTitle,
		setLoading: setTableLoading,
	} = props
	const { viewsModule, devicesModule } = useSelector((state) => state)
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const initDialog = {
		title: t('common:misc.loadingTitle'),
		content: (
			<div>
				<SkeletonLoaderDialog />
			</div>
		),
	}
	const [dialog, setDialog] = useState(initDialog)
	const [loading, setLoading] = useState(false)
	const [loaded, setLoaded] = useState(false)
	const [error, setError] = useState(null)
	const [recordBlobs, setRecordBlobs] = useState([])

	const record = useMemo(() => {
		if (_.isEmpty(actionRecord)) return null
		let result = { ...actionRecord }
		// result = {...result, key: result.type === "SUBMISSION" ? result.submissionKey : result.dispatchKey}
		return result
	}, [actionRecord])

	const performIframeAction = () => {
		setLoading(true)
		if (tab == 'devices' || tab == 'activity' || tab === 'views') {
			setDialog({ ...dialog, title: `Loading ${capitalizeFirstLetter(action)}` })

			let form
			if (tab === 'views') {
				form = {
					...formSelected,
					key: actionRecord.type === 'ACTIVITY' ? formSelected.key : formSelected.formKey,
				}
			} else {
				form = {
					...formSelected,
					key: actionRecord.type === 'ACTIVITY' ? formSelected.formKey : formSelected.key,
				}
			}

			const iframePromise =
				action === 'view'
					? viewRecord(record, environment.apiToken)
					: performRecordAction(environment, record, action, form, tab, actionRecord)
			iframePromise
				.then((res) => {
					const iframeSrc =
						action === 'view' ? getPrintPreview(res.data, environment.apiToken) : res
					if (tab === 'views') {
						setDialog({
							title: popupTitle,
							content: (
								<DoformsPortal
									iframeSrc={iframeSrc}
									onClose={handleClose}
									onLoaded={handleLoaded}
								/>
							),
						})
					} else {
						setDialog({
							//title: `${capitalizeFirstLetter(action)} / ${name}`,
							content: (
								<DoformsPortal
									iframeSrc={iframeSrc}
									onClose={handleClose}
									onLoaded={handleLoaded}
								/>
							),
						})
					}
				})
				.catch((err) => {
					if (err?.response?.status === 404) {
						setExternalError?.(`${t('common:misc.record')} ${t('common:misc.hasBeenDeleted')}`)
					} else {
						setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
					}
					handleClose()
				})
				.finally(() => {
					setLoading(false)
				})
		} else {
			setDialog({
				...dialog,
				title: `${capitalizeFirstLetter(action)} ${getSelectedProject(environment, formSelected, tab).name
					} / ${formSelected.name}`,
			})

			const iframePromise =
				action === 'view'
					? viewRecord(record, environment.apiToken, formSelected, environment.user)
					: performRecordAction(environment, record, action, formSelected, tab)
			iframePromise
				.then((res) => {
					const iframeSrc =
						action === 'view' ? getPrintPreview(res.data, environment.apiToken) : res

					setDialog({
						title: `${capitalizeFirstLetter(action)} ${getSelectedProject(environment, formSelected, tab).name
							} / ${formSelected.name}`,
						content: (
							<DoformsPortal iframeSrc={iframeSrc} onClose={handleClose} onLoaded={handleLoaded} />
						),
					})
				})
				.catch((err) => {
					setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}

	const performHistoryAction = () => {
		setLoading(true)
		setDialog({ ...dialog, title: `${capitalizeFirstLetter(action)}` })
		getRecordHistory(record, environment.apiToken)
			.then((res) => {
				setDialog({
					title: `${capitalizeFirstLetter(action)}`,
					content: (
						<DoformsRecordHistory
							recordHistory={res.data}
							environment={environment}
							tab={tab}
							columns={columns}
							onClose={handleClose}
						></DoformsRecordHistory>
					),
				})
			})
			.catch((err) => {
				if (err?.response?.data?.code === 404) {
					setExternalError?.(`${t('common:misc.record')} ${t('common:misc.hasBeenDeleted')}`)
				} else if (err?.response?.data?.code === 500) {
					setExternalError(
						'Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message
					)
				} else {
					setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
				}
				handleClose()
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const performPDFReport = async () => {
		setTableLoading(true);
		setDialog(null)
		try {
			const res = await viewRecord(record, environment.apiToken, formSelected, environment.user);
			let taskInfoKey = res.data.taskInfoKey;
			const saveFileResponse = await saveFilePDF(res.data, environment.apiToken);
			taskInfoKey = saveFileResponse.data?.taskInfoKey;

			if (isNullOrEmptyString(taskInfoKey)) {
				throw Error('Report failed');
			}

			let isFinish = false;
			let linkDownload = '';
			let isErrorTaskInfo = false;
			let errorMessage = '';
			do {
				const checkFinishTaskResponse = await checkFinishTask(taskInfoKey, environment.apiToken);
				isFinish = checkFinishTaskResponse.data?.isFinish;

				if (isFinish) {
					linkDownload = checkFinishTaskResponse.data.downloadLink;
					isErrorTaskInfo = checkFinishTaskResponse.data.isErrorTaskInfo;
					errorMessage = checkFinishTaskResponse.data.errorMessage;
				}

				await sleep(10000);
			} while (!isFinish);

			if (linkDownload.indexOf('http://') === 0 || linkDownload.indexOf('https://') === 0) {
				// Link of Aspose's PDF
				// => DO NOTHING
				throw Error('Notthing to report');
			}

			// Check if the task okie or not
			if (isErrorTaskInfo === true) {
				// Error occur, we will show error information
				throw Error('Error in PRTaskInfo : ' + errorMessage);
			}

			const link = document.createElement('a');
			const url = API + linkDownload;
			link.setAttribute('href', url);
			link.setAttribute('download', '');
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (error) {
			setError(error.message);
		} finally {
			setTableLoading(false);
			setAction(null);
		}
	};

	const performDeleteAction = () => {
		setIsFormDeleted?.(true)
		setGridRows((prev) =>
			prev.map((item) => (item.recordKey === record.key ? { ...item, isLoading: true } : item))
		)
		handleClose()
		setLoading(true)
		deleteRecord(record, environment.apiToken)
			.then(() => {
				const payload = [record.key]
				if (tab === 'forms') {
					dispatch({
						type: FORM_ACTIONS.FORM_DELETE_SELECTION,
						payload: payload,
					})
				} else if (tab === 'lookups') {
					dispatch({
						type: LOOKUPS_ACTIONS.FORM_DELETE_SELECTION,
						payload: payload,
					})
				} else if (tab === 'views') {
					dispatch({
						type: VIEWS_ACTIONS.FORM_DELETE_SELECTION,
						payload: payload,
					})
				} else if (tab === 'devices') {
					dispatch({
						type: DEVICES_ACTIONS.FORM_DELETE_SELECTION,
						payload: payload,
					})
				} else if (tab === 'activity') {
					dispatch({
						type: ACTIVITY_ACTIONS.FORM_DELETE_SELECTION,
						payload: payload,
					})
				}
			})
			.catch((err) => {
				if (err?.response?.data?.code === 404) {
					setExternalError?.(`${t('common:misc.record')} ${t('common:misc.hasBeenDeleted')}`)
				} else if (err?.response?.data?.code === 500) {
					setExternalError(
						'Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message
					)
				} else {
					setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
				}
			})
			.finally(() => {
				setLoading(false)
				setGridRows((prev) =>
					prev.map((item) => (item.recordKey === record.key ? { ...item, isLoading: false } : item))
				)
				setIsFormDeleted?.(false)
			})
	}

	const performAction = (e, action) => {
		switch (action) {
			case 'delete':
				performDeleteAction()
				break
			case 'send':
			case 'recall':
				setGridRows((prev) =>
					prev.map((item) => (item.recordKey === record.key ? { ...item, isLoading: true } : item))
				)
				handleClose()
				setLoading(true)
				const iframePromise = performRecordAction(environment, record, action, formSelected, tab)
				iframePromise
					.then((res) => {
						executePostDispatchLink(res, environment.apiToken)
							.then(() => {
								dispatch({
									type: FORM_ACTIONS.FORM_REFRESH,
									payload: true,
								})
								setLoading(false)
							})
							.catch((err) => {
								setError(err)
								setLoading(false)
							})
							.finally(() => {
								setGridRows((prev) =>
									prev.map((item) =>
										item.recordKey === record.key ? { ...item, isLoading: false } : item
									)
								)
							})
					})
					.catch((err) => {
						setError(err)
						setLoading(false)
					})
					.finally(() => { })
				break
		}
	}

	const performImagesAction = () => {
		setLoading(true)
		getSubmissionRecordImageBlobs(record, environment.apiToken)
			.then((res) => {
				setRecordBlobs([...res.data])
				onCallback({ galleryView: 1 })
			})
			.catch((err) => {
				if (err?.response?.data?.code === 404) {
					setExternalError?.(`${t('common:misc.record')} ${t('common:misc.hasBeenDeleted')}`)
					setAction?.(null)
				} else if (err?.response?.data?.code === 500) {
					setExternalError(
						'Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message
					)
					setAction?.(null)
				} else {
					setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
				}
				setLoading(false)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		if (!action) return
		switch (action) {
			case 'copy':
			case 'edit':
			case 'view':
				performIframeAction()
				break
			case 'history':
				performHistoryAction()
				break
			case 'delete':
				setDialog({
					title: t('common:misc.delete'),
					content: t('common:misc.areYouSureYouWantToDeleteThisRecord'),
				})
				break
			case 'images':
				performImagesAction()
				break
			case 'send':
				setDialog({
					title: t('common:misc.send'),
					content: t('common:misc.confirmSendThisRecord'),
				})
				break
			case 'recall':
				setDialog({
					title: t('common:misc.recall'),
					content: t('common:misc.confirmRecallThisRecord'),
				})
				break
			case 'pdf':
				performPDFReport();
				break;
			default:
				break
		}
	}, [action])

	const dispatch = useDispatch()

	const showLoading = () => loading && <LoadingSpinner />

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setError(null)
	}

	const handleClose = (event, reason) => {
		if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
			if (['copy', 'edit'].includes(action)) {
				if (tab === 'devices' && !_.isEmpty(devicesModule.oldFormSelected)) {
					dispatch({
						type: DEVICES_ACTIONS.UPDATE_FORM_SELECTED,
						payload: {
							key: devicesModule.oldFormSelected.key,
							projectKey: devicesModule.oldFormSelected.projectKey,
						},
					})
					dispatch({
						type: DEVICES_ACTIONS.OLD_FORM_SELECTED,
						payload: {},
					})
				} else if (tab === 'views' && !_.isEmpty(viewsModule.oldViewSelectedKey)) {
					dispatch({
						type: VIEWS_ACTIONS.UPDATE_VIEW_SELECTED_KEY,
						payload: viewsModule.oldViewSelectedKey,
					})
					dispatch({
						type: VIEWS_ACTIONS.OLD_VIEW_SELECTED_KEY,
						payload: null,
					})
				}
			}
			onClose(event, reason)
		}
	}

	const handleLoaded = () => {
		setLoaded(true)
	}

	return (
		<>
			{showErrorMessage()}
			{dialog && <Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth={action === 'delete' || action === 'send' || action === 'recall' ? 'sm' : 'md'}
				fullWidth
				className={classes.root}
				sx={{
					'& .MuiDialog-paper': ['copy', 'edit', 'view'].includes(action) ? {
						height: '90% !important',
					} : {},
				}}
			>
				{dialog.title && (
					<DialogTitle id="record-dialog-title" className={classes.dialogTitle}>
						{capitalizeFirstLetter(dialog.title)}
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
				)}
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{action !== 'delete' && showLoading()}
						{dialog.content}
					</DialogContentText>
				</DialogContent>
				{(action === 'delete' || action === 'send' || action === 'recall') && (
					<DialogActions>
						<LoadingButton
							onClick={(e) => performAction(e, action)}
							className={classes.icon}
							loading={loading}
							autoFocus
						>
							{t('common:misc.ok')}
						</LoadingButton>
						<Button onClick={handleClose} className={classes.icon}>
							{t('common:misc.cancel')}
						</Button>
					</DialogActions>
				)}
			</Dialog>
			}
			{action === 'images' ? (
				loading ? (
					showLoading()
				) : (
					<DoformsGalleryView
						viewTitle={viewTitle}
						environment={environment}
						record={record}
						recordBlobs={recordBlobs}
						onClose={handleClose}
					/>
				)
			) : null}
		</>
	)
}

export default DoformsRecordsActions
