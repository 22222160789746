import { Fragment, useState, useRef, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { enUS } from 'date-fns/locale'
import moment from 'moment'
import { isEmpty } from 'lodash'

import { TextField, MenuItem, Grid, Tooltip, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro'

import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns'

import { datesValues, operatorOptions, getConditionString } from '../../../../../utils/functions/conditionsOptions'
import { computeDateValue } from '../../../../../utils/functions/doformsDateUtil'
import { parseAs, calculateUserTimezoneOffset } from '../../../../../utils/functions/doformsDateUtil'
import { DATE_CONDITION_FILTER_TYPE } from './MasterDatetimeTile'

const useStyles = makeStyles(() => ({
    root: {
        minWidth: '300px',
    },

    columnBox: {
        maxWidth: '15em',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '0',
            },
        },
    },

    filtersContainer: {
        margin: '16px',
    },

    footer: {
        // marginTop: theme.spacing(1),
        display: 'flex',
        minHeight: '52px',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .MuiButton-root': {
            textTransform: 'none !important',
        },
    },

    footerLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '8px',
    },

    footerRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },

    formRoot: {
        padding: '16px',
        justifyContent: 'space-around',
    },

    menuItem: {
        fontSize: '13px',
        paddingTop: 'none',
        paddingBottom: 'none',
    },

    subheader: {
        margin: 'none',
    },

    operatorBox: {
        minWidth: '120px',
        width: '100%',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },
    },

    customValueBox: {
        display: 'inline-grid',
        width: '100%',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },

        '& .MuiOutlinedInput-input': {
            minHeight: '1.65em',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: '400',
            fontSize: '0.875rem',
            lineHeight: '1.4375em',
            letterSpacing: '0.00938em',
        },
    },

    presetsValueBox: {
        display: 'inline-grid',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0 4px 4px 0`,
            },
        },
    },

    dateRangePicker: {
        width: '100%',
        display: 'block',
    },
}))

const DisplayDatePickerType = {
    SINGLE: 'single',
    RANGE: 'range',
}

function buildCondition(operator, initValues) {
    const isOperatorType = ['EQ', 'NE', 'BT', 'CT', 'EMPTY', 'NOTEMPTY'].includes(operator)
    if (isOperatorType) {
        switch (operator) {
            case 'EMPTY':
                return { preset: operator, type: 'EQ', values: [] }
            case 'NOTEMPTY':
                return { preset: operator, type: 'NE', values: [] }

            case 'BT':
                return {
                    type: 'BT',
                    join: 'AND',
                    values: [initValues[0] || null, initValues[1] || null],
                }
            default:
                return {
                    type: operator,
                    values: initValues ? [initValues[0]] : [null],
                }
        }
    } else {
        return { type: 'EQ', preset: operator, values: initValues || [] }
    }
}

const DEFAULT_DATE_OPERATOR = 'TODAY'

export default function MasterDateTimeSelectComponent(props) {
    const { disablePicker, dateConditionFilterDispatch, handleApply, dateTimeOperators = [] } = props
    const [t] = useTranslation('common')
    const classes = useStyles()
    const operators = operatorOptions(t)
    const presets = datesValues(t)
    const [operatorValue, setOperatorValue] = useState(DEFAULT_DATE_OPERATOR)
    const [displayType, setDisplayType] = useState(DisplayDatePickerType.SINGLE)
    const [values, setValues] = useState([])
    const { environment } = useSelector((state) => state)

    const [displayOperator, setDisplayOperator] = useState(operators)
    const [displayPresets, setDisplayPresets] = useState(presets)

    useEffect(() => {
        if (isEmpty(dateTimeOperators)) return

        const selectedOperators = operators.filter(operator => {
            const operatorValue = getConditionString(operator)
            return dateTimeOperators.includes(operatorValue)
        })

        const selectedPresets = presets.filter(preset => {
            const presetValue = getConditionString(preset)
            return dateTimeOperators.includes(presetValue)
        })

        setDisplayOperator(selectedOperators)
        setDisplayPresets(selectedPresets)
    }, [dateTimeOperators])
    const userOffset = useMemo(() => {
        return calculateUserTimezoneOffset(environment)
    }, [environment])


    const handleDateOperatorChange = (event) => {
        const value = event.target.value
        setOperatorValue(value)
    }

    const needPicker = useMemo(() => !(['ALL', 'EMPTY', 'NOTEMPTY'].includes(operatorValue)), [operatorValue])

    useEffect(() => {
        const currentCondition = buildCondition(operatorValue, [])

        if (!currentCondition) return

        const { type: operator, preset } = currentCondition
        if (!operator && !preset) return

        if (preset) {
            const computedValues = computeDateValue(preset)
            const computedValuesISO = computedValues.map(item => {
                if (!item) return ''
                const userMoment = parseAs(item, userOffset)
                return userMoment?.toISOString()
            })
            switch (preset) {
                case 'EMPTY':
                case 'NOTEMPTY':
                case 'ALL':
                    {
                        dateConditionFilterDispatch({
                            type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
                            value: {
                                ...currentCondition,
                                values: []
                            }

                        })
                        setDisplayType(null)
                        setValues(computedValuesISO)
                        return
                    }
                case 'TODAY':
                case 'YESTERDAY':
                case 'TOMORROW': {
                    dateConditionFilterDispatch({
                        type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
                        value: {
                            ...currentCondition,
                            values: computedValuesISO
                        }

                    })
                    setDisplayType(DisplayDatePickerType.SINGLE)
                    setValues(computedValuesISO)
                    return
                }

                default: {
                    dateConditionFilterDispatch({
                        type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
                        value: {
                            ...currentCondition,
                            values: computedValuesISO
                        }

                    })
                    setDisplayType(DisplayDatePickerType.RANGE)
                    setValues(computedValuesISO)
                    return
                }
            }
        } else {
            const now = moment()
            const today = now.clone().startOf('day')
            const todayISOString = parseAs(today, userOffset).toISOString()
            switch (operator) {
                case 'EQ':
                case 'NE':
                case 'CT':
                    {
                        const values = [todayISOString]
                        dateConditionFilterDispatch({
                            type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
                            value: {
                                ...currentCondition,
                                values
                            }

                        })
                        setDisplayType(DisplayDatePickerType.SINGLE)
                        setValues(values)
                        return
                    }
                case 'BT':
                    {
                        const endToday = now.clone().endOf('day')
                        const endTodayMomentISOString = parseAs(endToday, userOffset).add(1, 's').toISOString()
                        const values = [todayISOString, endTodayMomentISOString]
                        dateConditionFilterDispatch({
                            type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
                            value: {
                                ...currentCondition,
                                values
                            }

                        })
                        setDisplayType(DisplayDatePickerType.RANGE)
                        setValues(values)
                        return
                    }

                default:
                    {
                        const values = []
                        dateConditionFilterDispatch({
                            type: DATE_CONDITION_FILTER_TYPE.OPERATOR,
                            value: {
                                ...currentCondition,
                                values
                            }

                        })
                        setDisplayType(null)
                        setValues(values)
                        return
                    }
            }
        }
    }, [operatorValue])

    return <Grid container>
        <Grid item xs md lg>
            <TextField
                disabled={disablePicker}
                id="operatorsSelection"
                className={classes.operatorBox}
                size="small"
                select={true}
                label={t('common:filters.operator')}
                color="primary"
                variant="outlined"
                value={operatorValue}
                onChange={(e) => {
                    const newOperator = e.target.value
                    setOperatorValue(newOperator)
                    handleDateOperatorChange(e)
                }}
            >
                {!isEmpty(displayOperator) && displayOperator.map((option) => (
                    <MenuItem key={option.name} value={option.type} className={classes.menuItem}>
                        {option.name}
                    </MenuItem>
                ))}
                {!isEmpty(displayOperator) && !isEmpty(displayPresets) && <hr className={classes.subheader} />}
                {!isEmpty(displayPresets) && displayPresets.map((option) => (
                    <MenuItem key={option.preset} value={option.preset} className={classes.menuItem}>
                        {option.name}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>
        {needPicker && <Grid item xs={8} md={8} lg={8}>
            <DateTimePicker
                disablePicker={disablePicker}
                operatorValue={operatorValue}
                dateConditionFilterDispatch={dateConditionFilterDispatch}
                displayType={displayType}
                values={values}
                setValues={setValues}
                userOffset={userOffset}
            />
        </Grid>}
        <Grid item xs={1} md={1} lg={1}>
            <Tooltip
                title={`Fetch new data`}
                arrow
                placement="bottom-start"
                disableInteractive
                sx={{ flexShrink: 0 }}
            >
                <Button onClick={() => handleApply(true)}> Apply </Button>
            </Tooltip>
        </Grid>
    </Grid>
}

function DateTimePicker(props) {
    const { disablePicker, operatorValue, dateConditionFilterDispatch, displayType, values, setValues, userOffset } = props
    const [t] = useTranslation('common')
    const dateValueRef = useRef(null)
    const classes = useStyles()
    const presets = datesValues(t)
    const [locale, setLocale] = useState(enUS)

    const isPreset = useMemo(() => {
        return presets?.some(item => item.preset === operatorValue)
    }, [operatorValue])

    const displayValues = useMemo(() => {
        return values.map((utcString, index) => {
            const momentUTC = index === 0 ? moment.utc(utcString) : moment.utc(utcString).subtract(1, 's')
            return momentUTC.utcOffset(userOffset).format('YYYY-MM-DDTHH:mm:ss')

        })
    }, [values, userOffset])

    useEffect(() => {
        const importLocaleFile = async () => {
            const localeToSet = await import(
                `date-fns/locale/${t('common:languages.dateFnsLocale')}/index.js`
            )
            setLocale(localeToSet.default)
        }

        if (locale.code !== t('common:languages.dateFnsLocale')) {
            importLocaleFile()
        }
    }, [t('common:languages.dateFnsLocale')])

    const handleChangeSingleDatePicker = (newValue, isOnInput) => {
        const startDayMoment = moment(newValue).clone().startOf('day')
        const userMoment = parseAs(startDayMoment, userOffset)
        // to UTC time
        const startDayUTC = userMoment.toISOString()
        dateConditionFilterDispatch({
            type: DATE_CONDITION_FILTER_TYPE.DATE_COMPONENT,
            value: [startDayUTC]
        })
        setValues([startDayUTC])
    }

    const handleChangeDateRangePicker = (values, callback) => {
        const from = values[0]
        const to = values[1]
        if (!from || !to) return
        let startMomentObj = moment(from).clone().startOf('day')
        let endMomentObj = moment(to).endOf('day').add(1, 's')

        const fromUserMoment = parseAs(startMomentObj, userOffset)
        const endUserMoment = parseAs(endMomentObj, userOffset)

        if (fromUserMoment.isValid() && endUserMoment.isValid()) {
            const startDayUTC = fromUserMoment.toISOString()
            const endDayUTC = endUserMoment.toISOString()
            callback(startDayUTC, endDayUTC)
        }
    }

    return <div>
        {(displayType === DisplayDatePickerType.SINGLE) && <LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
            <DatePicker
                label={t('common:filters.date')}
                value={displayValues}
                disabled={isPreset || disablePicker}
                onChange={(newValue) => handleChangeSingleDatePicker(newValue, false)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onChange={(e) => handleChangeSingleDatePicker(e.target.value, true)}
                        ref={dateValueRef}
                        className={classes.customValueBox}
                        size="small"
                        variant="outlined"
                        label={t('common:filters.value')}
                    ></TextField>
                )}
            />
        </LocalizationProvider>}
        {
            (displayType === DisplayDatePickerType.RANGE) && <div id="dateRangePicker" className={classes.dateRangePicker}>
                <LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
                    <MobileDateRangePicker
                        value={displayValues}
                        disabled={isPreset || disablePicker}
                        onChange={(newValue) => handleChangeDateRangePicker(newValue, (start, to) => {
                            setValues([start, to])
                        })}
                        onAccept={(newValue) => handleChangeDateRangePicker(newValue, (start, to) => {
                            dateConditionFilterDispatch({
                                type: DATE_CONDITION_FILTER_TYPE.DATE_COMPONENT,
                                value: [start, to]
                            })
                        })}
                        renderInput={(startProps, endProps) => (
                            <Fragment>
                                <TextField
                                    {...startProps}
                                    label={t('common:filters.from')}
                                    className={classes.customValueBox}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />

                                <TextField
                                    {...endProps}
                                    label={t('common:filters.to')}
                                    className={classes.customValueBox}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Fragment>
                        )}
                    />
                </LocalizationProvider>
            </div>
        }
    </div>
}